import React from 'react';
import { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'src/Components/Common/Popover';
import { LangContext } from 'src/Contexts/Lang';


interface BadgeUnknownGenesNamesI {
    badgesData: {
        value: any,
        name?: string,
        label?: string,
        reference?: any
    }[]
}

export default function BadgeUnknownGenesNames(props: BadgeUnknownGenesNamesI) {
    const { dispatch: { translate } } = useContext(LangContext);
    let badgesArray: any = props.badgesData.filter((x: any) => x.value !== "")
    if (badgesArray.length === 0) return <></>
    const value = badgesArray.map((x: any) => x.value).join(", ")
    const valueTruncated = value.length > 50 ? value.slice(0, 50) + "..." : value
    const label = props.badgesData[0].label
    return (
        <Popover placement='bottom' 
            content={
            <>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ border: "none", fontSize: "12px", color: "#F28B00" }} />&nbsp;
                <span style={{ color: "#F28B00", fontWeight: "bold" }}>
                    {translate("common.warning")}
                </span> - {translate("genesPanel.symbolIsNotApprovedByHugo")} - Genes: {value}
            </>
        } trigger='hover' contentStyle={{ fontSize: "10px" }}>
            <Badge variant={"warning"}
                style={{
                    marginRight: "5px", fontSize: "10px",
                    color: "white"
                }}>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: "5px", border: "none", fontSize: "12px", color: "#F28B00" }} />
                <span style={{ verticalAlign: "text-top" }}>{label}:&nbsp;{valueTruncated}</span>
            </Badge >
        </Popover>
    )
}

function getBadgeUnknownGenesNames(translate: any, validatedGenes: any ): { value: any, name?: string, label?: string, reference?: any }[] {
    let badgesValues: { value: any, name?: string, label?: string, reference?: any }[] = [];
    function checkUnknownGeneNames() {
        let unknownGeneNames = validatedGenes?.unknownGeneNames ?? [];
        unknownGeneNames = unknownGeneNames.filter((x: any) => x.trim() !== "")
        if (unknownGeneNames.length > 0) {
            badgesValues.push({
                "name": "geneName",
                "label": translate("casesQuery.genesSymbolsNotFoundInHugo"),
                "value": unknownGeneNames.join(", ")
            })
        }
    }
    checkUnknownGeneNames();
    return badgesValues
}


export {getBadgeUnknownGenesNames}