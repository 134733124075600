import { CNVsActionType } from "../actionsTypes";
import { } from "../actionsTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
    const { payload, type } = action;
    switch (type) {
        // Async methods
        case CNVsActionType.GET_CNVS_SUCCESS: return { ...state, cnvs: { data: payload, loading: false, error: false, loaded: true } };
        case CNVsActionType.GET_CNVS_ERROR: return { ...state, cnvs: { data: state.cnvs.data, loading: false, error: payload, loaded: true } };
        case CNVsActionType.GET_CNVS_LOADING: return { ...state, cnvs: { data: state.cnvs.data, loading: true, error: false, loaded: false } };
        case CNVsActionType.CLEAN_CNVS: return { ...state, cnvs: { data: null, loading: false, error: false, loaded: false  } };

        // Sync methods
        case CNVsActionType.SET_FILTERS: return { ...state, filters: payload };
        case CNVsActionType.SET_SORT_COLUMN: return { ...state, sortBy: payload };

        default:
            return state;
    }
}