import React from 'react';
import { faArrowDown, faDna, faDownload, faFileDownload, faSortAmountDown, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { Tooltip } from "src/Components/Common/Tooltip/Tooltip";
import { LangContext } from "src/Contexts/Lang";
import { AsyncDataI } from "src/Contexts/interfaces";
import ReportService from "src/Services/ReportService";
import UserContext from "src/Contexts/User/UserContext";
import { CSVLink } from 'react-csv';
import FormInput from "src/Components/Common/Form/FormInput";
import ReactSelect from "src/Components/Common/Form/ReactSelect";
import { CoverageBodyT, OrderByOptionsI } from "./CoverageBody";
import { useContext } from "react";

interface CoverageBodyToolkitI {
	type: CoverageBodyT
	filter: string,
	setfilter(x: string): void,
	studyReq: AsyncDataI,
	coverageReq: AsyncDataI,
	coverageData: {
		filteredData: any[],
		filteredDataToDownload: any[]
	},
	orderBy?: OrderByOptionsI,
	orderByOptions?: OrderByOptionsI[],
	handleSetOrderBy(i?: OrderByOptionsI): void,
	filterNotOnTarget: boolean,
	handlefilterNotOnTarget(): void,
	filterNotCover: boolean,
	handlefilterNotCover(): void,
}
export default function CoverageBodyToolkit(props: CoverageBodyToolkitI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { user } = useContext(UserContext);
	const RS = new ReportService();

	const getCoverageByGene = () => {
		const samplesCov = Object.values(props.coverageData?.filteredData)
		if (samplesCov.length > 0) {
			const pctByGene = samplesCov[0].map((x: any) => `${x.genename}${(x.pctgeneCoverage ?? "").indexOf("100") > -1 ? "" : ("(" + x.pctgeneCoverage + "%)")}`)
			return pctByGene.join(",")
		}
		return ""
	}

	return (<Row style={{
		margin: 0,
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	}}>
		<Col sm={6} md={6} lg={5} xl={4} style={{ padding: "5px", display: "flex" }}>
			<FormInput
				formGrouplStyle={{ width: "100%" }}
				type={"text"}
				value={props.filter}
				disabled={!props.coverageReq.loaded}
				onChange={(e: any) => { props.setfilter((e.target?.value ?? "")); }}
				textAreaRows={10}
				placeHolder={props.type === "gene" ?
					`${translate("common.filterBy")}: ${translate("common.gene").toLowerCase()}, ${translate("common.transcript").toLowerCase()}, ${translate("common.chromosome").toLowerCase()}` :
					`${translate("common.filterBy")}: ${translate("common.gene").toLowerCase()}, rs, ${translate("common.chromosome").toLowerCase()}`}
			// onKeyDown={(e: any) => { checkKeyEnterPress(e, () => { if (props.callFilter) props.callFilter(); }) }} 
			/>
			{props.type === "variant" && <>&nbsp;
				<Button variant="outline-secondary"
					disabled={!props.coverageReq.loaded}
					active={props.filterNotCover}
					onClick={props.handlefilterNotCover}
					style={{ padding: "5px", minWidth: "fit-content", border: "none" }}>
					<FontAwesomeIcon icon={faTimesCircle} style={{ color: "indianred", backgroundColor: "white", borderRadius: "20px", fontSize: "13px" }} />
					&nbsp;
					Not covered
					&nbsp;
				</Button>
				&nbsp;
				<Button variant="outline-secondary"
					active={props.filterNotOnTarget}
					disabled={!props.coverageReq.loaded}
					onClick={props.handlefilterNotOnTarget}
					style={{ padding: "5px", minWidth: "fit-content", border: "none" }}>
					<FontAwesomeIcon icon={faTimesCircle} style={{ color: "indianred", backgroundColor: "white", borderRadius: "20px", fontSize: "13px" }} />
					&nbsp;
					Not on target
					&nbsp;
				</Button>
				&nbsp;
			</>}
			<>
				{((props.studyReq.data?.study?.samplesNames ?? [])).length === 1 ?
					<>
						<a id="downloadCoverageLink"
							href={`${RS.basePath}downloadCoverageFile?token=${user.data.token}&domainId=${user.data.domain}&sample=${props.studyReq.data?.study?.samplesNames[0]}&variantplp=${props.type === "gene" ? "" : "true"}`}
							target="_blank" rel="noreferrer">
							<Tooltip label={translate('common.downloadFullCoverageFile')} placement={"top"}>
								<Button variant="light" style={{ height: "32px", border: "none", margin: "0 2px", float: "right" }}>
									<FontAwesomeIcon icon={faDownload} style={{ color: "gray", fontSize: "15px" }} />
								</Button>
							</Tooltip>
						</a>
						{
							props.coverageReq.loaded && props.type === "gene" &&
							<a id="downloadCoverageByGene"
								href={`data:text/plain;charset=utf-8,${getCoverageByGene()}`}
								download="coverageByGene">
								<Tooltip label={translate('common.downloadCoverageByGenes')} placement={"top"}>
									<Button variant="light" style={{ height: "32px", border: "none", margin: "0 2px", float: "right" }}>
										<label style={{ marginTop: "-11px" }}>
											<FontAwesomeIcon icon={faDna} style={{ color: "gray", fontSize: "14px", transform: "rotate(-90deg)", marginBottom: "-11px" }} />
											<FontAwesomeIcon icon={faArrowDown} style={{ color: "gray", fontSize: "8px" }} />
										</label>
									</Button>
								</Tooltip>
							</a>
						}
					</>
					:
					(props.studyReq.data?.study?.samplesNames ?? []).length > 1 &&
					<Tooltip label={translate('common.downloadFullCoverageFile')} placement={"top"}>
						<Dropdown style={{ float: "right" }}>
							<Dropdown.Toggle variant="light" id="asfqòm" style={{ height: "35px", border: "none", margin: "2px", padding: "0px 8px" }}>
								<FontAwesomeIcon icon={faDownload} style={{ color: "gray", fontSize: "15px" }} />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{(props.studyReq.data?.study?.samplesNames ?? []).map((x: any, i: number) => {
									return <Dropdown.Item
										key={i}
										href={`${RS.basePath}downloadCoverageFile?token=${user.data.token}&domainId=${user.data.domain}&sample=${x}&variantplp=${props.type === "gene" ? "" : "true"}`}
										target="_blank"
										rel="noreferrer"
									>
										{x}
									</Dropdown.Item>
								})}
							</Dropdown.Menu>
						</Dropdown>
					</Tooltip>}
			</>
		</Col>
		<Col sm={2} md={1} lg={3} xl={5} style={{ padding: "0", textAlign: "end" }}>
		</Col>
		<Col sm={6} md={5} lg={4} xl={3} style={{
			padding: "0",
			textAlign: "end",
			display: props.type === "gene" || !props.coverageReq.loaded ? "block" : "flex"
		}}>
			{props.coverageReq.loaded && props.type === "variant" &&
				<>
					<ReactSelect options={props.orderByOptions}
						onChange={(e: any) => {
							props.handleSetOrderBy(e)
						}}
						value={props.orderBy}
						placeholder={"Order by"}
						// onKeyDown={(e: any) => {

						// }} 
						divStyles={{ width: "inherit", margin: "3px" }}
					/>
					<Button variant="outline-secondary"
						style={{ height: "32px", padding: "0 8px", borderColor: "lightgray", margin: "3px 4px 3px -4px" }}
					// onClick={() => { props.handleGetQueryVariant(1) }}
					>
						<FontAwesomeIcon icon={faSortAmountDown} style={{ fontSize: "17px" }} />
					</Button>
				</>}
			{!props.coverageReq.loaded ?
				<>
					<Button variant="light" style={{ height: "35px", border: "none", margin: "2px" }} disabled>
						<FontAwesomeIcon icon={faFileDownload} style={{ color: "lightgray", fontSize: "15px" }} />
					</Button>
				</>
				:
				(Object.keys(props.coverageData.filteredData ?? {})).length === 1 ?
					<CSVLink filename={"coverage"}
						headers={(Object.values(props.coverageData.filteredData)[0] as Array<Array<any>>).length > 0 ?
							Object.keys((Object.values(props.coverageData.filteredData)[0] as Array<any>)[0]) : ["."]
						}
						data={Object.values(props.coverageData.filteredDataToDownload)[0] as any}
					>
						<Tooltip label={translate("common.downloadFilteredCoverageFile")} placement={"top"}>
							<Button variant="light" style={{ height: "35px", border: "none", margin: "2px" }}>
								<FontAwesomeIcon icon={faFileDownload} style={{ color: "gray", fontSize: "15px" }} />
							</Button>
						</Tooltip>
					</CSVLink>
					:
					<Dropdown style={{ display: "contents" }}>
						<Tooltip label={"Download filtered coverage file"} placement={"top"}>
							<Dropdown.Toggle key={0} variant="light" id="asfqòm" style={{ height: "35px", border: "none", margin: "2px", padding: "0px 8px" }}>
								<FontAwesomeIcon icon={faFileDownload} style={{ color: "gray", fontSize: "15px" }} />
							</Dropdown.Toggle>
						</Tooltip>
						<Dropdown.Menu>
							{(Object.keys(props.coverageData.filteredDataToDownload ?? {})).map((x: any, i: number) => {
								return <Dropdown.Item eventKey={`${i}`} as="button"                                            >
									<CSVLink filename={"coverage"}
										headers={
											props.coverageData.filteredDataToDownload && props.coverageData.filteredDataToDownload.length > 0 ?
												Object.keys(props.coverageData.filteredDataToDownload[x]) : ["."]
										}
										data={props.coverageData.filteredDataToDownload[x]}>
										{x}
									</CSVLink>
								</Dropdown.Item>
							})}
						</Dropdown.Menu>
					</Dropdown>
			}
		</Col>
	</ Row >
	)
}
