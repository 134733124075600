import React from 'react';
import { useState, useEffect, useContext } from 'react';
import CasesBody from '../../Components/Cases/Home/CasesBody';
import DetailCase from '../../Components/Cases/Detail/DetailCase';
import { Layout } from '../../Components/Layout/Layout';
import CasesContextState from '../../Contexts/Cases/CasesState';
import Comments from '../../Components/Cases/Comments/Comments';
import UserContext from '../../Contexts/User/UserContext';
import { Redirect } from 'react-router-dom';
import UserService from '../../Services/UserServices';
import CoverageBody from 'src/Components/Cases/Coverage/CoverageBody';
import CnvBody from 'src/Components/Cases/CNV/CnvBody';
import CNVsContextState from 'src/Contexts/CNVs/CNVsState';
import DomainsContextState from 'src/Contexts/Domains/DomainsState';

interface CasePageI {
    history: any,
    match?: any,
    // query: boolean,
    // caseId?: number,
    // variantId?: number
}
export default function CasesPage(props: CasePageI) {
    const { user } = useContext(UserContext);
    const [body, setbody] = useState<any>(<></>)
    const UserS = new UserService();
    const cuRoll: any = user.data?.rolestoString ?? ""
    const usRoll: any = UserS.user?.rolestoString ?? ""
    useEffect(() => {
        if (usRoll === "ROLE_SYSTEM") {
            setBodyCases();
        } else if ((usRoll !== "ROLE_ADMIN" && usRoll !== "ROLE_OPERATOR" && usRoll !== "ROLE_REPORTER" && usRoll !== "ROLE_GUEST") ||
            (cuRoll !== "" && (cuRoll !== UserS.user?.rolestoString))) {
            setbody(<Redirect to='/' />)
        } else {
            const caseId = props.match?.params?.caseId;
            const locationPath = props.history?.location?.pathname;
            if (caseId && locationPath) {
                if ((locationPath).indexOf("/comments/") > -1) setBodyComments(caseId)
                else if ((locationPath).indexOf("/coverage/") > -1) {
                    if ((locationPath).indexOf("/variant/") > -1) setBodyCoverage(caseId, "variant")
                    else if ((locationPath).indexOf("/gene/") > -1) setBodyCoverage(caseId, "gene")
                    else setbody(<Redirect to='/' />)
                }
                else if ((locationPath).indexOf("/cnv/") > -1) setBodyCnvs(caseId)
                else setBodyDetails(caseId)
            }
            else setBodyCases()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, user])

    function setBodyCases() {
        setbody(
            <CasesBody history={props.history} />
        )
    }
    function setBodyDetails(caseId: number) {
        setbody(
            <DetailCase history={props.history}
                caseId={caseId} />
        )
    }
    function setBodyComments(caseId: number) {
        setbody(
            <Comments history={props.history}
                caseId={`${caseId}`} />
        )
    }
    function setBodyCnvs(caseId: number) {
        setbody(
            <CNVsContextState>
                <CnvBody history={props.history}
                    caseId={`${caseId}`} />
            </CNVsContextState>
        )
    }
    function setBodyCoverage(caseId: number, type: "variant" | "gene") {
        setbody(
            <CoverageBody history={props.history}
                match={props.match}
                type={type}
                caseId={caseId}
            />
        )
    }

    return (
        <CNVsContextState>
            <CasesContextState>
                <DomainsContextState>
                    <Layout history={props.history}>
                        {body}
                    </Layout>
                </DomainsContextState>
            </CasesContextState>
        </CNVsContextState>
    )
}
