import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Statistics from './Statistics';
import { StudyI } from 'src/Contexts/interfaces';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { LangContext } from 'src/Contexts/Lang';

interface ModalStatisticsI {
    history: any,
    handleCloseStatistics: () => void
    showStats: { 
        open: boolean, 
        study: StudyI | undefined, 
        domainId: number | null }
}
export default function ModalStatistics(props: ModalStatisticsI) {
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);

    return (
        <Modal show={props.showStats.open}
            onHide={props.handleCloseStatistics}
            size={"xl"}>
            <Modal.Header closeButton
                style={{
                    color: "lightgray",
                    backgroundColor: theme.grayHeader,
                    display: "flow",
                    fontSize: 20,
                    fontWeight: "bold"
                }}
            >
                <FontAwesomeIcon icon={faChartPie} style={{ fontSize: "20px", marginRight: "10px", }} />
                {translate('casesDetail.statistics')} - {props.showStats.study?.name}
            </Modal.Header>
            <Statistics history={props.history}
                actualCase={props.showStats.study}
                domainId={props.showStats.domainId ?? undefined}
                noTitle={true}

            />
        </Modal >)
}