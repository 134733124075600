import { createContext } from "react";
import { AsyncDataI } from "../interfaces";

interface FiltersCNVsI {
    genes: string,
    oncoQValue: number | null,
    deconBF: number | null
    diseaseGenes: boolean
}
interface ContextCNVsStatesI {
    cnvs: AsyncDataI & { data: any | null }, // TODO: crear interfaz con datos de CNV request
    filters: FiltersCNVsI,
    sortBy: string,
    filterAndSortCnvDataTable(arrayCnvs: any[], filters: FiltersCNVsI, sortBy: string, diseaseGenes?: string[]): any[],
}
interface ContextPropsCNVsI extends ContextCNVsStatesI {
    dispatch: {
        getCNVs: (studyId?: number, pageNumber?: number, pageSize?: number) => Promise<any>;
        cleanCNVs: () => void;
        setFilters: (key: "genes" | "oncoQValue" | "deconBF" | "diseaseGenes", value: string) => boolean;
        cleanFilters: () => void;
        handleSort: (value: string) => void;
    }
}

const CNVsContext = createContext({} as ContextPropsCNVsI);

export default CNVsContext;
export type { FiltersCNVsI, ContextCNVsStatesI }