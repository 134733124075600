import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LangContext } from 'src/Contexts/Lang';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import CancerHotspotsBar, { BarDataI, getBarDataI } from '../Nivo/HotspotsBar/HotspotsBar';
import CancerHotspotsBarLabels from '../Nivo/HotspotsBar/HotspotsBarLabels';
import { getHotspotsLinkFromInfoCD } from './DbLinks';
import { Tooltip } from '../Tooltip/Tooltip';
interface HotspotsDetailI {
	infoCd: any,
	// studyId: number,
	// variantId: string,
	// variant: AsyncDataI,
	// isMitochondrial: boolean,
	// isSomatic: boolean,
}

export default function HotspotsDetail(props: HotspotsDetailI) {
	const infoCd = props.infoCd
	const { dispatch: { translate } } = useContext(LangContext);

	const { theme } = useContext(ThemeContext);

	let thereAreOrganTypes = (infoCd["HOTSPOTS_ORGAN_TYPES"] && infoCd["HOTSPOTS_ORGAN_TYPES"].trim().length > 2)
	let organTypesSplitted: string[] = []
	let totalSamples = 0;
	let aaChangeByAny = infoCd["HOTSPOTS_AMINO_ACID_CHANGE_BY_ANY_STRING"]
	let aaChangeBySame = infoCd["HOTSPOTS_AMINO_ACID_CHANGE_BY_SAME"]
	if (thereAreOrganTypes) {
		organTypesSplitted = infoCd["HOTSPOTS_ORGAN_TYPES"].split(",")
		totalSamples = organTypesSplitted.map((x: string): number => {
			let valueSplitted: string[] = x.split(":")
			if (valueSplitted.length > 2) {
				return +valueSplitted[2]
			}
			return 0
		}).reduce((acum: number, x: number) => {
			acum += +x;
			return acum;
		}, 0);

	}
	// console.log(totalSamples)
	const getOrganType = () => {
		// const organValues: string[] = [
		// 	"uterus", "cnsbrain", "esophagusstomach", "breast",
		// 	"bowel", "lung", "prostate", "bladder", "unk",
		// 	"cervix", "headandneck", "thyroid", "ovaryfallopiantube",
		// 	"liver", "biliarytract", "lymph", "pancreas", "skin",
		// 	"kidney", "softtissue", "penis", "testis", "blood",
		// 	"bone", "adrenalgland", "ampullaofvater", "vulvavagina",
		// 	"thymus", "pleura", "eye", "peritoneum"
		// ]
		//example:"cnsbrain:2270:113,bowel:1782:105,lung:2761:78,esophagusstomach:1407:74,breast:2561:43,ovaryfallopiantube:699:37,uterus:618:30,headandneck:988:29,pancreas
		if (thereAreOrganTypes) {
			let data: BarDataI[] = getBarDataI(`${infoCd["HOTSPOTS_ORGAN_TYPES"]}`, "organ", theme.chart_nivo_nivo, translate);
			const dataValuesTotalCount = data.reduce((acc, item) => {
				acc[item.id] = item.totalCount;
				return acc;
			}, {} as Record<string, number>);
			let data2 = data.map((x: BarDataI, i: number) => {
				return <CancerHotspotsBarLabels x={x} i={i} percentage={dataValuesTotalCount[x.id]} type='organ' />
			})
			return data2
		}
	}
	const getDetailedCancerType = () => {
		if (infoCd["HOTSPOTS_DETAILED_CANCER_TYPES"] && infoCd["HOTSPOTS_DETAILED_CANCER_TYPES"].trim().length > 2) {
			let data: BarDataI[] = getBarDataI(`${infoCd["HOTSPOTS_DETAILED_CANCER_TYPES"]}`, "cancer", theme.chart_nivo_set3, translate);
			const dataValuesTotalCount = data.reduce((acc, item) => {
				acc[item.id] = item.totalCount;
				return acc;
			}, {} as Record<string, number>);
			let cancerType = data.map((x: BarDataI, i: number) => {
				return <CancerHotspotsBarLabels x={x} i={i} percentage={dataValuesTotalCount[x.id]} type='cancer' />
			})
			if (cancerType.length > 0) return cancerType
			return false
		}
	}
	const organType = getOrganType();
	const detailedCancerType = getDetailedCancerType();
	const hotspotsLink: any = getHotspotsLinkFromInfoCD(infoCd)
	const HP_RAA = infoCd.HOTSPOTS_REFERENCE_AMINO_ACID
	return <>
		<Row style={{ margin: 0, display: "flex" }}>
			{
				HP_RAA && HP_RAA.trim().length > 2 ?
					(<>
						<Col md={11} style={{ margin: 0, padding: 0, marginTop: 2 }}>
							<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
								{translate("casesDetail.mutation")}
							</span> : &nbsp;{HP_RAA}
							&nbsp;|&nbsp;
							{aaChangeBySame && aaChangeByAny ?
								<>
									<Tooltip label={`${translate("casesQuery.totalSamplesWithSameAminoAcidChange")} (${HP_RAA}) :  ${aaChangeBySame}`}>
										<span style={{ color: theme.grayHeader }}>
											{translate("casesQuery.totalSamplesWithAAEqualAlt")} ({infoCd.HOTSPOTS_REFERENCE_AMINO_ACID.replace(/^(.*\d)([A-Za-z]+)$/, '$1')}<strong>{HP_RAA.slice(HP_RAA.length - 1, HP_RAA.length)}</strong>) :  {aaChangeBySame}
										</span>
									</Tooltip>
									&nbsp;|&nbsp;
									<Tooltip label={ `${translate("casesQuery.totalSamplesWithAminoAcidChangedByAnyAminoAcid")} (${infoCd.HOTSPOTS_REFERENCE_AMINO_ACID.replace(/^(.*\d)([A-Za-z]+)$/, '$1_')}):  ${aaChangeByAny}`}>
										<span style={{ color: theme.grayHeader }}>
											{translate("casesQuery.totalSamplesWithAADifferentAlt")} ({infoCd.HOTSPOTS_REFERENCE_AMINO_ACID.replace(/^(.*\d)([A-Za-z]+)$/, '$1')}<strong>_</strong>) :  {aaChangeByAny}
										</span>
									</Tooltip>
								</> :
								<><span style={{ color: theme.grayHeader }}>
									{translate("casesDetail.totalSamplesWithTheSameMutatedCodon")}
								</span> : &nbsp;{totalSamples}</>
							}
						</Col>
						<Col md={1} style={{ margin: 0, padding: 0, marginTop: 2 }}>
							<span style={{ float: "right", marginRight: 10 }}>
								{hotspotsLink}
							</span>
						</Col>
					</>
					) : ""
			}
		</Row>
		{
			organType ?
				(<>
					<hr style={{ margin: "7px 0" }} />
					<Row style={{ margin: 0, display: "inline" }}>
						<span style={{ fontWeight: "bold", color: theme.grayHeader }}>{translate("casesDetail.organTypes")}</span> <br />
						<CancerHotspotsBar data={infoCd["HOTSPOTS_ORGAN_TYPES"]} type={"organ"} />
						{organType} <br />
					</Row>
				</>) : ""
		}
		{
			detailedCancerType ?
				(<>
					<hr style={{ margin: "7px 0" }} />
					<Row style={{ margin: 0, display: "inline" }}>
						<span style={{ fontWeight: "bold", color: theme.grayHeader }}>{translate("common.cancerTypes")}</span> <br />
						<CancerHotspotsBar data={infoCd["HOTSPOTS_DETAILED_CANCER_TYPES"]} type={"cancer"} />
						<div style={{ maxHeight: 100, overflowY: "auto" }}>
							{detailedCancerType}
						</div> <br />
					</Row>
				</>
				) : ""
		}
	</>
}

