import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from "react-bootstrap";
import { LangContext } from 'src/Contexts/Lang';
import StudyService from 'src/Services/StudyServices';
import BulkLoadTableCsv from './BulkLoadTableCsv';
import WarningCaseLoadFilesNotFound, { convertDataToValidetionRequest, FilesNotFoundByDomainT, getTransformedDataRecords } from 'src/Components/Common/WarningCaseLoadFilesNotFound';
import { StudyBulk } from 'src/Components/Common/Interfaces';
import { AxiosResponse } from 'axios';

interface TabLoadFromCsvI {
    handleLaunchRequest: () => void
}
export default function TabLoadFromCsv(props: TabLoadFromCsvI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const StudyS = new StudyService();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileContent, setFileContent] = useState<string | null>(null);

    const initWarningModal = { "show": false, filesNotFound: {} }
    const [warningModal, setwarningModal] = useState<{ show: boolean, filesNotFound: FilesNotFoundByDomainT }>(initWarningModal)


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };
    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content: any = e.target?.result;
                let strContent = content as string;
                setFileContent(strContent);
            };
            reader.readAsText(selectedFile);
        }
    }, [selectedFile]);

    const handleUploadBatchSamples = () => {
        if (selectedFile && fileContent) {
            // handleShowBatchLoadingModal()
            const formData = new FormData();
            formData.append('file', selectedFile);
            const records: StudyBulk[] = getTransformedDataRecords(fileContent);
            const domainsIdAndSamples = convertDataToValidetionRequest(records)
            StudyS.upload_batch_cases(formData).then((res: any) => {
                if (res.status === 200) {
                    return StudyS.validateAuxiliaryFiles(domainsIdAndSamples)
                        .then((res: AxiosResponse<any>) => {
                            if (Object.keys(res.data).length === 0) {
                                setSelectedFile(null);
                                props.handleLaunchRequest();
                                return;
                            }
                            const filesNotFoundByDomain = StudyS.validateAuxiliaryFilesAdapter(res, records)
                            if (filesNotFoundByDomain) {
                                setTimeout(() => {
                                    setSelectedFile(null);
                                    props.handleLaunchRequest();
                                }, 12000);
                                setwarningModal({ show: true, filesNotFound: filesNotFoundByDomain })
                            } else {
                                setSelectedFile(null);
                                props.handleLaunchRequest();
                            }
                        }).catch((e: any) => {
                            console.log("e")
                            console.log(e)
                        })
                }
            })
        } else {
            setSelectedFile(null);
            // console.log('No file selected');
        }
    };
    return (
        <div style={{ padding: "10px 0 0 0" }}>
            {warningModal.show ?
                <div style={{ padding: "10px 0 0 0" }}>
                    <WarningCaseLoadFilesNotFound
                        filesNotFoundByDomain={warningModal.filesNotFound}
                        handleReload={() => { }}
                        handleClose={() => { }}
                    />
                </div> :
                <>
                    <Form id="example-fade-text" style={{ display: "flex", alignItems: "center" }}>
                        <Form.File
                            id="custom-file"
                            label={selectedFile?.name ?? translate("common.selectFile")}
                            custom
                            data-browse={".csv"}
                            onChange={handleFileChange}
                        />&nbsp;
                        {selectedFile?.name &&
                            <Button variant={"outline-success"} onClick={() => handleUploadBatchSamples()}>{translate("common.upload")}</Button>}
                    </Form>
                    <div>
                        {fileContent && (
                            <div>
                                <BulkLoadTableCsv csvData={fileContent} />
                            </div>
                        )}
                    </div>
                </>
            }
        </div>
    )
}


