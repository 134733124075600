import { AxiosResponse } from 'axios';
import axios from './Middleware';
import { AbstractService } from './AbstractService';
import { FilesNotFoundByDomainT, ValidationFileByCaseAndDomainT, ValidationFileByCaseT, ValidationFileT } from 'src/Components/Common/WarningCaseLoadFilesNotFound';
import { StudyBulk } from 'src/Components/Common/Interfaces';

export default class StudyService extends AbstractService {
	basePath: string;
	constructor() {
		super();
		this.basePath = this.REACT_APP_API_URL + "study/";
	}
	async search(filters?: any, pageNumber: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
		filters = filters ?? {}
		const setFilter = (filters: any) => {
			filters["studyType"] = filters?.studyType ?? null;
			filters["dateCreatedFromSearch"] = filters?.dateCreatedFromSearch ?? "";
			filters["dateCreatedToSearch"] = filters?.dateCreatedToSearch ?? "";
			filters["descriptionSearch"] = filters?.descriptionSearch ?? "";
			filters["nameSearch"] = filters?.nameSearch ?? "";
			filters["sortColumn"] = filters?.sortColumn ?? "";
			return filters
		}
		filters = setFilter(filters)
		const url = `${this.basePath}search`
		const data = {
			"domainId": `${this.user?.domain}`,
			"userId": this.user?.id.toString(),
			"pageNumber": pageNumber,
			"pageSize": pageSize,
			...filters
		}
		return await axios.post(url, data, this.authHeader)
	}

	async archive(casesIds: number[]): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}archive`
		const config = {
			...this.authHeader,
			params: {
				id: casesIds.join(","),
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}
	async active(casesIds: number[]): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}active`
		const config = {
			...this.authHeader,
			params: {
				id: casesIds.join(","),
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}
	async delete(casesIds: number[], domainId?: string): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}delete`
		const data = {
			domainId: domainId ?? `${this.user?.domain}`,
			id: casesIds.join(","),
			userId: this.user?.id
		}
		return await axios.post(url, data, this.authHeader)
	}
	async deleteAll(domainIdsSamples: string): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}deleteAll`
		const data = {
			userId: this.user?.id,
			domainIdsSamples: domainIdsSamples
		}
		return await axios.post(url, data, this.authHeader)
	}
	async read_case(id: string, domainId?: string): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}study`
		const config = {
			...this.authHeader,
			params: {
				id: id,
				domainId: domainId ?? this.user?.domain,
				userId: this.user?.id,
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}

	async create_case(input: create_caseI, domainId?: string): Promise<AxiosResponse<any>> {
		let data: any = input;
		data.id = "";
		data.domainId = domainId ?? `${this.user?.domain}`;
		data.user = this.user?.id.toString();
		const url = `${this.basePath}save`
		return await axios.post(url, data, this.authHeader)
	}


	async upload_batch_cases(file: any): Promise<AxiosResponse<any>> {
		let data: any = {};
		// data.file = file;

		// data.domainId = domainId ?? `${this.user?.domain}`;
		data.user = this.user?.id.toString();
		const url = `${this.basePath}upload`
		return await axios.post(url, file, this.authHeader)
		// return await axios.post(url, file, 
		// { headers: { ...this.authHeader.headers, 'Content-Type': 'multipart/form-data' } }
		// { headers: { ...this.authHeader.headers, 'Content-Type': 'multipart' } }
		// )

	}
	async update_case(input: Update_caseI, domainId?: string): Promise<AxiosResponse<any>> {
		if (input.hasBam !== undefined) input.hasBam = `${input.hasBam}`
		if (input.hasCnv !== undefined) input.hasCnv = `${input.hasCnv}`
		let data: any = input;
		input.samples.forEach((x: any) => {
			let s: any = {}
			data[`sample-${x.id}-id`] = x.id.toString()
			if (x.affected !== undefined && x.affected)
				data[`sample-${x.id}-affected`] = "on"
			data[`sample-${x.id}-birthDate`] = x.birthDate ?? ""
			data[`sample-${x.id}-sex`] = x.sex ?? ""
			if (x.relation !== undefined)
				data[`sample-${x.id}-tag`] = x.relation ?? ""
			return s;
		})
		delete data['samples']
		data.id = input.id.toString();
		data.domainId = domainId ?? `${this.user?.domain}`;
		data.user = this.user?.id.toString();
		const url = `${this.basePath}update`
		return await axios.post(url, data, this.authHeader)
	}

	async update_case_bam(studyId: string, hasBam: boolean): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}studyBam`
		const data = {
			studyId: studyId,
			hasBam: `${hasBam}`
		}
		return await axios.post(url, data, this.authHeader)
	}
	async update_case_cnv(studyId: string, hasCnv: boolean): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}studyCnv`
		const data = {
			studyId: studyId,
			hasCnv: `${hasCnv}`
		}
		return await axios.post(url, data, this.authHeader)
	}
	async getStudyCoverage(studyId: string, domainId: string): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}getStudyCoverage`
		const data = {
			studyId: studyId,
			domainId: domainId
		}
		return await axios.post(url, data, this.authHeader)
	}

	async getCNVForStudy(studyId: string, pageNumber: number = 1, pageSize: number = 25): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}getCNVForStudy?`
		const config = {
			...this.authHeader,
			params: {
				studyId: studyId,
				pageNumber: pageNumber,
				pageSize: pageSize,
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}
	async validateAuxiliaryFiles(domainIdsSamples: string): Promise<AxiosResponse<any>> {
		const url = `${this.basePath}validateAuxiliaryFiles?`
		const config = {
			...this.authHeader,
			params: {
				domainIdsSamples: domainIdsSamples,
				userId: this.user?.id,
				_: (new Date()).getTime()
			}
		}
		return await axios.get(url, config)
	}
	validateAuxiliaryFilesAdapter(res: AxiosResponse<any>, records: StudyBulk[]): FilesNotFoundByDomainT | undefined {
		if (res.status === 200) {
			if (Object.keys(res.data).length === 0) return;
			const adaptedRecordsByDomain: ValidationFileByCaseAndDomainT = records
				.filter((x: StudyBulk) => Object.keys(res.data).includes(x.domainId))
				.map((x: StudyBulk): ValidationFileByCaseT => {
					const sampleName = x.fileSamples.split("_acmg_final_annot.vcf.gz")[0];
					// ------------------ files to validate ------------------ 
					let filesToValidate: ValidationFileByCaseT = {
						domain: { id: x.domainId, name: x.institution },
						coverage: [`${sampleName}.tsv.zip`, `${sampleName}_clinvar.tsv.zip`],
					};
					if (x.hasBam.toLocaleLowerCase().trim() === "true") filesToValidate.bam = [`${sampleName}.bam`, `${sampleName}.bai`]
					if (x.hasCnv.toLocaleLowerCase().trim() === "true") filesToValidate.cnv = [`${sampleName}_CNV.tsv.zip`]
					// ------------------ files to validate ------------------ 
					return filesToValidate
				}).reduce((acc: Record<string, ValidationFileByCaseT[]>, record: ValidationFileByCaseT) => {
					if (!acc[`${record.domain.id}`]) {
						acc[`${record.domain.id}`] = [];
					}
					acc[`${record.domain.id}`].push(record);
					return acc;
				}, {});
			let filesNotFoundByDomain: FilesNotFoundByDomainT = {}
			Object.entries(adaptedRecordsByDomain).forEach((x: [string, ValidationFileByCaseT[]]) => {
				if (!Object.keys(filesNotFoundByDomain).includes(x[0]))
					filesNotFoundByDomain[x[0]] = { domainName: x[1][0].domain.name, files: [] }
				x[1].forEach((y: any) => {
					Object.keys(res.data).includes(x[0])
					const responseFilesNotFound: string[] = res.data[x[0]].map((x: string) => x.split("/")[x.split("/").length - 1])
					let filesNotFound: { file: string, type: ValidationFileT }[] = [];
					if (responseFilesNotFound.includes(y.coverage[0])) filesNotFound.push({ file: y.coverage[0], type: "coverage" })
					if (responseFilesNotFound.includes(y.coverage[1])) filesNotFound.push({ file: y.coverage[1], type: "coverage" })
					if (y.cnv !== undefined && responseFilesNotFound.includes(y.cnv[0]))
						filesNotFound.push({ file: y.cnv[0], type: "cnv" })
					if (y.bam !== undefined) {
						if (responseFilesNotFound.includes(y.bam[0]))
							filesNotFound.push({ file: y.bam[0], type: "bam" })
						if (responseFilesNotFound.includes(y.bam[1]))
							filesNotFound.push({ file: y.bam[1], type: "bam" })
					}
					filesNotFoundByDomain[x[0]].files = [...filesNotFoundByDomain[x[0]].files, ...filesNotFound]
				})
			});
			return filesNotFoundByDomain
		}
	}
}

interface SamplesI {
	affected: boolean,
	birthDate: string,
	id: number,
	sex: string,
	tag: string
}

interface caseI {
	id: number,
	code: string,
	customLibrary: string
	genomeReference: string,
	hasBam?: string,
	hasCnv?: string,
	institution: string,
	libraryPrepKit: string,
	professional: string,
	studyDescription: string,
	studyName: string,
	studyState: string,
	subtype: string
}
interface Update_caseI extends caseI {
	samples: SamplesI[]
}
interface create_caseI extends caseI {
	fileSamples: string,
	type: "simple"
}
// fileSamples: "10k_ADN-1160_variant_calling_final_annot.vcf"
// user: "51"
// domainId: "26"

// id: ""
// code: ""
// customLibrary: ""
// genomeReference: "GRCh37"
// institution: "Cromosoma 1"
// libraryPrepKit: "SSHAEV5"
// professional: "Cromosoma1"
// studyDescription: ""
// studyName: "DDTEST3"
// subtype: "WES"
// type: "simple"

export type { Update_caseI }