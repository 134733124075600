import React from 'react';
import { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'src/Components/Common/Popover';
import { LangContext } from 'src/Contexts/Lang';


interface BadgePreviousGenesNamesI {
    badgesData: {
        value: any,
        name?: string,
        label?: string,
        reference?: any
    }[]
} 

export default function BadgePreviousGenesNames(props: BadgePreviousGenesNamesI) {
    const { dispatch: { translate } } = useContext(LangContext);
    let badgesArray: any = props.badgesData.filter((x: any) => x.value !== "")
    if (badgesArray.length === 0) return <></>
    const value = badgesArray.map((x: any) => x.value).join(", ")
    const valueTruncated = value.length > 50 ? value.slice(0, 50) + "..." : value
    const label = props.badgesData[0].label
    return (
        <Popover placement='bottom'
            content={
            <>
                <FontAwesomeIcon icon={faInfoCircle} style={{ border: "none", fontSize: "12px" }} />&nbsp;
                {translate("genesPanel.symbolIsNotApprovedByHugo")} - Genes: {value}
            </>
        } trigger='hover' contentStyle={{ fontSize: "10px" }}>
            <Badge variant={"info"}
                style={{
                    marginRight: "5px", fontSize: "10px",
                    color: "white"
                }}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: "5px", border: "none", fontSize: "12px" }} />
                <span style={{ verticalAlign: "text-top" }}>{label}:&nbsp;{valueTruncated}</span>
            </Badge >
        </Popover>
    )
}

function getPreviousGenesNamesBadgesInfo(translate: any, validatedGenes: any): { value: any, name?: string, label?: string, reference?: any }[] {
    let badgesValues: { value: any, name?: string, label?: string, reference?: any }[] = [];
    function checkOldNames() {
        let previous = validatedGenes?.previous ?? [];
        previous = previous.filter((x: any) => x.trim() !== "")
        if (previous.length > 0) {
            badgesValues.push({
                "name": "geneName",
                "label": translate("casesQuery.genesSearchedForByPreviousSymbols"),
                "value": previous.join(", ")
            })
        }
    }
    checkOldNames();
    return badgesValues
}

export {getPreviousGenesNamesBadgesInfo}