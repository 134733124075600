import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { LangContext } from 'src/Contexts/Lang';
import { StudyBulk } from './Interfaces';

type ValidationFileByCaseT = {
    domain: {
        id: string,
        name: string,
    },
    coverage: string[],
    bam?: string[],
    cnv?: string[],
}
type ValidationFileByCaseAndDomainT = {
    [domainId: string]: ValidationFileByCaseT[]
}
type ValidationFileT = "coverage" | "bam" | "cnv"
type FilesNotFoundT = { file: string, type: ValidationFileT }
type FilesNotFoundByDomainT = {
    [domainId: string]: {
        domainName: string,
        files: FilesNotFoundT[]
    }
}
type WarningCaseLoadFilesNotFoundT = {
    filesNotFoundByDomain: FilesNotFoundByDomainT,
    handleClose: () => void,
    handleReload: () => void
}

export default function WarningCaseLoadFilesNotFound(props: WarningCaseLoadFilesNotFoundT) {
    const { dispatch: { translate } } = useContext(LangContext);
    const filesNotFoundByDomainComponent = Object.entries(props.filesNotFoundByDomain).map((x: [string, { domainName: string, files: FilesNotFoundT[] }], i: number) => {
        const len = x[1].files.length
        const filess = x[1].files.reduce((acc: { [key: string]: string[] }, curr: { file: string, type: string }) => {
            if (!acc[curr.type]) {
                acc[curr.type] = [];
            }
            acc[curr.type].push(curr.file);
            return acc;
        }, {});
        const badges = Object.entries(filess).map((x: any, i: number) => getBadgesByFileType(x, i, len))
        return (
            <div key={i}>
                <hr />
                <h6 key={i} style={{}}> {x[1].domainName} ({x[0]})</h6>
                {badges}
                <br />
            </div>
        )
    })
    setTimeout(() => {
        if (props.handleReload) props.handleReload()
        props.handleClose()
    }, 12000);

    return (
        <div style={{ padding: 20 }}>
            <span style={{ fontSize: "20px" }}>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ border: "none", color: "#F28B00" }} />&nbsp;
                {translate("common.warning")} - {translate("common.filesNotFound")}
            </span>
            <br />
            {filesNotFoundByDomainComponent}
        </div>
    )
}

function getBadgesByFileType(data: [string, [string]], index: number, len: number) {
    const type = data[0] === "bam" ? "Binary Alignment Map" : data[0] === "coverage" ? "Coverage" : data[0] === "cnv" ? "Copy Number Variation" : " - "
    const filesLength = data[1].length;
    const filesBadges = data[1].map((x: string, i: number) => {
        return (
            <span key={i}>
                <Badge variant="secondary">{x}</Badge>
                {i === filesLength - 1 ? "" : ", "}
            </span>

        )
    })
    return (
        <span key={index}>
            <Badge variant="warning">{type}</Badge> : &nbsp;
            <span style={{ fontSize: 14 }}>
                {filesBadges}
                {index === len - 1 ? "" : <br />}
            </span>
        </span>
    )
}

const convertDataToValidetionRequest = (records: StudyBulk[]) => {
    const groupedByDomain: Record<string, string[]> = records.reduce((acc: Record<string, string[]>, record: StudyBulk) => {
        const domainId = record.domainId;
        if (!acc[domainId]) {
            acc[domainId] = [];
        }
        acc[domainId].push(record.fileSamples.split("_acmg_final_annot.vcf.gz")[0]);
        return acc;
    }, {});
    // console.log('Grouped data:', groupedByDomain);
    function convertToString(obj: { [key: string]: string[] }): string {
        const result: string[] = [];
        for (const key in obj) {
            result.push(`${key}:${obj[key].join(',')}`);
        }
        return result.join(';');
    }
    const domainsIdAndSamples = convertToString(groupedByDomain);
    // console.log(domainsIdAndSamples)
    return domainsIdAndSamples;
}
const getTransformedDataRecords = (transformedData: string): StudyBulk[] => {
    const newTransformedData = transformedData.split("\n")
        .filter((x: any) => x.trim() !== "")
        .map((row: string) => row.split(','));
    // console.log(newTransformedData)
    const headers = newTransformedData[0] as (keyof StudyBulk)[];
    const records: StudyBulk[] = newTransformedData.slice(1).map(row => {
        const record = {} as StudyBulk;
        headers.forEach((header, index) => {
            record[header] = row[index];
        });
        return record;
    });
    return records
}

export { convertDataToValidetionRequest, getTransformedDataRecords }
export type { ValidationFileT, ValidationFileByCaseT, ValidationFileByCaseAndDomainT, FilesNotFoundT, FilesNotFoundByDomainT }
