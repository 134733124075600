import React, { useReducer } from "react";
import CNVsContext, { ContextCNVsStatesI, FiltersCNVsI } from "./CNVsContext";
import CNVsReducer from "./CNVsReducer";
import { CNVsActionType } from "../actionsTypes";
import StudyService from "../../Services/StudyServices";
import { ContextStateManager } from "../ContextStateManager";
import { compareToSort } from "src/utils/helpers";

const CNVsContextState = (props: any) => {
    const StudyS = new StudyService();

    const initialState: ContextCNVsStatesI = {
        cnvs: { data: null, loaded: false, loading: false, error: false },
        filters: { genes: "", oncoQValue: null, deconBF: null, diseaseGenes: false },
        sortBy: "region",
        filterAndSortCnvDataTable: filterAndSortCnvDataTable
    };
    const [state, dispatch] = useReducer(CNVsReducer, initialState);
    const CSM = new ContextStateManager(state, initialState, CNVsActionType, dispatch)

    const getCNVs = async (studyId?: number, pageNumber?: number, pageSize?: number) =>
        CSM.GetAsyncData("GET_CNVS_", { studyId, pageNumber, pageSize }, StudyS, "getCNVForStudy",
            (resData: any) => {
                let cnvWithRegion = (resData?.cnv?.results ?? []).map((x: any) => {
                    return {
                        ...x,
                        regionDecon: x.decon ? `${x.chromosome}:${x.startDecon}-${x.endDecon}` : " - ",
                        regionOnco: x.onco ? `${x.chromosome}:${x.startOnco}-${x.endOnco}` : " - ",
                        chrNumber: x.chromosome.toLowerCase().trim().replace("chr", ""),
                        KbDeconByCnv: ((x.endDecon - x.startDecon) * 0.001).toFixed(2),
                        KbOncoByCnv: ((x.endOnco - x.startOnco) * 0.001).toFixed(2),
                        // startPosition: x.decon ? x.startDecon : x.startOnco,
                        // endPosition: x.decon ? x.endDecon : x.endOnco,

                    }
                })
                let diseaseGenes = Object.entries(resData?.hugoGenes)
                    .filter((x: any[]) => x[1].diseases.length > 0).map((x: any) => x[0])
                resData.cnv.results = cnvWithRegion;
                resData.diseaseGenes = diseaseGenes;
                return resData
            })
    const setFilters = (key: "genes" | "oncoQValue" | "deconBF" | "diseaseGenes", value: string): boolean => {
        if (Object.keys(state.filters).find((x: string) => x === key)) {
            const f: any = state.filters;
            f[key] = value;
            if (key === "oncoQValue") {
                f.oncoQValue = value.trim().length === 0 ? null : f.oncoQValue
            };
            if (key === "deconBF") {
                f.deconBF = value.trim().length === 0 ? null : f.deconBF
            };
            if (key === "diseaseGenes") {
                f.diseaseGenes = !f.diseaseGenes;
            };
            dispatch({ type: CNVsActionType.SET_FILTERS, payload: f });
            return true
        } else {
            alert(`Value : ${key} : ${value} not found on filter fields`)
            return false
        }
    }
    const cleanFilters = () => CSM.CleanFilters("filters", "SET_FILTERS")
    const cleanCNVs = () => {
        dispatch({ type: CNVsActionType.CLEAN_CNVS, payload: "" })
    }

    const handleSort = (value: string) => {
        if (value !== state.sortBy) {
            dispatch({ type: CNVsActionType.SET_SORT_COLUMN, payload: value })
        }
    }

    return (
        <CNVsContext.Provider
            value={{
                cnvs: state.cnvs,
                filters: state.filters,
                sortBy: state.sortBy,
                filterAndSortCnvDataTable: state.filterAndSortCnvDataTable,
                dispatch: {
                    getCNVs,
                    cleanCNVs,
                    setFilters,
                    cleanFilters,
                    handleSort,
                }
            }}
        >
            {props.children}
        </CNVsContext.Provider>
    );
};

const filterAndSortCnvDataTable = (arrayCnvs: any[], filters: FiltersCNVsI, sortBy: string, diseaseGenes?: string[]): any[] => {
    arrayCnvs = arrayCnvs ?? [];
    if (filters.genes.trim().length > 2) {
        const filterSplitted = Array.from(new Set(filters.genes.toUpperCase().trim().split(/[\s,;.]+/))).filter((x: any) => x !== "")
        arrayCnvs = arrayCnvs.filter((x: any) => filterSplitted.some((xx: any) => x.gene.trim().toUpperCase().indexOf(xx) > -1))
    }
    if (filters.deconBF !== null) arrayCnvs = arrayCnvs.filter((x: any) => x.bayesFactor >= (filters.deconBF ?? 0))
    if (filters.oncoQValue !== null) arrayCnvs = arrayCnvs.filter((x: any) => x.qValue !== 0 && x.qValue <= (filters.oncoQValue ?? 0))
    if (filters.diseaseGenes !== false && diseaseGenes) arrayCnvs = arrayCnvs.filter((x: any) => diseaseGenes.indexOf(x.gene) > -1)

    if (sortBy) {
        switch (sortBy) {
            case "sample":
                arrayCnvs = arrayCnvs.sort((a: any, b: any) => compareToSort(a, b, "sample")); break;
            case "gene":
                arrayCnvs = arrayCnvs.sort((a: any, b: any) => compareToSort(a, b, "gene")); break;
            case "onco":
                let arrayCnvs1 = arrayCnvs.filter((x: any) => x.qValue !== 0),
                    arrayCnvs2 = arrayCnvs.filter((x: any) => x.qValue === 0);
                arrayCnvs = arrayCnvs1.sort((a: any, b: any) => compareToSort(a, b, "qValue", false, true));
                arrayCnvs = [...arrayCnvs, ...arrayCnvs2]
                break;
            case "decon":
                arrayCnvs = arrayCnvs.sort((a: any, b: any) => compareToSort(a, b, "bayesFactor", true, true)); break;
            case "region":
                arrayCnvs = arrayCnvs.sort((a: any, b: any) => compareToSort(a, b, "chrNumber"));
                arrayCnvs = arrayCnvs.sort((a: any, b: any) => {
                    if (a.chrNumber === b.chrNumber) { return +a.startPosition < +b.startPosition ? -1 : 1 }
                    else { return +a.chrNumber < +b.chrNumber ? -1 : 1 }
                })
                break;
        }
    }
    return arrayCnvs
}

export default CNVsContextState;

