import { QueryActionType } from "../actionsTypes";
import { } from "../actionsTypes";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
    const { payload, type } = action;
    // console.log("state")
    // console.log(state)
    // console.log("action")
    // console.log(action)
    switch (type) {
        // --- Async methods ---------------------------------------------------
        case QueryActionType.GET_VARIANT_SUCCESS: return { ...state, variant: { data: payload, loading: false, error: false, loaded: true } };
        case QueryActionType.GET_VARIANT_ERROR: return { ...state, variant: { data: state.variant.data, loading: false, error: payload, loaded: false } };
        case QueryActionType.GET_VARIANT_LOADING: return { ...state, variant: { data: state.variant.data, loading: true, error: false, loaded: false } };
        case QueryActionType.GET_VARIANT_REFRESH: return { ...state, variants: { data: null, loading: false, error: false, loaded: false, refresh: true, } };
        
        case QueryActionType.CLEAN_VARIANT: return { ...state, variant: { data: {}, loading: false, error: false, loaded: false } };

        case QueryActionType.GET_VARIANTS_SUCCESS: return { ...state, queryVariants: { data: payload, loading: false, error: false, loaded: true, refresh: false, } };
        case QueryActionType.GET_VARIANTS_ERROR: return { ...state, queryVariants: { data: state.queryVariants.data, loading: false, error: payload, loaded: false, refresh: false, } };
        case QueryActionType.GET_VARIANTS_LOADING: return { ...state, queryVariants: { data: state.queryVariants.data, loading: true, error: false, loaded: false, refresh: false, } };
        case QueryActionType.GET_VARIANTS_REFRESH: return { ...state, queryVariants: { data: null, loading: false, error: false, loaded: false, refresh: true, } };
        case QueryActionType.CLEAN_VARIANTS: return { ...state, queryVariants: { data: null, loading: false, error: false, loaded: false, refresh: false, } };

        case QueryActionType.GET_GENES_VARIANTS_SUCCESS: return { ...state, genesVariants: { data: payload, loading: false, error: false, loaded: true } };
        case QueryActionType.GET_GENES_VARIANTS_ERROR: return { ...state, genesVariants: { data: state.genesVariants.data, loading: false, error: payload, loaded: false } };
        case QueryActionType.GET_GENES_VARIANTS_LOADING: return { ...state, genesVariants: { data: state.genesVariants.data, loading: true, error: false, loaded: false } };
        case QueryActionType.GET_GENES_VARIANTS_REFRESH: return { ...state, genesVariants: { data: null, loading: false, error: false, loaded: false, refresh: true, } };
        
        case QueryActionType.CLEAN_GENES_VARIANTS: return { ...state, genesVariants: { data: {}, loading: false, error: false, loaded: false } };

        case QueryActionType.GET_PHENOTYPES_SUCCESS: return { ...state, hpoPhenotypes: { data: payload, loading: false, error: false, loaded: true } };
        case QueryActionType.GET_PHENOTYPES_ERROR: return { ...state, hpoPhenotypes: { data: state.hpoPhenotypes.data, loading: false, error: payload, loaded: false } };
        case QueryActionType.GET_PHENOTYPES_LOADING: return { ...state, hpoPhenotypes: { data: state.hpoPhenotypes.data, loading: true, error: false, loaded: false } };

        case QueryActionType.GET_QUERY_COMBOS_SUCCESS: return {
            ...state, queryCombos: {
                data: {
                    panels: payload.genesSet,
                    variantGroups: payload.groups,
                    savedFilters: payload.savedResults,
                    study: payload.study
                },
                loading: false, error: false, loaded: true
            }
        };
        case QueryActionType.GET_QUERY_COMBOS_ERROR: return { ...state, queryCombos: { data: state.queryCombos.data, loading: false, error: payload, loaded: false } };
        case QueryActionType.GET_QUERY_COMBOS_LOADING: return { ...state, queryCombos: { data: state.queryCombos.data, loading: true, error: false, loaded: false } };

        // --- Sync methods ---------------------------------------------------
        // --- Filters
        case QueryActionType.SET_FILTERS_SAMPLES: return { ...state, filters_samples: payload };
        case QueryActionType.SET_FILTERS_VARIANTS: return { ...state, filters_variants: payload };
        case QueryActionType.SET_FILTERS_QC: return { ...state, filters_qc: payload };
        case QueryActionType.SET_FILTERS_FREQUENCY: return { ...state, filters_frequency: payload };
        case QueryActionType.SET_FILTERS_PREDICTORS: return { ...state, filters_predictors: payload };
        case QueryActionType.SET_FILTERS_GENES: return { ...state, filters_genes: payload };
        case QueryActionType.SET_FILTERS_PHENOTYPES: return { ...state, filters_phenotypes: payload };
        case QueryActionType.SET_FILTERS_VARIANTGROUPS: return { ...state, filters_variantgroups: payload };
        case QueryActionType.SET_FILTERS_CONSEQUENCE: return { ...state, filters_consequence: payload };
        case QueryActionType.SET_FILTERS_EVIDENCE: return { ...state, filters_evidence: payload };
        case QueryActionType.SET_FILTERS_MITOCHONDRIAL: return { ...state, filters_mitochondrial: payload };
        case QueryActionType.SET_FILTERS_SOMATIC: return { ...state, filters_somatic: payload };
        case QueryActionType.SET_FILTERS_PHARMGKB: return { ...state, filters_pharmgkb: payload };
        // --- Badges
        case QueryActionType.SET_BADGES: return { ...state, badgesFilters: payload };
        case QueryActionType.CLEAN_BADGES: return { ...state, badgesFilters: [] };
        case QueryActionType.SET_BADGES_WARNING: return { ...state, badgesWarning: payload };
        case QueryActionType.CLEAN_BADGES_WARNING: return { ...state, badgesWarning: [] };
        case QueryActionType.SET_BADGES_INFO: return { ...state, badgesInfo: payload };
        case QueryActionType.CLEAN_BADGES_INFO: return { ...state, badgesInfo: [] };
        // --- Filters
        // --- Tabs
        case QueryActionType.SET_TABS: return { ...state, tabs: payload };
        case QueryActionType.SET_TAB_SELECTED: return { ...state, tabSelected: payload };
        // --- Tabs
        case QueryActionType.SET_SCROLLYPOSITION: return { ...state, scrollYPosition: payload };
        default:
            return state;
    }
}