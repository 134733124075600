import React, { useContext, useEffect, useMemo, useReducer, useState } from "react";
import QueryContext, { ContextDataQueryI, FiltersPropsQueryI, TableTitleI } from "./QueryContext";
import QueryReducer from "./QueryReducer";
import { QueryActionType } from "../actionsTypes";
import VariantQueryServices from "../../Services/QueryVariantService";
import HpoGensetServices from "../../Services/HpoGensetService";
import { cancerHotspotsOrganTypes, consequenceEffectValues, consequenceImpactValues, evidenceClinvarValues, getVariantQCFilters, LabelValueI, mitochondrialDiseaseStatusValues, mitochondrialScoreInterpretationValues, mutationTasterValues, polyphenValues, siftValues, variantAcmgValues, variantRelevanceValues, VariantsOrderByValueT, variantTypesValues } from "../../utils/BioFunctionalData";
import { common_0to1_steps001_Array, common_Less10to10_steps01_Array, common_Less1to1_steps001_Array, getWolePageHeight, rangeDataArray } from "../../utils/helpers";
import { LangContext } from "../Lang";
import VariantServices from "../../Services/VariantServices";
import { encryptSessionStorage, encrypt } from "../../utils";
import { ContextStateManager } from "../ContextStateManager";
import { getSampleId_NameTagAffSex } from "src/utils/BioHelpers";
import { VariantTranscriptI } from "../interfaces";
import { allPredictorsNames, predictorsFieldsNamesByTranscript } from "src/Components/Cases/Query/Detail/Predictors/Predictors";
import { getPreviousGenesNamesBadgesInfo } from "src/Components/Cases/Query/BadgePreviousGenesNames";
import { getBadgeUnknownGenesNames } from "src/Components/Cases/Query/BadgeUnknownGenesNames";

type fieldsReqType = "field" | "checkbox" | "options" | "multioptions" | "range" | "slider" | "phenotype";

interface TabsI {
	urlData: string,
	filterName: string,
	id: number,
	data: any
}

interface state_request_dictI {
	state: string,
	sField: string,
	request: string,
	type: fieldsReqType,
	excludeTypes: string[],
	data?: any
}

interface QueryContextStateI {
	children: JSX.Element,
	studyId: string,
}
const QueryContextState = (props: QueryContextStateI) => {
	const { actualLang, dispatch: { translate } } = useContext(LangContext);
	const VariantQS = new VariantQueryServices();
	const VariantS = new VariantServices();
	const HpoGensetQS = new HpoGensetServices();
	const state_request_dictParser: state_request_dictI[] = useMemo(() => {
		return [
			{ state: "filters_samples", sField: "operations", request: "shareVariants", type: "options", excludeTypes: ["simple"] },
			{ state: "filters_samples", sField: "diseaseModel", request: "variants", type: "options", excludeTypes: ["simple", "multiple"] },
			{ state: "filters_variants", sField: "code", request: "rs", type: "field", excludeTypes: [] },
			{ state: "filters_variants", sField: "relevance", request: "comment", type: "multioptions", excludeTypes: [] },
			{ state: "filters_variants", sField: "excludeComments", request: "comment[]_exclude", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: [] },
			{ state: "filters_variants", sField: "disease", request: "variants", type: "options", excludeTypes: [] },
			{ state: "filters_variants", sField: "genotype", request: "zygosity", type: "options", excludeTypes: ["mt-dna"] },
			{ state: "filters_variants", sField: "cromosome", request: "chromosome", type: "field", excludeTypes: [] },
			{ state: "filters_variants", sField: "variantTypes", request: "vartype", type: "multioptions", excludeTypes: [] },
			{ state: "filters_variants", sField: "multipleAlleles", request: "maa", type: "options", excludeTypes: [] },
			{ state: "filters_qc", sField: "filter", request: "filter", type: "multioptions", excludeTypes: ["mt-dna"] },
			{ state: "filters_qc", sField: "excludeFilter", request: "filter[]_exclude", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: ["mt-dna"] },
			{ state: "filters_qc", sField: "depth", request: "readdepth", type: "range", data: { requestNegName: "readdepth_exclude", arrayData: rangeDataArray, isInverted: true }, excludeTypes: ["mt-dna"] },
			{ state: "filters_qc", sField: "ratio", request: "ratiodepthgeno", type: "slider", excludeTypes: ["mt-dna", "somatic"] },
			{ state: "filters_qc", sField: "genotypeQuality", request: "quality", type: "slider", excludeTypes: ["mt-dna"] },
			{ state: "filters_qc", sField: "qual", request: "qual", type: "slider", excludeTypes: ["mt-dna"] },
			{ state: "filters_frequency", sField: "frecGnomAdExomes", request: "exacfrequency", type: "range", data: { requestNegName: "exacfrequency_exclude", arrayData: common_0to1_steps001_Array }, excludeTypes: ["mt-dna"] },
			{ state: "filters_frequency", sField: "frecGnomAdGenomes", request: "gnomaddGenomefrequency", type: "range", data: { requestNegName: "gnomaddGenomefrequency_exclude", arrayData: common_0to1_steps001_Array }, excludeTypes: ["mt-dna"] },
			{ state: "filters_predictors", sField: "revel", request: "revel", type: "slider", excludeTypes: ["mt-dna"] },
			{ state: "filters_predictors", sField: "bayesdel", request: "bayesdel", type: "slider", excludeTypes: ["mt-dna"] },
			{ state: "filters_predictors", sField: "bayesdelnoaf", request: "bayesdelnoaf", type: "slider", excludeTypes: [] },
			{ state: "filters_predictors", sField: "spliceai", request: "spliceai", type: "slider", excludeTypes: ["mt-dna"] },
			{ state: "filters_predictors", sField: "mcap", request: "mcap", type: "slider", excludeTypes: ["mt-dna"] },
			{ state: "filters_predictors", sField: "dann", request: "dann", type: "slider", excludeTypes: ["mt-dna"] },
			{ state: "filters_predictors", sField: "mutationTaster", request: "mutationtaster", type: "multioptions", excludeTypes: ["mt-dna"] },
			{ state: "filters_predictors", sField: "sift", request: "sift", type: "multioptions", excludeTypes: [] },
			{ state: "filters_predictors", sField: "polyphen", request: "polyphen", type: "multioptions", excludeTypes: [] },
			{ state: "filters_genes", sField: "genName", request: "geneName", type: "field", excludeTypes: [] },
			{ state: "filters_genes", sField: "panel", request: "panels", type: "multioptions", excludeTypes: [] },
			{ state: "filters_genes", sField: "excludePanel", request: "panels[]_exclude", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: [] },
			{ state: "filters_genes", sField: "gnomadpli", request: "gnomadpli", type: "slider", excludeTypes: [] },
			{ state: "filters_genes", sField: "gnomadzmiss", request: "gnomadzmiss", type: "range", data: { requestNegName: "gnomadzmiss_exclude", arrayData: common_Less10to10_steps01_Array, isInverted: true }, excludeTypes: [] },
			{ state: "filters_genes", sField: "omimgenes", request: "omimgenes", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: [] },
			{ state: "filters_phenotypes", sField: "phenotype", request: "hpoId", type: "phenotype", excludeTypes: [] },
			{ state: "filters_variantgroups", sField: "groups", request: "groups", type: "multioptions", excludeTypes: [] },
			{ state: "filters_variantgroups", sField: "excludeGroups", request: "groups[]_exclude", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: [] },
			{ state: "filters_consequence", sField: "impact", request: "impact", type: "multioptions", excludeTypes: [] },
			{ state: "filters_consequence", sField: "effect", request: "effect", type: "multioptions", excludeTypes: [] },
			{ state: "filters_consequence", sField: "excludeEffects", request: "effect[]_exclude", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: [] },
			{ state: "filters_evidence", sField: "veredict", request: "intervarVeredict", type: "multioptions", excludeTypes: ["mt-dna"] },
			{ state: "filters_evidence", sField: "excludeVeredict", request: "intervarVeredict[]_exclude", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: ["mt-dna"] },
			{ state: "filters_evidence", sField: "acmgBayClasification", request: "bayesVeredict", type: "multioptions", excludeTypes: ["mt-dna"] },
			{ state: "filters_evidence", sField: "excludeAcmgBayClasification", request: "bayesVeredict[]_exclude", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: ["mt-dna"] },
			{ state: "filters_evidence", sField: "acmgBayScore", request: "bayesscore", type: "slider", excludeTypes: ["mt-dna"] },
			{ state: "filters_evidence", sField: "clinvar", request: "clnsig", type: "multioptions", excludeTypes: [] },
			{ state: "filters_evidence", sField: "excludeClinvar", request: "clnsig[]_exclude", type: "checkbox", data: { requestTrueVal: "on" }, excludeTypes: [] },
			{ state: "filters_evidence", sField: "dbsnp", request: "unknown", type: "options", excludeTypes: [] },
			{ state: "filters_mitochondrial", sField: "reference", request: "prioritized", type: "options", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_mitochondrial", sField: "scoreInterpretation", request: "mitoTIP_Interpretation", type: "multioptions", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_mitochondrial", sField: "apogeescore", request: "apogeescore", type: "slider", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_mitochondrial", sField: "diseaseStatus", request: "mitoMap_DiseaseStatus", type: "multioptions", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_mitochondrial", sField: "heteroplasmy", request: "heteroplasmy", type: "slider", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_mitochondrial", sField: "afhomoplasmy", request: "afhomoplasmy", type: "slider", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "oncokb", request: "oncokb", type: "checkbox", data: { requestTrueVal: "isNotNull" }, excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "cosmic", request: "cosmic", type: "checkbox", data: { requestTrueVal: "isNotNull" }, excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "hotspots", request: "hotspots", type: "checkbox", data: { requestTrueVal: "isNotNull" }, excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "civic", request: "civic", type: "checkbox", data: { requestTrueVal: "isNotNull" }, excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "pmkb", request: "pmkbvariant", type: "checkbox", data: { requestTrueVal: "isNotNull" }, excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "pon", request: "excludepon", type: "checkbox", data: { requestTrueVal: "isNull" }, excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "germq", request: "germq", type: "checkbox", data: { requestTrueVal: "isNotNull" }, excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "oncoevidence", request: "oncoevidence", type: "checkbox", data: { requestTrueVal: "isNotNull" }, excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "vaf", request: "ratiodepthgeno", type: "slider", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "hotspotsorgantypes", request: "hotspotsorgantypes[]", type: "options", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_somatic", sField: "pmkbtiers", request: "pmkbtier[]", type: "options", excludeTypes: ["array", "wes", "wgs", "panel"] },
			{ state: "filters_pharmGkb", sField: "pharmGkb", request: "pgkbdrugs", type: "multioptions", excludeTypes: ["mt-dna", "wes", "wgs", "panel"] },
			{ state: "filters_pharmGkb", sField: "evidence", request: "pgkbEvidence[]", type: "multioptions", excludeTypes: ["mt-dna", "wes", "wgs", "panel"] },
		]
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const selectNonValue = useMemo(() => { return { label: "...", value: "" } }, [])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const radioButtonAll = useMemo(() => { return { label: translate('common.all'), value: "" } }, [])

	const getSamplesLabelValueInit = () => {
		const samplesOptions: {
			label: string,
			value: { name: string, value: string, affected: boolean },
			checked: boolean
		}[] = (state.queryCombos?.data?.study?.samples ?? []).map((x: any) => {
			return {
				label: x.sample.name,
				value: {
					name: `sample_${x.id}`,
					value: getSampleId_NameTagAffSex(x.sample.name, x.tag, x.afectado, x.sample.sexo),
					affected: x.afectado ? x.afectado : false
				},
				checked: true
			}
		})
		return samplesOptions;
	}
	const getSamplesLabelValue = () => {
		try {
			const urlPath = new URL(window.location.href);
			let urlSamples: any = urlPath.pathname.toLowerCase().split("sample_")
			if (urlSamples.length > 1) {
				urlSamples = urlSamples.map((x: any) => {
					let xx = x.split("=")
					if (xx.length > 0 && !isNaN(xx[0].trim()))
						return +xx[0]
					return false
				}).filter((x: any) => x)
				urlSamples = urlSamples.length > 0 ? urlSamples : false
			} else urlSamples = false
			const samplesOptions: {
				label: string,
				value: { name: string, value: string, affected: boolean },
				checked: boolean
			}[] = (state.queryCombos?.data?.study?.samples ?? []).map((x: any) => {
				const checked = urlSamples.length > 0 ? urlSamples.find((y: any) => +y === +x.id) : true
				return {
					label: x.sample.name,
					value: {
						name: `sample_${x.id}`,
						value: getSampleId_NameTagAffSex(x.sample.name, x.tag, x.afectado, x.sample.sexo),
						affected: x.afectado ? x.afectado : false
					},
					checked: checked ? true : false
				}
			})
			return samplesOptions;
		}
		catch (e: any) {
			return [];
		};
	}
	const [filters_samples_samples, setfilters_samples_samples] = useState<any>([])
	//------- Filters Parameters --------------------------------------------------------------------------------------------
	const filters_samplesParams = useMemo(() => {
		return {
			diseaseModel: [
				selectNonValue,
				{ label: translate("casesQuery.diseaseModelOptions.xLinkedDominant"), value: "xLinkedDominant" },
				{ label: translate("casesQuery.diseaseModelOptions.xLinkedRecessive"), value: "xLinkedRecessive" },
				{ label: translate("casesQuery.diseaseModelOptions.autosomalDominant"), value: "autosomalDominant" },
				{ label: translate("casesQuery.diseaseModelOptions.autosomalRecessive"), value: "autosomalRecessive" },
				{ label: translate("casesQuery.diseaseModelOptions.compoundHeterozygosity"), value: "compoundHeterozygosity" },
			],
			operations: [
				{ label: translate('casesQuery.samplesOperationOptions.atLeastOne'), value: undefined },
				{ label: translate('casesQuery.samplesOperationOptions.inAllSamples'), value: "shared" },
				{ label: translate('casesQuery.samplesOperationOptions.justOneSample'), value: "complement" }
			],
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actualLang])
	const filters_variantsParams = useMemo(() => {
		return {
			relevance: variantRelevanceValues(translate),
			disease: [
				selectNonValue,
				{ label: translate('casesQuery.diseaseModelAutosomal'), value: "compoundHeterozygosity" },
			],
			genotype: [
				selectNonValue,
				{ label: translate('common.homozygous'), value: "true" },
				{ label: translate('common.heterozygous'), value: "false" },
			],
			variantTypes: variantTypesValues,
			multipleAlleles: [
				radioButtonAll,
				{ label: translate('common.yes'), value: "isNotNull" },
				{ label: translate('common.no'), value: "isNull" }

			]
		}
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	const filters_qcParams = useMemo(() => {
		return {
			filter: [
				selectNonValue,
				...getVariantQCFilters("germline")
			],
			filterSomatic: [
				selectNonValue,
				...getVariantQCFilters("somatic")
			],
			depth: rangeDataArray,
			ratio: Array.from(Array(101).keys()).map((x) => parseFloat((x * 1 / 100).toFixed(2))),
			genotypeQuality: Array.from(Array(101).keys()),
			qual: Array.from(Array(51).keys()).map((x) => x * 100)
		}

	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	const filters_frequencyParams = useMemo(() => {
		return {
			frecGnomAdExomes: common_0to1_steps001_Array,
			frecGnomAdGenomes: common_0to1_steps001_Array
		}
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	const filters_predictorsParams = useMemo(() => {
		return {
			revel: common_0to1_steps001_Array,
			bayesdel: common_Less1to1_steps001_Array,
			bayesdelnoaf: common_Less1to1_steps001_Array,
			spliceai: common_0to1_steps001_Array,
			mcap: common_0to1_steps001_Array,
			dann: common_0to1_steps001_Array,
			mutationTaster: mutationTasterValues,
			sift: siftValues,
			polyphen: polyphenValues,
		}
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	const filters_consequenceParams = useMemo(() => {
		return {
			impact: consequenceImpactValues,
			effect: consequenceEffectValues
		}

	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	const filters_evidenceParams = useMemo(() => {
		return {
			veredict: variantAcmgValues,
			acmgBayClasification: variantAcmgValues,
			acmgBayScore: common_0to1_steps001_Array,
			clinvar: evidenceClinvarValues,
			dbsnp: [
				{ label: translate('common.all'), value: "" },
				{ label: translate('common.yes'), value: "isNotNull" },
				{ label: translate('common.no'), value: "isNull" }
			]
		}

	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	const filters_mitochondrialParams = useMemo(() => {
		return {
			reference: [
				radioButtonAll,
				{ label: translate("casesQuery.prioritized"), value: "isNotNull" },
				{ label: translate("casesQuery.notPrioritized"), value: "isNull" }
			],
			scoreInterpretation: mitochondrialScoreInterpretationValues,
			apogeescore: Array.from(Array(101).keys()).map((x) => parseFloat((x * 1 / 100).toFixed(2))),
			diseaseStatus: mitochondrialDiseaseStatusValues(translate),
			heteroplasmy: Array.from(Array(101).keys()).map((x) => parseFloat((x * 1 / 100).toFixed(2))),
			afhomoplasmy: Array.from(Array(101).keys()).map((x) => parseFloat((x * 1 / 100).toFixed(2)))
		}

	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	const filters_somaticParams = useMemo(() => {
		const hotspotsvalues = [
			selectNonValue,
			...cancerHotspotsOrganTypes.map((x: string): LabelValueI => {
				return { label: translate(`casesQuery.hotspotsOrganTypesOptions.${x}`), value: x }
			})
		]
		const pmkbtiers = [
			selectNonValue,
			{ label: "Tier 1", value: "1" },
			{ label: "Tier 2", value: "2" },
			{ label: "Tier 3", value: "3" }
		]
		return {
			hotspotsorgantypes: hotspotsvalues,
			pmkbtiers: pmkbtiers,
			vaf: Array.from(Array(101).keys())
		}
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	const filters_pharmgkbParams = useMemo(() => {
		return {
			pharmGkb: [
				{ label: "1A - High", value: "1A" },
				{ label: "1B - High", value: "1B" },
				{ label: "2A - Moderate", value: "2A" },
				{ label: "2B - Moderate", value: "2B" },
				{ label: "3 - Low", value: "3" },
				{ label: "4 - Preliminary", value: "4" }
			],
			evidence: [
				{ value: 'amitriptyline_(PA448385)', label: 'amitriptyline' },
				{ value: 'aripiprazole_(PA10026)', label: 'aripiprazole' },
				{ value: 'atomoxetine_(PA134688071)', label: 'atomoxetine' },
				{ value: 'atorvastatin_(PA448500)', label: 'atorvastatin' },
				{ value: 'brivaracetam_(PA166153491)', label: 'brivaracetam' },
				{ value: 'carisoprodol_(PA448809)', label: 'carisoprodol' },
				{ value: 'carvedilol_(PA448817)', label: 'carvedilol' },
				{ value: 'celecoxib_(PA448871)', label: 'celecoxib' },
				{ value: 'citalopram_(PA449015)', label: 'citalopram' },
				{ value: 'clobazam_(PA10888)', label: 'clobazam' },
				{ value: 'clomipramine_(PA449048)', label: 'clomipramine' },
				{ value: 'clopidogrel active metabolite_(PA166131260)', label: 'clopidogrel active metabolite' },
				{ value: 'clopidogrel_(PA449053)', label: 'clopidogrel' },
				{ value: 'clozapine_(PA449061)', label: 'clozapine' },
				{ value: 'codeine_(PA449088)', label: 'codeine' },
				{ value: 'desipramine_(PA449233)', label: 'desipramine' },
				{ value: 'desvenlafaxine_(PA165958374)', label: 'desvenlafaxine' },
				{ value: 'dextromethorphan_(PA449273)', label: 'dextromethorphan' },
				{ value: 'diazepam_(PA449283)', label: 'diazepam' },
				{ value: 'doxepin_(PA449409)', label: 'doxepin' },
				{ value: 'escitalopram_(PA10074)', label: 'escitalopram' },
				{ value: 'esomeprazole_(PA10075)', label: 'esomeprazole' },
				{ value: 'fesoterodine_(PA165958376)', label: 'fesoterodin' },
				{ value: 'fluoxetine_(PA449673)', label: 'fluoxetine' },
				{ value: 'fluvoxamine_(PA449690)', label: 'fluvoxamine' },
				{ value: 'formoterol_(PA134687907)', label: 'formoterol' },
				{ value: 'galantamine_(PA449726)', label: 'galantamine' },
				{ value: 'iloperidone_(PA161199368)', label: 'iloperidone' },
				{ value: 'imipramine_(PA449969)', label: 'imipramine' },
				{ value: 'lansoprazole_(PA450180)', label: 'lansoprazole' },
				{ value: 'methotrexate_(PA450428)', label: 'methotrexate' },
				{ value: 'metoprolol_(PA450480)', label: 'metoprolol' },
				{ value: 'nortriptyline_(PA450657)', label: 'nortriptyline' },
				{ value: 'omeprazole_(PA450704)', label: 'omeprazole' },
				{ value: 'ondansetron_(PA450705)', label: 'ondansetron' },
				{ value: 'pantoprazole_(PA450774)', label: 'pantoprazole' },
				{ value: 'paroxetine_(PA450801)', label: 'paroxetine' },
				{ value: 'perphenazine_(PA450882)', label: 'perphenazine' },
				{ value: 'phenytoin_(PA450947)', label: 'phenytoin' },
				{ value: 'pimozide_(PA450965)', label: 'pimozide' },
				{ value: 'piroxicam_(PA450985)', label: 'piroxicam' },
				{ value: 'prasugrel_(PA154410481)', label: 'prasugrel' },
				{ value: 'pravastatin_(PA451089)', label: 'pravastatin' },
				{ value: 'propafenone_(PA451131)', label: 'propafenone_' },
				{ value: 'propranolol_(PA451145)', label: 'propranolol' },
				{ value: 'quinidine_(PA451209)', label: 'quinidine' },
				{ value: 'rabeprazole_(PA451216)', label: 'rabeprazole' },
				{ value: 'risperidone_(PA451257)', label: 'risperidone' },
				{ value: 'rosuvastatin_(PA134308647)', label: 'rosuvastatin' },
				{ value: 'simvastatin acid_(PA166129446)', label: 'simvastatin acid' },
				{ value: 'simvastatin_(PA451363)', label: 'simvastatin' },
				{ value: 'tamoxifen_(PA451581)', label: 'tamoxifen' },
				{ value: 'tetrabenazine_(PA140222719)', label: 'tetrabenazine' },
				{ value: 'ticagrelor_(PA165374673)', label: 'ticagrelor' },
				{ value: 'thioridazine_(PA451666)', label: 'thioridazine' },
				{ value: 'tramadol_(PA451735)', label: 'tramadol' },
				{ value: 'trimipramine_(PA451791)', label: 'trimipramine' },
				{ value: 'venlafaxine_(PA451866)', label: 'venlafaxine' },
				{ value: 'voriconazole_(PA10233)', label: 'voriconazole' },
				{ value: 'vortioxetine_(PA166122595)', label: 'vortioxetine' },
				{ value: 'warfarin_(PA451906)', label: 'warfarin<' }],
		}

	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[actualLang])
	//------- Filters Parameters --------------------------------------------------------------------------------------------

	const filtersInitialState: FiltersPropsQueryI = {
		filters_samples: {
			samples: filters_samples_samples,
			operations: filters_samplesParams.operations[0],
			diseaseModel: selectNonValue,
		},
		filters_variants: {
			code: "",
			relevance: [],
			excludeComments: false,
			disease: selectNonValue,
			genotype: selectNonValue,
			cromosome: "",
			variantTypes: [],
			multipleAlleles: radioButtonAll
		},
		filters_qc: {
			filter: [],
			depth: [rangeDataArray[0], rangeDataArray[rangeDataArray.length - 1]],
			ratio: 0,
			genotypeQuality: 0,
			qual: 0

		},
		filters_frequency: {
			frecGnomAdExomes: [common_0to1_steps001_Array[0], common_0to1_steps001_Array[common_0to1_steps001_Array.length - 1]],
			frecGnomAdGenomes: [common_0to1_steps001_Array[0], common_0to1_steps001_Array[common_0to1_steps001_Array.length - 1]]
		},
		filters_predictors: {
			revel: 0,
			bayesdel: -1,
			bayesdelnoaf: -1,
			mcap: 0,
			dann: 0,
			spliceai: 0,
			mutationTaster: [],
			sift: [],
			polyphen: []
		},
		filters_genes: {
			genName: "",
			panel: [],
			excludePanel: false,
			omimgenes: false,
			gnomadpli: 0,
			gnomadzmiss: [common_Less10to10_steps01_Array[0], common_Less10to10_steps01_Array[common_Less10to10_steps01_Array.length - 1]],
		},
		filters_phenotypes: {
			aux_search: "",
			phenotype: [],
		},
		filters_variantgroups: {
			groups: [],
			excludeGroups: false,
		},
		filters_consequence: {
			impact: [],
			effect: [],
			excludeEffects: false,
		},
		filters_evidence: {
			veredict: [],
			excludeVeredict: false,
			acmgBayClasification: [],
			excludeAcmgBayClasification: false,
			acmgBayScore: 0,
			clinvar: [],
			excludeClinvar: false,
			dbsnp: radioButtonAll
		},
		filters_mitochondrial: {
			reference: radioButtonAll,
			scoreInterpretation: [],
			apogeescore: 0,
			diseaseStatus: [],
			heteroplasmy: 0,
			afhomoplasmy: 0
		},
		filters_somatic: {
			oncokb: false,
			cosmic: false,
			civic: false,
			hotspots: false,
			pmkb: false,
			hotspotsorgantypes: [],
			pmkbtiers: [],
			oncoevidence: false,
			vaf: 0,
			pon: false,
		},
		filters_pharmgkb: {
			pharmGkb: [],
			evidence: []

		}
	}

	const initialState: ContextDataQueryI = {
		// -------------- Async data --------------
		// Data with variants query
		variant: { data: {}, loaded: false, loading: false, error: false },
		queryVariants: { data: null, loaded: false, loading: false, error: false },
		genesVariants: { data: {}, loaded: false, loading: false, error: false },
		hpoPhenotypes: { data: {}, loaded: false, loading: false, error: false },
		queryCombos: { data: undefined, loaded: false, loading: false, error: false },
		// -------------- Sync data --------------
		tabs: {},
		tabSelected: {},
		tabsMax: 3,
		badgesFilters: [],
		badgesWarning: [],
		badgesInfo: [],
		scrollYPosition: {
			y: null,
			height: null
		},
		...filtersInitialState
	};
	const [state, dispatch] = useReducer(QueryReducer, initialState);
	const CSM = new ContextStateManager(state, initialState, QueryActionType, dispatch)
	//------------------------- Async ----------------------------------------------
	// CSM.GetAsyncData("GET_CASES_", { filters, pageNumber, pageSize }, StudyS, "search")
	const generateVariantTranscriptData = (resData: any): any => {
		let ensembl_transcriptids = resData?.variant?.infoColumnData?.ENSEMBL_TRANSCRIPTID;
		if (!resData?.variant?.infoColumnData?.ENSEMBL_TRANSCRIPTID) {
			const aachange_ensgene = resData?.variant?.infoColumnData?.AACHANGE_ENSGENE
			const transcript_id = resData?.variant?.infoColumnData?.TRANSCRIPT_ID
			ensembl_transcriptids = ""
			if (transcript_id && transcript_id.indexOf("ENST") > -1) {
				ensembl_transcriptids = transcript_id
			}
			if (aachange_ensgene && aachange_ensgene.indexOf(":ENST") > -1) {
				let aaFiltered: any = aachange_ensgene.split(",")
					.filter((x: String) => x.indexOf(":ENST") > -1)
					.map((x: String) => {
						let result: String = x ?? "";
						result = result.substring(result.indexOf(":ENST") + 1, result.length)
						result = result.indexOf(":") > -1 ? result.substring(0, result.indexOf(":")) : result;
						result = result.indexOf(".") > -1 ? result.substring(0, result.indexOf(".")) : result;
						return result;
					})
					.filter((x: String) => x !== "");
				if (ensembl_transcriptids !== "") aaFiltered.push(ensembl_transcriptids)
				let aaFilteredSet: any = new Set(aaFiltered);
				aaFiltered = [...aaFilteredSet].join(",");
				ensembl_transcriptids = aaFiltered;
			} else {
				ensembl_transcriptids = ensembl_transcriptids && ensembl_transcriptids !== "" ? ensembl_transcriptids : undefined;
			}
		}
		// if (!ensembl_transcriptids && ensembl_transcriptids.trim() === "") return resData;
		const geneHugo = resData ? resData?.hugoGenes[`${Object.keys(resData?.hugoGenes)[0]}`] : undefined;
		let ensembl_transcripts = ensembl_transcriptids?.trim().split(",");
		const genereateArrayByValues = (fieldName: String, size: number): any[] => {
			const result: any[] = []
			const fieldValue = resData?.variant?.infoColumnData?.[`${fieldName}`] ? `${resData?.variant?.infoColumnData?.[`${fieldName}`]}` : "";
			let fieldValues: any = [];
			if (fieldValue)
				if (predictorsFieldsNamesByTranscript.indexOf(fieldName) === -1) fieldValues = Array(size).fill(fieldValue);
				else fieldValues = fieldValue.split(',');
			for (let i = 0; i < size; i++) result.push((fieldValues[i]) ? `${fieldValues[i]}` : undefined)
			return result
		}
		const infoCdProps = allPredictorsNames;
		let newAllPredictors: any = {};
		if (ensembl_transcriptids)
			infoCdProps.forEach((x: String) => {
				const xValue = genereateArrayByValues(x, ensembl_transcripts.length);
				newAllPredictors[`${x}`] = xValue
			})
		let ensmblg: any = resData?.variant?.infoColumnData?.ENSEMBL_GENEID;
		ensmblg = ensmblg ? ensmblg.split(",") : [];
		const getRefseqTranscipts = () => {
			let aachange_refseq: any = resData?.variant?.infoColumnData?.AACHANGE_REFGENE;
			let aaChangeRefseqData: VariantTranscriptI[] = [];
			if (!aachange_refseq) {
				const mane = geneHugo.refseqManeSelect?.split(".")[0];
				aaChangeRefseqData = [{
					id: mane ? mane : geneHugo.id?.split(".")[0],
					type: "RefSeq",
					isMane: true
				}];
			} else {
				aaChangeRefseqData = aachange_refseq.trim().split(",")
					.map((x: string) => {
						let transcripts = x.split(":");
						const startIndex = transcripts.findIndex(item => item.includes('NM_'));
						if (startIndex !== -1) {
							transcripts = transcripts.slice(startIndex);
						}
						let isMane = (geneHugo?.refseqManeSelect ?? "").indexOf(`${transcripts[0]}`) > -1;
						let transcript = {
							id: `${transcripts[0]}`,
							type: "RefSeq",
							isMane: isMane,
							description: transcripts.slice(1, transcripts.length).join(" - ")
						} as VariantTranscriptI
						return transcript;
					}).sort((a: any, b: any) => {
						return (b.isMane - a.isMane)
					}).filter((item: any, index: number, self: any) =>
						index === self.findIndex((i: any) => i.id === item.id)
					);
			}
			return aaChangeRefseqData;
		}
		const getEnsemblTranscripts = (): VariantTranscriptI[] => {

			let aaChangeEnsgene: any = resData?.variant?.infoColumnData?.AACHANGE_ENSGENE ? `${resData?.variant?.infoColumnData?.AACHANGE_ENSGENE}`.trim().toUpperCase() : undefined;
			aaChangeEnsgene = aaChangeEnsgene !== "" && aaChangeEnsgene !== "UNKNOWN" ? aaChangeEnsgene : undefined;
			let aaChangeEnsgeneRaw: any = [];
			let aaChangeEnsgeneData: VariantTranscriptI[] = [];
			if (aaChangeEnsgene) aaChangeEnsgeneRaw = aaChangeEnsgene.split(",")
			for (let i = 0; i < (ensembl_transcripts ?? []).length; i++) {
				let val: any = aaChangeEnsgeneRaw[i] ? `${aaChangeEnsgeneRaw[i]}` : undefined;
				let valSplitted = (val ?? "").split(":");
				let description;
				if (valSplitted.length > 2) {
					description = val.substring(val.indexOf(valSplitted[2]), val.length - 1).replaceAll(":", " - ");
				}
				val = {
					id: `${ensembl_transcripts[i]}`,
					type: "Ensembl",
					geneId: i < ensmblg.length ? `${ensmblg[i]}` : undefined,
					description: description
				} as VariantTranscriptI
				aaChangeEnsgeneData.push(val)
			}
			return aaChangeEnsgeneData;
		}
		let refseqTranscrtipt = getRefseqTranscipts();
		let aaChangeEnsemlValues = getEnsemblTranscripts();
		const keys = Object.keys(newAllPredictors);
		const maneSelect = geneHugo?.ensemblManeSelect && geneHugo.ensemblManeSelect.split(".")[0];

		let ensemblTranscripts: VariantTranscriptI[] = []
		if (keys.length)
			ensemblTranscripts = newAllPredictors[keys[0]].map((x: any, index: number) => {// TOMAR EL NUEVO VALOR QUE FILTRA Y VER!
				const isMane = (maneSelect && maneSelect.indexOf(aaChangeEnsemlValues[index].id.split(".")[0]) > -1) ? true : false;
				const newObj: any = {
					...aaChangeEnsemlValues[index],
					isMane: isMane,
					predictors: {},
				};
				keys.forEach(key => newObj.predictors[key] = newAllPredictors[key][index]);
				return newObj as VariantTranscriptI;
			}).sort((a: any, b: any) => { return (b.isMane - a.isMane) })
		return { ...resData, transcriptData: ensemblTranscripts.concat(refseqTranscrtipt) }
	}
	const getVariant = async (studyId: number, variantId: string) => CSM.GetAsyncData(
		"GET_VARIANT_", { studyId, variantId }, VariantS, "read_variant", (resData) => generateVariantTranscriptData(resData))
	const refreshVariant = () => dispatch({ type: QueryActionType.GET_VARIANT_REFRESH })
	const getGenesVariants = async (geneName: string, variantsId: string, pageNumber?: number, pageSize?: number, sortBy?: string) => {
		return CSM.GetAsyncData("GET_GENES_VARIANTS_", { studyId: props.studyId, geneName, variantsId, pageNumber, pageSize, sortBy }, VariantS, "read_all_variants")
	}
	const refreshGenesVariants = () => dispatch({ type: QueryActionType.GET_GENES_VARIANTS_REFRESH })
	const cleanVariant = () => { dispatch({ type: QueryActionType.CLEAN_VARIANT, payload: null }) }
	const cleanGenesVariants = () => { dispatch({ type: QueryActionType.CLEAN_GENES_VARIANTS, payload: null }) }
	const getQueryVariants = async (urlFilterParams?: any, pageNumber?: number, pageSize?: number, sortDirection?: string, sortColumn?: VariantsOrderByValueT[]) => {
		CSM.GetAsyncData("GET_VARIANTS_",
			{ urlFilterParams, pageNumber, pageSize, sortDirection: sortDirection ?? "ASC", sortColumn },
			VariantQS, "filter_variants_by_urlQueryString", (resData) => {
				let at: any = getActualTabs(), tabsId: string = getTabsId();
				if (at && tabsId) {
					const newFilterTabs = { ...state.tabs }
					newFilterTabs[tabsId].map((x: any) => {
						if (`${x.id}` === `${getActualTabSelectedId()}`) {
							x["data"] = resData
							x["urlData"] = urlFilterParams
						}
						return x
					})
					setFiltersTabsAndSessionS(newFilterTabs)
				}
				cleanVariant()
				cleanGenesVariants()
				return resData
			})
	};
	const cleanQueryVariants = () => { dispatch({ type: QueryActionType.GET_VARIANTS_SUCCESS, payload: initialState.queryVariants.data }); }
	const refreshQueryVariants = () => dispatch({ type: QueryActionType.GET_VARIANTS_REFRESH })

	const getPhenotypes = async () => CSM.GetAsyncData("GET_PHENOTYPES_", {}, HpoGensetQS, "read_hpogenset")
	const getQueryCombos = async () => CSM.GetAsyncData("GET_QUERY_COMBOS_", { caseId: props.studyId }, VariantQS, "read_combos")

	//------------------------- Sync ----------------------------------------------
	//------------------------- Filters ----------------------------------------------
	//------- Filters Parameters that use state as input--------------------------------------------------------------------------------------------
	const filters_genesParams = useMemo(() => {
		const getGenesPanel = () => {
			let genesPanel: any[] = [];
			if (state.queryCombos.data?.panels) {
				genesPanel = state.queryCombos.data?.panels.map((x: any, i: number) => {
					const description = x.description.length > 100 ? `${x.description.substr(0, 90)}...` : x.description
					return {
						label: `${x.name} (${x.totalGenes}) : ${description}`,
						value: `${x.key}:${x.regionKey}:${x.name}`
					}
				})
			}
			return {
				panel: genesPanel,
				gnomadpli: common_0to1_steps001_Array,
				gnomadzmiss: common_Less10to10_steps01_Array
				// Array.from(Array(101).keys()).map((x) => parseFloat((x * 1 / 100).toFixed(2)))
			}
		}
		return getGenesPanel()
	}, [state.queryCombos])
	const filters_phenotypesParams = useMemo(() => {
		const getPhenotypesOptions = () => {
			let phenotypesOptions: any[] = [];
			if (state.filters_phenotypes.aux_search.length > 2) {
				phenotypesOptions = (state.hpoPhenotypes?.data?.symptom ?? [])
					.filter((x: any) => x.indexOf(state.filters_phenotypes.aux_search) > -1)
					.map((x: any, i: number) => {
						return {
							label: x.replaceAll("_", " "),
							value: x.replaceAll("_", "+")
						}
					})
			}
			return { phenotype: phenotypesOptions }
		}
		return getPhenotypesOptions()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.filters_phenotypes, state.hpoPhenotypes])
	const filters_variantgroupsParams = useMemo(() => {
		const getVariantGroupsOptions = () => {
			let variantGroupsOptions: any[] = [];
			if (state.queryCombos.data?.variantGroups) {
				variantGroupsOptions = state.queryCombos.data?.variantGroups.map((x: any, i: number) => {
					const description = x.description.length > 100 ? `${x.description.substr(0, 90)}...` : x.description
					return { label: `${x.name} (${x.countVariantIds}) : ${description}`, value: x.id }
				})
			}
			return { groups: variantGroupsOptions }
		}
		return getVariantGroupsOptions();
	}, [state.queryCombos.data])
	//------- Filters Parameters that use state as input--------------------------------------------------------------------------------------------

	const commonCleanFilters = (initialState: any, state: any, dispatchMethod: any) => {
		if (JSON.stringify(initialState) !== JSON.stringify(state)) {
			dispatch({ type: dispatchMethod, payload: initialState });
		}
	}
	const setfilters_samples = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_SAMPLES, payload: value })
	const cleanfilters_samples = () => commonCleanFilters(initialState.filters_samples, state.filters_samples, QueryActionType.SET_FILTERS_SAMPLES)
	const setfilters_variants = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_VARIANTS, payload: value })
	const cleanfilters_variants = () => commonCleanFilters(initialState.filters_variants, state.filters_variants, QueryActionType.SET_FILTERS_VARIANTS)
	const setfilters_qc = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_QC, payload: value });
	const cleanfilters_qc = () => commonCleanFilters(initialState.filters_qc, state.filters_qc, QueryActionType.SET_FILTERS_QC);
	const setfilters_frequency = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_FREQUENCY, payload: value });
	const cleanfilters_frequency = () => commonCleanFilters(initialState.filters_frequency, state.filters_frequency, QueryActionType.SET_FILTERS_FREQUENCY);
	const setfilters_predictors = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_PREDICTORS, payload: value });
	const cleanfilters_predictors = () => commonCleanFilters(initialState.filters_predictors, state.filters_predictors, QueryActionType.SET_FILTERS_PREDICTORS);
	const setfilters_genes = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_GENES, payload: value });
	const cleanfilters_genes = () => commonCleanFilters(initialState.filters_genes, state.filters_genes, QueryActionType.SET_FILTERS_GENES);
	const setfilters_phenotypes = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_PHENOTYPES, payload: value });
	const cleanfilters_phenotypes = () => commonCleanFilters(initialState.filters_phenotypes, state.filters_phenotypes, QueryActionType.SET_FILTERS_PHENOTYPES);
	const setfilters_variantgroups = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_VARIANTGROUPS, payload: value });
	const cleanfilters_variantgroups = () => commonCleanFilters(initialState.filters_variantgroups, state.filters_variantgroups, QueryActionType.SET_FILTERS_VARIANTGROUPS);
	const setfilters_consequence = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_CONSEQUENCE, payload: value });
	const cleanfilters_consequence = () => commonCleanFilters(initialState.filters_consequence, state.filters_consequence, QueryActionType.SET_FILTERS_CONSEQUENCE);
	const setfilters_evidence = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_EVIDENCE, payload: value });
	const cleanfilters_evidence = () => commonCleanFilters(initialState.filters_evidence, state.filters_evidence, QueryActionType.SET_FILTERS_EVIDENCE);
	const setfilters_mitochondrial = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_MITOCHONDRIAL, payload: value });
	const cleanfilters_mitochondrial = () => commonCleanFilters(initialState.filters_mitochondrial, state.filters_mitochondrial, QueryActionType.SET_FILTERS_MITOCHONDRIAL);
	const setfilters_somatic = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_SOMATIC, payload: value });
	const cleanfilters_somatic = () => commonCleanFilters(initialState.filters_somatic, state.filters_somatic, QueryActionType.SET_FILTERS_SOMATIC);
	const setfilters_pharmgkb = (value: any) => dispatch({ type: QueryActionType.SET_FILTERS_PHARMGKB, payload: value });
	const cleanfilters_pharmgkb = () => commonCleanFilters(initialState.filters_pharmgkb, state.filters_pharmgkb, QueryActionType.SET_FILTERS_PHARMGKB);
	const cleanfilters_all = () => {
		cleanfilters_samples(); cleanfilters_variants(); cleanfilters_qc(); cleanfilters_frequency(); cleanfilters_predictors();
		cleanfilters_genes(); cleanfilters_phenotypes(); cleanfilters_variantgroups(); cleanfilters_consequence();
		cleanfilters_evidence(); cleanfilters_mitochondrial(); cleanfilters_pharmgkb();
		cleanfilters_somatic();
	}

	//------------------------- Parse values 
	function getFilterStateSeted(st: any) {
		const stateName = Object.getOwnPropertyNames(st)[0];
		st = st[stateName];
		let aa: any = filtersInitialState
		const fieldsSeted = Object.keys(st)
			.filter((x: string) => {
				return JSON.stringify(st[x]) !== JSON.stringify(aa[stateName][x]);
			})
		return fieldsSeted
	}
	function parseFilters_StateToRequest(): any {
		let i: any = {}
		const setFieldReq = (st: any, field: string, requestName: string) => {
			let stateField = state[st][field]
			stateField = stateField.replace(/\s+/g, ' ').trim().toUpperCase().replace("\t", " ")
			if (stateField.trim().length > 0) i[requestName] = stateField
		}
		const setFieldCheckboxReq = (st: any, field: string, requestName: string, trueVal: string) => {
			const stateField = state[st][field]
			if (stateField) i[requestName] = trueVal
		}
		const setFieldsOptionsReq = (st: any, field: string, requestName: string) => {
			const stateField = state[st][field]
			if (Object.values(stateField).length !== 0)
				i[requestName] = stateField.value.toString()
		}
		const setFieldsMultiOptionsReq = (st: any, field: string, requestName: string) => {
			const stateField = state[st][field]
			if (stateField.length > 0)
				i[requestName] = stateField.map((x: any) => {
					if (st === 'filters_genes' && field === 'panel')
						return x.value.replaceAll(",", "_").replaceAll(" ", "+")
					return x.value
				})
		}
		const setFieldRangeReq = (st: any, field: string, requestName: string, requestNegName: string, arrayData: number[], isInvertedManage: boolean = false) => {
			let stateField = state[st][field]
			if (Array.isArray(stateField) && stateField.length > 1) {
				if (stateField[1] !== arrayData[arrayData.length - 1]) {
					if (isInvertedManage) i[requestNegName] = "on"
					i[requestName] = stateField[1]
				} if (stateField[0] !== arrayData[0]) {
					if (!isInvertedManage) i[requestNegName] = "on"
					i[requestName] = stateField[0]
				}
			}
		}
		const setFieldSliderReq = (st: any, field: string, requestName: string) => {
			const stateField = state[st][field];
			if (stateField !== 0) {
				i[requestName] = stateField
			}
		}
		const setFilterPhenotypesReq = () => {
			if (state.filters_phenotypes.phenotype.length > 0) {
				i["hpoId"] = state.filters_phenotypes.phenotype.map((x: any) => x.value).join(",")
			}
		}
		if (state.filters_samples.samples.length > 0) {
			const samplesChecked = state.filters_samples.samples
				.filter((x: any) => x.checked)
				.map((x: any) => { return { name: x.value.name, value: x.value.value } })
			samplesChecked.forEach((x: any) => {
				i[`${x.name}`] = `${x.value}`
			});
		}
		function filterStateRequestdictParserByState(state: any) {
			const stateName = Object.getOwnPropertyNames(state)[0];
			let variantsSeteds = getFilterStateSeted(state)
			state = state[stateName];
			return variantsSeteds.map((x: any) => {
				return state_request_dictParser.find((y: any) => (y.state === stateName && y.sField === x))
			})
		}
		const myStates: any = [
			{ filters_samples: state.filters_samples },
			{ filters_variants: state.filters_variants },
			{ filters_qc: state.filters_qc },
			{ filters_frequency: state.filters_frequency },
			{ filters_predictors: state.filters_predictors },
			{ filters_genes: state.filters_genes },
			{ filters_phenotypes: state.filters_phenotypes },
			{ filters_variantgroups: state.filters_variantgroups },
			{ filters_consequence: state.filters_consequence },
			{ filters_evidence: state.filters_evidence },
			{ filters_mitochondrial: state.filters_mitochondrial },
			{ filters_somatic: state.filters_somatic },
			{ filters_pharmgkb: state.filters_pharmgkb }
		];
		let stateRequestFiltered = [].concat(...myStates.map((x: any) => filterStateRequestdictParserByState(x)))
		stateRequestFiltered.forEach((x: any) => {
			if (!x) return
			switch (x.type) {
				case "checkbox": setFieldCheckboxReq(x.state, x.sField, x.request, x.data.requestTrueVal); break;
				case "field": setFieldReq(x.state, x.sField, x.request); break;
				case "multioptions": setFieldsMultiOptionsReq(x.state, x.sField, x.request); break;
				case "options": setFieldsOptionsReq(x.state, x.sField, x.request); break;
				case "phenotype": setFilterPhenotypesReq(); break;
				case "range": setFieldRangeReq(x.state, x.sField, x.request, x.data.requestNegName, x.data.arrayData, x.data?.isInverted); break;
				case "slider": setFieldSliderReq(x.state, x.sField, x.request); break;
			}
			if (x.request === 'ratiodepthgeno' && state.queryCombos.data.study.subtype === "SOMATIC") {
				i[x.request] = (state[x.state][x.sField] * 0.01).toFixed(4)
			}
		});
		return i
	}
	function parseFilters_RequestToState(urlData: string, getArrayParams: boolean = false): void | string[] | undefined {
		const urlSearchParams = new URLSearchParams(urlData);
		const params = Object.fromEntries(urlSearchParams.entries());
		const url_string = `https://a.com?${urlData}`;
		const url = new URL(url_string);
		const isSomatic = (state.queryCombos.data?.study?.subtype ?? "").toUpperCase().trim() === "SOMATIC"
		const arraysParams = state_request_dictParser
			.filter((x: state_request_dictI) => x.type === "multioptions")
			.map((x: state_request_dictI) => `${x.request}[]`)
		let paramsFilterFilled = Object.keys(params).filter((x: string) => (
			params[x].toString().trim() !== ""
			// && ( +params[x].toString().trim() !== 0 ||
			//     (+params[x].toString().trim() === 0 && x.toLowerCase() === "clnsig[]"))
		)).map((x: any) => {
			let result: any = {}
			if (arraysParams.indexOf(x) > -1) result[`${x.replace("[]", "")}`] = url.searchParams.getAll(x)
			else result[`${x}`] = params[x]
			return result
		}).filter((x: any) => {
			return state_request_dictParser.find((y: any) => (
				y.request === Object.keys(x)[0] ||
				(y.type === "range" && y.data.requestNegName === Object.keys(x)[0])
			))
		})
		if (getArrayParams) {
			return paramsFilterFilled
		}
		const genericSetState = (st: any, field: string, value: any) => {
			const newS = state[st]
			newS[field] = value
			// eslint-disable-next-line no-eval
			const setState = eval(`set${st}`)
			setState(newS)
		}
		const setFieldState = (st: any, field: string, value: any) => {
			genericSetState(st, field, value)
		}
		const setFieldCheckboxState = (st: any, field: string) => {
			genericSetState(st, field, true)
		}
		const setFieldsMultiOptionsState = (st: any, field: string, value: any) => {
			// eslint-disable-next-line no-eval
			const options = eval(`${st}Params.${field}`)
			let o = options.filter((x: any) => value.find((y: any) => {
				const opt = `${y}`.replaceAll("\n", "").replaceAll("\t", "").replaceAll("+", " ").toLowerCase().trim();
				const inp = `${x.value}`.replaceAll("\n", "").replaceAll("\t", "").replaceAll("+", " ").toLowerCase().trim();
				return opt === inp
			}))
			if (o.length > 0) {
				genericSetState(st, field, o)
			}
		}
		const setFieldsOptionsState = (st: any, field: string, value: any) => {
			// eslint-disable-next-line no-eval
			const options = eval(`${st}Params.${field}`)
			let o = options.find((x: any) => `${value}`.toLowerCase() === `${x.value}`.toLowerCase())
			if (o) genericSetState(st, field, o)
		}
		const setFieldsRangeState = (st: any, field: string, isNegativeParam: boolean, value: any, isInvertedManage: boolean = false) => {
			const newS = state[st]
			// eslint-disable-next-line no-eval
			const arrayData = eval(`${st}Params.${field}`)
			newS[field] = !isInvertedManage ?
				isNegativeParam ? [+value, arrayData[arrayData.length - 1]] : [arrayData[0], +value] :
				isNegativeParam ? [arrayData[0], +value] : [+value, arrayData[arrayData.length - 1]]
			// isNegativeParam ? [arrayData[arrayData.length - 1], +value] : [+value, arrayData[0]] 
			// if(isInvertedManage) newS[field] 
			// eslint-disable-next-line no-eval
			const setState = eval(`set${st}`)
			setState(newS)
		}
		const setPhenotypes = (value: string) => {
			const newS: any = state.filters_phenotypes;
			newS.phenotype = [];
			value.split(",").forEach(e => {
				newS.phenotype.push({ label: e.replaceAll("+", " "), value: e.replaceAll(" ", "+") })
			});
			setfilters_phenotypes(newS)
		}
		paramsFilterFilled.forEach((x: any) => {
			const dictParser = state_request_dictParser.find((y: state_request_dictI) => {
				if (isSomatic && Object.keys(x)[0].toLowerCase() === "ratiodepthgeno") {
					return (
						`${y.request}`.toLowerCase() === `${Object.keys(x)[0]}`.toLowerCase() &&
						`${y.state}`.toLowerCase() === "filters_somatic"
					)
				} else {
					if (Object.keys(x)[0].toLowerCase() !== "variants")
						return (`${y.request}`.toLowerCase() === `${Object.keys(x)[0]}`.toLowerCase())
					//El parametro de la request "variants", en casos multiples o familiares se setea en acordeon "samples", mientras que para simples en "variants"
					if ((state.queryCombos?.data?.study?.samples ?? []).length > 1) {
						return (`${y.request}`.toLowerCase() === `${Object.keys(x)[0]}`.toLowerCase())
					} else {
						return (
							`${y.request}`.toLowerCase() === `${Object.keys(x)[0]}`.toLowerCase() &&
							`${y.state}`.toLowerCase() === "filters_variants"
						)
					}
				}
			})
			let value = x[Object.keys(x)[0]]
			if (isSomatic && Object.keys(x)[0] === "ratiodepthgeno")
				value = (x[Object.keys(x)[0]] * 100).toFixed(2)
			if (dictParser) {
				switch (dictParser.type) {
					case "checkbox":
						setFieldCheckboxState(dictParser.state, dictParser.sField); break;
					case "field":
						setFieldState(dictParser.state, dictParser.sField, value); break;
					case "multioptions":
						setFieldsMultiOptionsState(dictParser.state, dictParser.sField, value); break;
					case "options":
						setFieldsOptionsState(dictParser.state, dictParser.sField, value); break;
					case "phenotype":
						setPhenotypes(value); break;
					case "range":
						const isNegativeParam = paramsFilterFilled.find((y: any) => Object.keys(y)[0] === dictParser.data.requestNegName) ? true : false;
						setFieldsRangeState(dictParser.state, dictParser.sField, isNegativeParam, value, dictParser.data?.isInverted); break;
					case "slider":
						setFieldState(dictParser.state, dictParser.sField, value); break;
				}
			}
		})
	}

	//---------------------------------------BADGES FILTERS---------------------------------------

	function getBadgesFiltersData(): { value: any, name?: string, label?: string, reference?: any }[] {
		let badgesValues: { value: any, name?: string, label?: string, reference?: any }[] = [];
		const setFields = (st: any, field: string, label: string) => {
			if (st[field].trim().length > 0)
				badgesValues.push({
					"name": field,
					"label": label,
					"value": st[field]
				})
		}
		const setFieldCheckbox = (st: any, field: string, reference: string, label?: string) => {
			let badgeData: any = {
				"name": field,
				"reference": reference,
				"value": true
			}
			if (label) badgeData = { ...badgeData, label }
			if (st[field]) badgesValues.push(badgeData)
		}
		const setFieldsOptions = (st: any, field: string, label: string) => {
			if (Object.keys(st).indexOf(field) > -1 &&
				Object.values(st[field]).length !== 0 &&
				(st[field].value ?? "").length !== 0)
				badgesValues.push({
					"name": field,
					"label": label,
					"value": `${st[field].label ?? ""}`
				})
		}
		const setFieldsMultiOptions = (st: any, field: string, label: string) => {
			if (st[field].length > 0)
				badgesValues.push({
					"name": field,
					"label": label,
					"value": st[field].map((x: any) => x.label).join(", ")
				})
		}
		const setFieldRange = (st: any, field: string, label: string, arrayData: number[]) => {
			if (Array.isArray(st[field]) && st[field].length > 1) {
				if (st[field][1] !== arrayData[arrayData.length - 1]) {
					badgesValues.push({
						"label": label,
						"value": st[field][1]
					})
				} if (st[field][0] !== arrayData[0]) {
					badgesValues.push({
						"name": `${field}_neg`,
						"reference": field,
						"value": true
					})
					badgesValues.push({
						"name": field,
						"label": label,
						"value": st[field][0]
					})
				}
			}
		}
		const setFieldSlider = (st: any, field: string, label: string) => {
			if (field.toLowerCase().indexOf("bayesdel") !== -1) {
				if (st[field] !== -1)
					badgesValues.push({
						"name": field,
						"label": label,
						"value": st[field]
					})
			} else if (st[field] !== 0) {
				badgesValues.push({
					"name": field,
					"label": label,
					"value": st[field]
				})
			}
		}
		const setFilterPhenotypes = () => {
			if (state.filters_phenotypes.phenotype.length > 0) {
				badgesValues.push({
					"name": "hpoId",
					"label": translate("common.phenotype"),
					"value": state.filters_phenotypes.phenotype.map((x: any) => x.label).join(", ")
				})
			}
		}
		const setSamples = () => {
			let label = getSamplesLabelValue().filter((x: any) => x.checked).map((x: any) => x.label).join(", ")
			if (label.length > 1) {
				badgesValues
					.push({
						"name": "studies",
						"label": translate("common.samples"),
						"value": label
					})
			}
		}
		//-------------------set samples fields--------------------------------
		setFieldsOptions(state.filters_samples, "diseaseModel", translate('casesQuery.diseaseModels'))
		setFieldsOptions(state.filters_samples, "operations", translate('casesQuery.operation'))
		//-------------------set variants fields--------------------------------
		setFields(state.filters_variants, "code", translate('casesQuery.variantCode'))
		setFieldsMultiOptions(state.filters_variants, "relevance", translate('casesQuery.relevanceFlag'))
		setFieldCheckbox(state.filters_variants, "excludeComments", "relevance")
		setFieldsOptions(state.filters_variants, "disease", translate('casesQuery.diseaseModels'))
		setFieldsOptions(state.filters_variants, "genotype", translate('casesQuery.genotype'))
		setFields(state.filters_variants, "cromosome", translate('casesQuery.chromosome'))
		setFieldsMultiOptions(state.filters_variants, "variantTypes", translate('casesQuery.vartype'))
		setFieldsOptions(state.filters_variants, "multipleAlleles", translate('casesQuery.multiplesAlleles'))
		//-------------------set QC fields--------------------------------------
		setFieldsMultiOptions(state.filters_qc, "filter", translate("common.filter"))
		setFieldRange(state.filters_qc, "depth", translate("casesQuery.depthcoverage"), rangeDataArray)
		setFieldSlider(state.filters_qc, "ratio", translate("casesQuery.ratioAdDp"))
		setFieldSlider(state.filters_qc, "genotypeQuality", translate("casesQuery.genotypeQuality"))
		setFieldSlider(state.filters_qc, "qual", "QUAL")
		//-------------------set Frequency fields-------------------------------
		setFieldRange(state.filters_frequency, "frecGnomAdExomes", translate("casesQuery.gnomAdExomesFrequency"), common_0to1_steps001_Array)
		setFieldRange(state.filters_frequency, "frecGnomAdGenomes", translate("casesQuery.gnomadGenomesFreq"), common_0to1_steps001_Array)
		//-------------------set Predictors-------------------------------------
		setFieldSlider(state.filters_predictors, "revel", "Revel Score")
		setFieldSlider(state.filters_predictors, "bayesdel", "BayesDel ADD")
		setFieldSlider(state.filters_predictors, "bayesdelnoaf", "BayesDel noAF")
		setFieldSlider(state.filters_predictors, "spliceai", "SpliceAI")
		setFieldSlider(state.filters_predictors, "mcap", "M-CAP")
		setFieldSlider(state.filters_predictors, "dann", "DANN")
		setFieldsMultiOptions(state.filters_predictors, "mutationTaster", "Mutation Taster")
		setFieldsMultiOptions(state.filters_predictors, "sift", "SIFT")
		setFieldsMultiOptions(state.filters_predictors, "polyphen", "Polyphen")
		//-------------------set Genes-------------------------------------
		setFields(state.filters_genes, "genName", translate("common.geneName"))
		setFieldsMultiOptions(state.filters_genes, "panel", translate("common.genesPanel"))
		setFieldCheckbox(state.filters_genes, "code", "panel")
		setFieldCheckbox(state.filters_genes, "omimgenes", "omimgenes", "OMIM Genes")
		setFieldSlider(state.filters_genes, "gnomadpli", "pLi")
		setFieldRange(state.filters_genes, "gnomadzmiss", "Z-Score", common_Less10to10_steps01_Array)
		//-------------------set Phenotypes-------------------------------------
		setFilterPhenotypes()
		//-------------------set Variant groups-------------------------------------
		setFieldsMultiOptions(state.filters_variantgroups, "groups", translate("casesQuery.savedGroups"))
		setFieldCheckbox(state.filters_variantgroups, "excludeGroups", "groups")
		//-------------------set Consequence-------------------------------------
		setFieldsMultiOptions(state.filters_consequence, "impact", translate("common.potentialImpact"))
		setFieldsMultiOptions(state.filters_consequence, "effect", translate("casesQuery.variantEffect"))
		setFieldCheckbox(state.filters_consequence, "excludeEffects", "effect")
		//-------------------set Evidence-------------------------------------
		setFieldsMultiOptions(state.filters_evidence, "veredict", translate("casesQuery.acmgVeredict"))
		setFieldCheckbox(state.filters_evidence, "excludeVeredict", "veredict")
		setFieldsMultiOptions(state.filters_evidence, "acmgBayClasification", translate("casesQuery.acmgBayesianClassif"))
		setFieldCheckbox(state.filters_evidence, "excludeAcmgBayClasification", "acmgBayClasification")
		setFieldSlider(state.filters_evidence, "acmgBayScore", translate("casesQuery.acmgBayesianScore"))
		setFieldsMultiOptions(state.filters_evidence, "clinvar", "CLINVAR")
		setFieldCheckbox(state.filters_evidence, "excludeClinvar", "clinvar")
		setFieldsOptions(state.filters_evidence, "dbsnp", "dbSNP")
		//-------------------set Mitocchondrial fields--------------------------------
		setFieldsOptions(state.filters_mitochondrial, "reference", translate('common.reference'))
		setFieldsMultiOptions(state.filters_mitochondrial, "scoreInterpretation", translate('casesQuery.scoreInterpretation'))
		setFieldSlider(state.filters_mitochondrial, "apogeescore", "APOGEE score")
		setFieldsMultiOptions(state.filters_mitochondrial, "diseaseStatus", translate('casesQuery.diseaseStatus'))
		setFieldSlider(state.filters_mitochondrial, "heteroplasmy", translate("casesQuery.heteroplasmy"))
		setFieldSlider(state.filters_mitochondrial, "afhomoplasmy", "GnomAD homoplasmy")
		//-------------------set Somatic fields--------------------------------
		setFieldCheckbox(state.filters_somatic, "oncokb", "oncokb", "Oncokb")
		setFieldCheckbox(state.filters_somatic, "cosmic", "cosmic", "Cosmic")
		setFieldCheckbox(state.filters_somatic, "hotspots", "hotspots", "Hotspots")
		setFieldCheckbox(state.filters_somatic, "civic", "civic", "Civic")
		setFieldCheckbox(state.filters_somatic, "pmkb", "pmkb", "PMKB")
		setFieldCheckbox(state.filters_somatic, "oncoevidence", "oncoevidence", translate("casesQuery.somaticEvidence"))
		setFieldsOptions(state.filters_somatic, "hotspotsorgantypes", translate("casesQuery.hotspotsorgantypes"))
		setFieldsOptions(state.filters_somatic, "pmkbtiers", "PMKB TIER")
		setFieldSlider(state.filters_somatic, "vaf", `VAF`)
		setFieldCheckbox(state.filters_somatic, "pon", "pon", "PON")
		setFieldCheckbox(state.filters_somatic, "germq", "germq", "GERMQ")
		//-------------------set PharmGkb fields--------------------------------
		setFieldsMultiOptions(state.filters_pharmgkb, "pharmGkb", "PharmGKB")
		setFieldsMultiOptions(state.filters_pharmgkb, "evidence", translate('common.evidence'))

		setSamples()
		return badgesValues
	}
	function cleanBadgesFiltersData(): void {
		dispatch({ type: QueryActionType.CLEAN_BADGES });
	}
	function setBadgesFiltersData(): void {
		dispatch({ type: QueryActionType.SET_BADGES, payload: getBadgesFiltersData() });
	}
	//---------------------------------------BADGES Warning---------------------------------------
	function cleanBadgesWarning(): void {
		dispatch({ type: QueryActionType.CLEAN_BADGES_WARNING });
	}
	function setBadgesWarning(): void {
		dispatch({ type: QueryActionType.SET_BADGES_WARNING, payload: getBadgeUnknownGenesNames(translate, state.queryVariants?.data?.validatedGenes) });
	}
	
	function cleanBadgesInfo(): void {
		dispatch({ type: QueryActionType.CLEAN_BADGES_INFO });
	}
	function setBadgesInfo(): void {
		dispatch({ type: QueryActionType.SET_BADGES_INFO, payload: getPreviousGenesNamesBadgesInfo(translate, state.queryVariants?.data?.validatedGenes) });
	}

	function setToolkitBadges(): void {
		setBadgesFiltersData();
		setBadgesWarning();
		setBadgesInfo();
	}
	//-----------------------------------------TABS-----------------------------------------------
	function getTabsId(): string {
		return `query_${props.studyId}_tabs`;
	}
	function getTabSelectedId(): string {
		return `query_${props.studyId}_tabSelected`;
	}
	function getActualTabs(): TabsI[] | undefined {
		const caseIdTab: string = getTabsId()
		if (Object.keys(state.tabs).indexOf(caseIdTab) !== -1)
			return state.tabs[caseIdTab]
		return undefined
	}
	function getActualTabSelected(): TabsI | undefined {
		const actualTabs = getActualTabs()
		const selected = getActualTabSelectedId()
		if (actualTabs &&
			actualTabs.length > 0) {
			const t = actualTabs.find((x: any) => `${x.id}` === `${selected}`)
			if (t) return t
		}
		return undefined
	}
	function getActualTabSelectedId(): number | undefined {
		const selected: string = getTabSelectedId()
		if (Object.keys(state.tabSelected).indexOf(selected) !== -1)
			return state.tabSelected[selected]
		return undefined
	}
	function getFiltersTabsFromSessionS(): { tabs: TabsI[], selected: TabsI } {
		let newTabs: any, selected: any;
		newTabs = encryptSessionStorage.getItem(getTabsId());
		if (newTabs && !encrypt) newTabs = JSON.parse(newTabs)
		if (!newTabs) newTabs = []
		selected = `${encryptSessionStorage.getItem(getTabSelectedId())}`;
		selected = selected ? selected.replaceAll(`"`, "") : undefined;
		selected = (newTabs === "undefined" ? [] : newTabs ?? []).find((x: any) => +x.id === +selected) // TODO VEEEEEEEEEEEEEEEEEER!!! no puede llegar "undefined", esto es un bug
		return {
			tabs: newTabs,
			selected: selected
		}
	}
	const setFiltersTabsAndSessionS = (newFilterTabs: any) => {
		const newTabId = getTabsId();
		dispatch({ type: QueryActionType.SET_TABS, payload: newFilterTabs })
		encryptSessionStorage.setItem(newTabId, JSON.stringify(newFilterTabs[newTabId]));
	}

	const setFiltersTabSelectedAndSessionS = (id: number) => {
		const newTabSelectedId = getTabSelectedId()
		const newTabSelected: any = { ...state.tabSelected }
		newTabSelected[newTabSelectedId] = id
		dispatch({ type: QueryActionType.SET_TAB_SELECTED, payload: newTabSelected })
		encryptSessionStorage.setItem(newTabSelectedId, JSON.stringify(newTabSelected[newTabSelectedId]))
		const removeThirdTabSettedOnSessionS = () => {
			const tabsOnSS = Object.keys(sessionStorage).filter((x: string) => x.indexOf("query_") > -1 && x.indexOf("_tabs") > -1)
			if (tabsOnSS.length > 2) {
				sessionStorage.removeItem(tabsOnSS[2])
				sessionStorage.removeItem(tabsOnSS[2].substring(0, 10).concat("tabSelected"))
			}
		}
		removeThirdTabSettedOnSessionS()
	}

	function CleanFiltersBadgesAndTable() {
		cleanfilters_all();
		cleanBadgesFiltersData()
		cleanQueryVariants()
	}
	function SetFiltersTabSelected(params: string): void {
		const tabId = getTabsId();
		const tabSelectedId = getTabSelectedId();
		let newTabs: any = { ...state.tabs }
		if (Object.keys(newTabs).indexOf(getTabsId()) === -1) {
			const ftss = getFiltersTabsFromSessionS()
			if (ftss.tabs.length > 0) {
				newTabs[getTabsId()] = ftss.tabs
			}
		}
		newTabs = Object.entries(newTabs).map((x: any[]) => {
			if (x[0] === tabId) {
				x[1] = x[1].map((y: TabsI) => {
					const ssTabId = getFiltersTabsFromSessionS()?.selected?.id
					if ((ssTabId && ssTabId === y.id) || y.id === state.tabSelected[tabSelectedId]) {
						y.urlData = params;
						y.data = undefined
					}; // veeeer tal vez tomo directo del session storage;
					return y
				})
			}
			return x;
		})
		setFiltersTabsAndSessionS(Object.fromEntries(newTabs))
	}
	function AddFiltersTabs(urlData: string, filterName: string, id: number, description?: string): void {
		CleanFiltersBadgesAndTable()
		const newTabId = getTabsId();
		const newTabs: any = { ...state.tabs }
		if (Object.keys(newTabs).indexOf(newTabId) === -1) newTabs[newTabId] = []
		newTabs[newTabId] = [...newTabs[newTabId], { urlData, filterName, id, description }]
		setFiltersTabsAndSessionS(newTabs)
	}
	function RemoveFiltersTabs(id: number, clean: boolean = false): void {
		if (clean) CleanFiltersBadgesAndTable()
		const newTabId = getTabsId();
		const newTabs: any = { ...state.tabs }
		newTabs[newTabId] = [...(newTabs[newTabId].filter((x: any) => x.id !== id) ?? [])]
		// console.log("lalal2")
		setFiltersTabsAndSessionS(newTabs)
	}

	//-----------------------------------------TABS-----------------------------------------------
	const SetOrderBySS = (value: any) => {
		const orderBysOnSS = Object.keys(sessionStorage).filter((x: string) => x.indexOf("_orderBy") > -1)
		if (orderBysOnSS.length > 2) sessionStorage.removeItem(orderBysOnSS[2])
		localStorage.setItem(`query_orderBy`, JSON.stringify(value))
	}

	function SetQueryColumnsOnLS(tableData?: (TableTitleI[] | undefined)): void {
		let tableDataTitlesValues: any = tableData?.filter((x: any) => x)
		if (tableDataTitlesValues && tableData && Array.isArray(tableData) && props.studyId) {
			localStorage.setItem(`query_columns`, JSON.stringify(tableDataTitlesValues))
		}
	}

	function setQueryVariantsFromTabs(tabId: number) {
		const actualTabs = getActualTabs()
		if (actualTabs) {
			const t = actualTabs.find((x: any) => `${x.id}` === `${tabId}`)
			if (t?.data) {
				dispatch({ type: QueryActionType.GET_VARIANTS_SUCCESS, payload: t.data });
			}
		}
	}

	function SetScrollYPosition(position: number | null): void {
		const pageHeight = getWolePageHeight()
		dispatch({ type: QueryActionType.SET_SCROLLYPOSITION, payload: { y: position, height: pageHeight } });
	}

	useEffect(() => {
		if (state.queryCombos.loaded && Object.keys(state.queryCombos.data).length > 0) {
			setfilters_samples_samples(getSamplesLabelValueInit())
			setfilters_samples({ ...state.filters_samples, samples: getSamplesLabelValue() })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.queryCombos.data])
	//------------------------- Filters ----------------------------------------------
	return (
		<QueryContext.Provider
			value={{
				// ----- States ----------------------------------------------------------
				//------------Async-----------------------------
				variant: state.variant,
				genesVariants: state.genesVariants,
				queryVariants: state.queryVariants,
				hpoPhenotypes: state.hpoPhenotypes,
				queryCombos: state.queryCombos,
				//------------Sync-----------------------------
				tabs: state.tabs,
				tabSelected: state.tabSelected,
				tabsMax: state.tabsMax,
				badgesFilters: state.badgesFilters,
				badgesWarning: state.badgesWarning,
				badgesInfo: state.badgesInfo,
				scrollYPosition: state.scrollYPosition,
				// ----- filter states
				filters_samples: state.filters_samples,
				filters_variants: state.filters_variants,
				filters_qc: state.filters_qc,
				filters_frequency: state.filters_frequency,
				filters_predictors: state.filters_predictors,
				filters_genes: state.filters_genes,
				filters_phenotypes: state.filters_phenotypes,
				filters_variantgroups: state.filters_variantgroups,
				filters_consequence: state.filters_consequence,
				filters_evidence: state.filters_evidence,
				filters_mitochondrial: state.filters_mitochondrial,
				filters_somatic: state.filters_somatic,
				filters_pharmgkb: state.filters_pharmgkb,
				// ----- Consts ----------------------------------------------------------
				studyId: props.studyId,
				state_request_dictParser: state_request_dictParser,
				filters_initialState: filtersInitialState,
				// ----- filter parameters
				filters_samplesParams: filters_samplesParams,
				filters_variantsParams: filters_variantsParams,
				filters_qcParams: filters_qcParams,
				filters_frequencyParams: filters_frequencyParams,
				filters_predictorsParams: filters_predictorsParams,
				filters_genesParams: filters_genesParams,
				filters_phenotypesParams: filters_phenotypesParams,
				filters_variantgroupsParams: filters_variantgroupsParams,
				filters_consequenceParams: filters_consequenceParams,
				filters_evidenceParams: filters_evidenceParams,
				filters_mitochondrialParams: filters_mitochondrialParams,
				filters_somaticParams: filters_somaticParams,
				filters_pharmgkbParams: filters_pharmgkbParams,

				dispatch: {
					//----------------Async----------------
					getVariant,
					getGenesVariants,
					getQueryVariants,
					getPhenotypes,
					getQueryCombos,
					//----------------Sync----------------
					cleanQueryVariants,
					refreshQueryVariants,
					refreshVariant,
					refreshGenesVariants,
					setBadgesFiltersData,
					cleanBadgesFiltersData,
					cleanBadgesWarning,
					setBadgesWarning,
					cleanBadgesInfo,
					setBadgesInfo,
					setToolkitBadges,

					getSamplesLabelValue,
					parseFilters_StateToRequest,
					parseFilters_RequestToState,
					setfilters_samples,
					cleanfilters_samples,
					setfilters_variants,
					cleanfilters_variants,
					setfilters_qc,
					cleanfilters_qc,
					setfilters_frequency,
					cleanfilters_frequency,
					setfilters_predictors,
					cleanfilters_predictors,
					setfilters_genes,
					cleanfilters_genes,
					setfilters_phenotypes,
					cleanfilters_phenotypes,
					setfilters_variantgroups,
					cleanfilters_variantgroups,
					setfilters_consequence,
					cleanfilters_consequence,
					setfilters_evidence,
					cleanfilters_evidence,
					setfilters_mitochondrial,
					cleanfilters_mitochondrial,
					setfilters_somatic,
					cleanfilters_somatic,
					setfilters_pharmgkb,
					cleanfilters_pharmgkb,
					cleanfilters_all,
					//----------------Tabs---------------------
					getTabsId,
					getTabSelectedId,
					getActualTabs,
					getActualTabSelected,
					getActualTabSelectedId,
					setFiltersTabsAndSessionS,
					setFiltersTabSelectedAndSessionS,
					getFiltersTabsFromSessionS,
					CleanFiltersBadgesAndTable,
					SetFiltersTabSelected,
					AddFiltersTabs,
					RemoveFiltersTabs,
					//----------------Tabs---------------------
					setQueryVariantsFromTabs,
					SetOrderBySS,
					SetQueryColumnsOnLS,
					SetScrollYPosition
				}
			}}
		>
			{props.children}
		</QueryContext.Provider>
	);
};

export default QueryContextState;
export type { state_request_dictI, TabsI }