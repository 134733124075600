import React from 'react';
import { Accordion, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import FormInput from "../../Common/Form/FormInput";
import { useEffect, useState, useContext, useRef } from "react";
import GensetServices from '../../../Services/GensetService';
import { LangContext } from '../../../Contexts/Lang';
import { formInputType } from '../../Common/Interfaces';
import { Formik } from "formik";
import * as yup from "yup";
import HpoGensetServices from "../../../Services/HpoGensetService";
import LinkTooltip from "../../Common/Links/LinkTooltip";
import ReactSelect from "../../Common/Form/ReactSelect";
import { LabelValueI } from "../../../utils/BioFunctionalData";

// type formInputType = "number" | "text" | "textarea" | "password" | "email"
interface ProcessPanelGeneI {
    history: any,
    show: boolean,
    handleClose: any,
    editGenePanel?: {
        id?: string,
        region?: string
    },
    handleReload?: any,
    addGenesView?: boolean
}
export default function ProcessPanelGene(props: ProcessPanelGeneI) {
    const { dispatch: { translate } } = useContext(LangContext);

    const GensetS = new GensetServices();

    const [noIntersectionError, setnoIntersectionError] = useState<boolean>(false)

    const hpoInit = { aux_search: "", selected: [], raw_data: [] }
    const [hpo, sethpo] = useState<{ aux_search: string, selected: LabelValueI[], raw_data: string[] }>(hpoInit);

    const HpoGensetQS = new HpoGensetServices();
    let formRef: any = useRef();

    useEffect(() => {
        let isMounted = true;
        if (!hpo.raw_data || hpo.raw_data.length === 0) {
            HpoGensetQS.read_hpogenset()
                .then((r: any) => {
                    if (r.status === 200) {
                        sethpo({
                            aux_search: hpo.aux_search,
                            selected: hpo.selected,
                            raw_data: r.data.symptom
                        })
                    }
                }).catch((e) => {
                    console.log("err")
                    console.log(e)
                });
        }
        if (props.editGenePanel && props.editGenePanel.id && props.editGenePanel.region) {
            GensetS.get_panel(props.editGenePanel.id, props.editGenePanel.region)
                .then((r: any) => {
                    if (isMounted && r.status === 200) {
                        const data = r.data.panel;
                        formRef?.current?.setValues({
                            name: data.name,
                            description: data.description,
                            genes: data.geneNames.join(", "),
                            autor: data.author,
                            referencia: data.references,
                        })
                    }
                }).catch((e) => {
                    console.log(e)
                });
        }
        formRef?.current?.setValues(initializeValues())
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleSearchGenesBySymptoms = (searchType: "interseccion" | "union"): void => {
        if (hpo.selected.length > 0)
            HpoGensetQS.read_candidateGenes((hpo.selected.map((x: any) => x.value) ?? []), searchType)
                .then((res: any) => {
                    if (res.status === 200) {
                        let genes = "";
                        const newVal = formRef.current.values;
                        if (res.data.selectGen && res.data.selectGen.length > 0) {
                            genes = res.data.selectGen.join(", ")
                        }
                        if (genes === "") setnoIntersectionError(true)
                        else setnoIntersectionError(false)
                        newVal.genes = genes;
                        formRef.current.setValues(newVal)
                    }
                })
                .catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
    }

    const getHpoOptions = (): { label: string, value: string }[] => {
        let phenotypesOptions: any[] = [];
        if (hpo.aux_search.length > 2) {
            phenotypesOptions = hpo.raw_data
                .filter((x: any) => x.toLowerCase().replaceAll("_", " ").indexOf(hpo.aux_search.trim().toLowerCase()) > -1)
                .map((x: any, i: number) => {
                    return {
                        label: x.replaceAll("_", " "),
                        value: x
                    }
                })
        }
        return phenotypesOptions
    }

    function initializeValues() {
        return {
            name: "",
            description: "",
            genes: "",
            autor: "",
            referencia: ""
        }
    }
    function createSchema() {
        const name = yup.string()
            .min(3, translate(`error.toShort`))
            .max(50, translate(`error.toLong`))
            .required(translate(`error.required`));
        const description = yup.string().required(translate(`error.required`));
        const genes = yup.string().required(translate(`error.required`));
        const autor = yup.string();
        const referencia = yup.string();
        return yup.object().shape({
            name,
            description,
            genes,
            autor,
            referencia
        })
    }
    function createForm(values: any, handleChange: any, errors: any) {
        function commonFormInput(type: formInputType, key: string, name: string, label: string, placeholder?: string, required: boolean = false) {
            return <FormInput type={type}
                labelBold={true}
                labelColor={"gray"}
                // borderRadius={true}
                label={label}
                key={key}
                formGrouplStyle={{ "marginBottom": "15px" }}       // onKeyDown={(e: any) => handleEnter(e)}
                placeHolder={placeholder}
                name={name}
                // disabled={props.mode === "read"}
                required={required}
                requiredFeedback={errors[name]}
                feedbackValidator={!!errors[name]}
                value={values[name]}
                onChange={handleChange} />
        }
        const nameInput = commonFormInput("text", "nameKey", "name", translate("common.name"), `...`, true);
        const descriptionInput = commonFormInput("textarea", "descKey", "description", translate("common.description"), `...`, true)
        const genesInput = commonFormInput("textarea", "genesKey", "genes", "Genes", `...`, true);
        const autorInput = commonFormInput("text", "autorKey", "autor", translate(`common.author`), `...`)
        const referenceInput = commonFormInput("text", "referenceKey", "referencia", translate(`common.references`), `...`)
        const a = hpo.selected.length === 0 ? "outline-secondary" : "secondary"
        return (
            <Card>
                <Card.Body>
                    {!props.addGenesView &&
                        <>
                            <Row>
                                <Col xs={12}>{nameInput}</Col>
                            </Row >
                            <Row>
                                <Col xs={12}>{descriptionInput}</Col>
                            </Row>
                            <hr style={{ margin: "0 -20px 10px" }} />
                        </>
                    }
                    <Row>
                        <Col xs={12}>
                            <Accordion style={{ margin: "10px 0" }} defaultActiveKey={props.addGenesView ? "0" : "1"}>
                                <Card style={{ overflow: "inherit" }}>
                                    <Accordion.Toggle as={Card.Header} eventKey="0"
                                        style={{ fontSize: "13px", color: "dimgray", fontWeight: "bold", padding: "5px 10px", backgroundColor: "#daf2fd" }}>
                                        {/* <FontAwesomeIcon icon={faDna} style={{ fontSize: "18px", marginRight: "2px", color: "gray" }} /> */}
                                        <FontAwesomeIcon icon={faSearch} style={{ fontSize: "14px", color: "gray" }} />
                                        &nbsp;&nbsp;
                                        {translate("genesPanel.searchGenesBySymptoms")}&nbsp;-&nbsp;HPO
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body style={{ padding: "5px 15px 15px" }}>
                                            <Row style={{ margin: 0 }}>
                                                <Col xs={12} style={{ padding: "2px" }}>
                                                    <span style={{ fontSize: "11px", color: "dimGray" }}>
                                                        {translate("genesPanel.hpohelp")}
                                                    </span>
                                                </Col>
                                                <Col xs={8} style={{ padding: "5px 2px" }}>
                                                    <ReactSelect options={getHpoOptions()}
                                                        height="29px"
                                                        isMulti={true}
                                                        value={hpo.selected ?? []}
                                                        placeholder={translate("common.enter3Characters")}
                                                        onInputChange={(e: any) => {
                                                            var newState = hpo;
                                                            newState.aux_search = e;
                                                            sethpo({ ...newState })
                                                        }}
                                                        onChange={(e: any) => {
                                                            var newState = hpo;
                                                            newState.selected = e;
                                                            sethpo({ ...newState })
                                                        }}
                                                    />
                                                    {noIntersectionError &&
                                                        <span style={{ fontSize: "12px", color: "#dc3545" }}>
                                                            {translate("genesPanel.noGeneWasFoundBetweenTheIntersection")}
                                                        </span>
                                                    }
                                                </Col>
                                                <Col xs={4} style={{ padding: "2px", textAlign: "end" }}>
                                                    <Button variant={a} style={{ margin: "1px 2px", fontSize: "11px" }}
                                                        disabled={hpo.selected.length === 0}
                                                        onClick={() => handleSearchGenesBySymptoms("interseccion")}>
                                                        {translate("genesPanel.hpoaddInter")}
                                                    </Button>
                                                    <Button variant={a} style={{ margin: "1px 2px", fontSize: "11px" }}
                                                        disabled={hpo.selected.length === 0}
                                                        onClick={() => handleSearchGenesBySymptoms("union")}>
                                                        {translate("genesPanel.hpoaddUnion")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            {genesInput}
                            <p style={{ fontSize: "11px", color: "dimGray" }}>
                                {translate("genesPanel.processGeneInputGeneNomenclature")}
                                <LinkTooltip tooltipLabel={`HGNC - genenames.org`}
                                    href={`http://www.genenames.org/`}
                                    label={<strong>{translate("genesPanel.hgncApprovedNomenclature")}</strong>} />
                            </p>
                        </Col>
                    </Row>
                    {!props.addGenesView &&
                        <>
                            <hr style={{ margin: "0 -20px 10px" }} />
                            <Row>
                                <Col xs={12}>{autorInput}</Col>
                            </Row >
                            <Row>
                                <Col xs={12}>{referenceInput}</Col>
                            </Row >
                        </>
                    }
                </Card.Body>
            </Card>
        )
    }
    function getTitle() {
        const iconStyles = { border: "none", marginRight: "7px", fontSize: "27px", verticalAlign: "text-bottom" }
        let title = <>
            <FontAwesomeIcon icon={faPlus} style={{ color: "#34AC4f", ...iconStyles }} />
            <strong>{translate(`genesPanel.createGenesPanel`)}</strong>
        </>
        if (props.editGenePanel && props.editGenePanel.id && props.editGenePanel.region)
            title = <>
                <FontAwesomeIcon icon={faEdit} style={{ color: "#007BFF", ...iconStyles }} />
                {props.addGenesView ?
                    <strong>{translate(`genesPanel.addGenes`)}</strong> :
                    <strong>{translate(`genesPanel.editGenesPanel`)}</strong>
                }
            </>
        return (
            <Modal.Title style={{ margin: "0px 10px", color: "#FFF" }}>
                {title}
            </Modal.Title>)
    }
    const title = getTitle()

    function handleSubmit(data: any) {
        function handleCreatePanel() {

            return GensetS.create_panel(data.name, data.description, data.genes, data.autor, data.referencia)
                .then((res: any) => {
                    if (res.status === 200) {
                        if (res.data.errors === "geneset.error.alreadyExists") {
                            formRef.current.setErrors(
                                { "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` })
                        } else {
                            if (props.handleReload) props.handleReload()
                            props.handleClose()
                        }
                    }
                }).catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
        }
        function handleEditPanel(id: string, region: string) {
            return GensetS.update_panel(id, region, data.name, data.description, data.genes, data.autor, data.referencia)
                .then((res: any) => {
                    if (res.status === 200) {
                        if (res.data.errors === "geneset.error.alreadyExists") {
                            formRef.current.setErrors(
                                { "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` })
                        } else {
                            if (props.handleReload) props.handleReload(res.data.key)
                            props.handleClose()
                        }
                    }
                }).catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
        }
        if (props.editGenePanel && props.editGenePanel.id && props.editGenePanel.region)
            handleEditPanel(props.editGenePanel.id, props.editGenePanel?.region)
        else handleCreatePanel()
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="my-modal" >
            <Formik initialValues={initializeValues()}
                validationSchema={createSchema()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                // handleSubmit={console.log}
                innerRef={formRef}
                validateOnChange={false}
                validateOnBlur={true}>
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: "#444B59" }}>
                                {title}
                            </Modal.Header>
                            <Modal.Body style={{ padding: "0" }}>
                                {createForm(values, handleChange, errors)}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={props.handleClose}>
                                    {translate(`common.close`)}
                                </Button>
                                <Button variant="primary" type="submit">
                                    {translate(`common.save`)}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    )
}
