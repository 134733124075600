import React from 'react';
import CasesTable from './CasesTable';
import { useEffect, useState, useContext } from 'react';
import { Button, Card } from 'react-bootstrap';
import Paginator from '../../Common/Paginator/Paginator';
import { LangContext } from '../../../Contexts/Lang';
import ProcessCase from '../ProcessCase/ProcessCase';
import HomeLayout from '../../Layout/Home/HomeLayout';
import HomeFilter, { InputFilterI } from '../../Layout/Home/HomeFilter';
import CasesContext, { StudyI } from '../../../Contexts/Cases/CasesContext';
import { faFolderOpen, faArchive, faTrash, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import HomeToolkit from '../../Layout/Home/HomeToolkit';
import StudyService from '../../../Services/StudyServices';
import Loader from '../../Common/Loader/Loader';
import { studyStateOptions, studyTypeOptions } from '../../../utils/BioFunctionalData';
import ModalStatistics from '../Detail/Statistics/ModalStatistics';
import { EnvironmentT } from 'src/utils/BulkLoader/BulkLoadBuilder';
import { Tooltip } from 'src/Components/Common/Tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BulkLoadModal from 'src/Components/Domains/BulkLoadModal/BulkLoadModal';
import UserContext from 'src/Contexts/User/UserContext';
import DomainsContext from '../../../Contexts/Domains/DomainsContext';
import ModalCNVs from '../CNV/ModalCNVs';

interface CasesBodyI {
    history: any
}
export default function CasesBody(props: CasesBodyI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { cases, selecteds, filters, dispatch: {
        getCases, refreshCases, setFilters, cleanFilters, handleSelecteds, handleSelectAll } } = useContext(CasesContext);
    const { domains, dispatch: { getDomains } } = useContext(DomainsContext);
    const { user } = useContext(UserContext);
    const isSystem = (user.data?.authorities ?? []).find((x) => (x?.authority.toUpperCase().trim() === "ROLE_SYSTEM"))

    const [processCase, setprocessCase] = useState<{ "open": boolean, "id": number | undefined, "domainId": string | undefined }>({ open: false, id: undefined, domainId: undefined })
    const handleCloseProcessCase = () => setprocessCase({ open: false, id: undefined, domainId: undefined });
    const handleShowProcessCase = (id?: number, domainId?: string) => { setprocessCase({ open: true, id: id ?? undefined, domainId: domainId ?? undefined }) };
    const [showStats, setshowStats] = useState<{ "open": boolean, study: StudyI | undefined, domainId: number | null }>({ open: false, study: undefined, domainId: null })
    const handleCloseStatistics = () => setshowStats({ open: false, study: undefined, domainId: null });
    const handleShowStatistics = (study?: StudyI, domainId?: number) => {
        setshowStats({ open: true, study: study ? study : undefined, domainId: domainId ? domainId : study?.domain.id })
    };
    const [showCnvs, setshowCnvs] = useState<{ "open": boolean, study: StudyI | undefined, domainId: number | null }>({ open: false, study: undefined, domainId: null })
    const handleCloseCnvs = () => setshowCnvs({ open: false, study: undefined, domainId: null });
    const handleShowCnvs = (study?: StudyI, domainId?: number) => {
        setshowCnvs({ open: true, study: study ? study : undefined, domainId: domainId ? domainId : study?.domain.id })
    };
    const [batchLoadModal, setbatchLoadModal] = useState<{ show: boolean }>({ show: false })
    const handleCloseBatchLoadModal = () => { setbatchLoadModal({ show: false }) }
    const handleShowBatchLoadModal = () => { setbatchLoadModal({ show: true }) }
    const handleOpenBulkLoadModal = () => setbatchLoadModal({ show: true })
    const preprodDomains = [175, 174, 173, 172, 171, 170, 169, 168, 167, 165, 164, 163, 162, 160, 153, 148, 147, 143, 141, 140, 138, 135, 130, 126, 122, 119, 116, 112]// some preprod domains
    const ENV: EnvironmentT = (cases.data?.result?.results ?? []).filter((x: any) => preprodDomains.includes(x.domainId)).length > 0 ? "PREPROD" : "PROD";

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => {
            handleGetCases(i + 1);
            paginator.setActualPage(i)
        },
        onItemsPerPageChange: (i: any) => {
            handleGetCases(1, i);
            paginator.pageSize_set(i)
        }
    })
    const StudyS = new StudyService()

    function handleGetCases(page?: number, pageSize?: number, cleanFilters?: boolean, then?: any): void {
        getCases(
            cleanFilters ? undefined : filters,
            page ?? paginator.actualPage + 1,
            pageSize ?? paginator.itemsPerPage)
            .then(() => {
                if (page !== undefined) paginator.setActualPage(page - 1)
                handleSelecteds("")
                if (then) then()
            })
    }
    useEffect(() => {
        let isMounted = true;
        if (isMounted && !cases.loaded && !cases.loading) handleGetCases()
        if (isMounted && cases.loaded) {
            paginator.pageSize_set(cases.data?.rowsCount ?? 0);
            paginator.setActualPage((cases.data?.cases?.pageNumber ?? 1) - 1);
        }
        if (isMounted && cases.refresh) handleGetCases(1, paginator.itemsPerPage, true)
        if (isMounted && isSystem) {
            getDomains(undefined, 1, 1000)
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cases])

    function handleSortByFilter(value: string) {
        if (value !== filters.sortColumn) {
            setFilters("sortColumn", value)
            handleGetCases(1);
        }
    }

    //----------------------------Cases Filters--------------------------------------
    const inputFilterData: InputFilterI[] = [
        {
            type: "text",
            stateName: "nameSearch",
            placeholder: translate('home.filter.studyNameCodeOrBatch'),
        },
        {
            type: "text",
            stateName: "descriptionSearch",
            placeholder: translate('home.filter.description'),
        },
        {
            type: "select",
            stateName: "studyType",
            options: studyTypeOptions(translate),
        },
        {
            type: "select",
            stateName: "studyState",
            options: studyStateOptions(translate),
            placeholder: translate("common.states")
        },
        {
            type: "date",
            stateName: "dateCreatedFromSearch",
            placeholder: translate('home.filter.createdFrom'),
        },
        {
            type: "date",
            stateName: "dateCreatedToSearch",
            placeholder: translate('home.filter.createdUntil'),
        }
    ]
    //----------------------------Cases Filters--------------------------------------
    const buttonsecondElementLeftToRight = !isSystem ? <></> :
        <div style={{ display: "flex", alignItems: "center", height: 32 }}>
            <Button onClick={() => handleOpenBulkLoadModal()}
                aria-controls="example-fade-text"
                variant='outline-primary'
                style={{
                    borderRadius: "50px",
                    padding: "0 8px",
                    border: "none",
                    margin: "2px 4px 1px",
                    height: "29px",
                    width: "29px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Tooltip label={"upload by batch"}>
                    <FontAwesomeIcon icon={faFileUpload}
                        style={{ border: "none", fontSize: "17px" }}
                        data-tip="Add case"
                    />
                </Tooltip>
            </Button>
        </div>
    return (
        <>
            {domains.loaded && <BulkLoadModal history={props.history}
                show={batchLoadModal.show}
                handleCloseBulkLoadModal={() => handleCloseBatchLoadModal()}
                handleOpenBulkLoadModal={() => handleShowBatchLoadModal()}
                refreshCases={() => refreshCases()}
                env={ENV}
                domains={domains.data?.results ?? []} />}
            <ModalStatistics showStats={showStats}
                handleCloseStatistics={handleCloseStatistics}
                history={props.history} />
            <ModalCNVs showCnvs={showCnvs}
                handleCloseCnvs={handleCloseCnvs}
                history={props.history} />
            <ProcessCase show={processCase.open}
                handleClose={handleCloseProcessCase}
                handleReload={() => { handleGetCases(1) }}
                caseId={processCase.id}
                domainId={processCase.domainId ?? undefined}
                studyTypeOptions={studyTypeOptions(translate)}
            />
            <HomeLayout history={props.history}
                filterTitle={translate('home.cases')}
                rowsAmount={{
                    loading: !cases.loaded || cases.loading,
                    amount: cases?.data?.rowsCount ?? 0,
                }}
                Filters={
                    <div style={{ width: "100%" }}>
                        <HomeFilter
                            filters={filters}
                            setFilter={setFilters}
                            handleCleanFilter={() => {
                                cleanFilters();
                                handleGetCases(1, paginator.itemsPerPage, true)
                            }}
                            callFilter={() => {
                                handleGetCases(1)
                            }}
                            inputFilters={inputFilterData}
                        />
                    </div>
                }
                FiltersEmpty={
                    <div style={{ width: "100%" }}>
                        <HomeFilter inputFilters={inputFilterData} />
                    </div>
                }
                Toolkit={
                    <HomeToolkit id="cases"
                        rowData={(cases.data?.result?.results ?? [])}
                        rowDataSelected={selecteds}
                        handleSelectAll={handleSelectAll}
                        multipleActionsOptions={[
                            {
                                icon: faArchive,
                                label: translate("common.archive"),
                                handleClick: () => {
                                    StudyS.archive(selecteds.map((x: string) => +x)).then(() => {
                                        // let actualPage = paginator.actualPage + 1;
                                        // if (actualPage !== 1 && selecteds.length === (cases.data?.result?.results ?? []).length) {
                                        //     actualPage = actualPage - 1
                                        // }
                                        handleGetCases(1)
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmArchiveAllSelectedStudy'),
                                    buttonLabel: translate('common.archive')
                                }
                            },
                            {
                                icon: faFolderOpen,
                                label: translate("common.activate"),
                                handleClick: () => {
                                    StudyS.active(selecteds.map((x: string) => +x)).then(() => {
                                        // handleSelecteds("")
                                        handleGetCases(1)
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmActiveAllSelectedStudy'),
                                    buttonLabel: translate('common.activate')
                                }
                            },
                            "divider",
                            {
                                icon: faTrash,
                                label: translate('home.delete'),
                                handleClick: () => {
                                    const dataGrouped = cases.data.result.results
                                        .filter((x: any) => selecteds.includes(`${x.id}`))
                                        .map((x: any) => {
                                            return { id: `${x.id}`, domainId: `${x.domainId}` }
                                        })
                                        .reduce((acc: any, { id, domainId }: any) => {
                                            acc[domainId] = acc[domainId] || [];
                                            acc[domainId].push(id);
                                            return acc;
                                        }, {} as Record<string, string[]>);
                                    const domainIdsSamples = Object.entries(dataGrouped)
                                        .map(([domainId, ids]: any) => `${domainId}:${ids.join(",")}`)
                                        .join(";");
                                    StudyS.deleteAll(domainIdsSamples).then(() => {
                                        handleSelecteds("")
                                        handleGetCases(1)
                                    })
                                },
                                modalWarning: {
                                    label: translate('home.tableManager.confirmDeleteAllSelectedStudy'),
                                    buttonLabel: translate('home.delete')
                                }
                            },
                        ]}
                        addElement={{
                            tooltipLabel: translate('home.tableManager.addCase'),
                            handleCreate: handleShowProcessCase,
                        }}
                        secondElementLeftToRight={buttonsecondElementLeftToRight}
                        sortBy={{
                            value: filters.sortColumn,
                            options: "default",
                            handleSortBy: handleSortByFilter
                        }}
                    />
                }
                Table={
                    <Card style={{ borderRadius: "0px" }}>
                        <Card.Body style={{ padding: "0px" }}>
                            {!cases.loaded || cases.loading ?
                                <Loader onContainer={true} type={'DNA'} /> :
                                (cases?.data?.result?.results && cases?.data?.result?.results.length !== 0) ?
                                    <CasesTable cases={cases?.data?.result?.results}
                                        casesSelecteds={selecteds}
                                        handleCasesSelecteds={handleSelecteds}
                                        handleShowProcessCase={handleShowProcessCase}
                                        handleShowStatistics={handleShowStatistics}
                                        handleShowCnvs={handleShowCnvs}
                                        handleReload={() => { handleGetCases(1) }}
                                        history={props.history} />
                                    :
                                    <div>
                                        <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                            {translate('home.noCasesFound')}
                                        </p>
                                    </div>
                            }
                        </Card.Body>
                        {(cases?.data?.result?.results ?? []).length > 0 ?
                            <Card.Footer>
                                {paginator.component}
                            </Card.Footer>
                            : <></>}
                    </Card>
                }
            />
        </>
    )
}
