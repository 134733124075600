import React, { useContext, useState } from 'react';
import { Button, Form } from "react-bootstrap";
import { LangContext } from 'src/Contexts/Lang';
import StudyService from 'src/Services/StudyServices';
import BulkLoadBuilder, { EnvironmentT } from 'src/utils/BulkLoader/BulkLoadBuilder';
import BulkLoadTableCsv from './BulkLoadTableCsv';
import { DomainI } from 'src/Contexts/interfaces';
import WarningCaseLoadFilesNotFound, {  convertDataToValidetionRequest, FilesNotFoundByDomainT, getTransformedDataRecords } from 'src/Components/Common/WarningCaseLoadFilesNotFound';
import { StudyBulk } from 'src/Components/Common/Interfaces';
import { AxiosResponse } from 'axios';

interface TabLoadFromProcesamientoI {
    handleLaunchRequest: () => void,
    env: EnvironmentT,
    domains: DomainI[]
}
export default function TabLoadFromProcesamiento(props: TabLoadFromProcesamientoI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const StudyS = new StudyService();
    const [textareaValue, setTextareaValue] = useState<string>('');
    const [transformedData, settransformedData] = useState<string>('');
    const [wrongEnvironmetData, setwrongEnvironmetData] = useState<string>('');

    const initWarningModal = { "show": false, filesNotFound: {} }
    const [warningModal, setwarningModal] = useState<{ show: boolean, filesNotFound: FilesNotFoundByDomainT }>(initWarningModal)

    const [error, seterror] = useState<string>('');
    const handleTextareaChange = (e: any) => {
        setTextareaValue(e.target.value);
    };
    const handleTransformTextAreaData = () => {
        try {
            if (error !== '') seterror('')
            if (wrongEnvironmetData !== '') setwrongEnvironmetData('')
            const result: { successData: string, wrongEnvironmetData: string } = (new BulkLoadBuilder(textareaValue, props.env, props.domains)).build()
            settransformedData(result.successData);
            setwrongEnvironmetData(result.wrongEnvironmetData);
        } catch (error: any) {
            seterror(String(error.message))
        }
    }
    const handleUploadTransformedData = () => {
        const blob = new Blob([transformedData], { type: 'text/csv;charset=utf-8;' });
        const file = new File([blob], 'data.csv', { type: 'text/csv' });
        const formData = new FormData();
        formData.append('file', file);
        const records: StudyBulk[] = getTransformedDataRecords(transformedData);
        const domainsIdAndSamples = convertDataToValidetionRequest(records)
        const handleClose = () => {
            props.handleLaunchRequest();
            setTextareaValue('');
            settransformedData('');
            setwrongEnvironmetData('');
        }
        return StudyS.upload_batch_cases(formData)
            .then((res: any) => {
                if (res.status === 200) {
                    return StudyS.validateAuxiliaryFiles(domainsIdAndSamples)
                        .then((res: AxiosResponse<any>) => {
                            if (Object.keys(res.data).length === 0) {
                                handleClose(); return;
                            }
                            const filesNotFoundByDomain = StudyS.validateAuxiliaryFilesAdapter(res, records)
                            if (filesNotFoundByDomain) {
                                setTimeout(() => {
                                    handleClose();
                                }, 12000);
                                setwarningModal({ show: true, filesNotFound: filesNotFoundByDomain })
                            } else {
                                handleClose();
                            }
                            // props.handleCloseBulkLoadModal();
                        }).catch((e: any) => {
                            console.log("e")
                            console.log(e)
                        })
                } else {
                    console.log("error...")
                }
            })
    }
    const thereIsTransformedData = (transformedData.split('\n').length - 2) > 0;

    return (
        <>
            {warningModal.show ?
                <div style={{ padding: "10px 0 0 0" }}>
                    <WarningCaseLoadFilesNotFound
                        filesNotFoundByDomain={warningModal.filesNotFound}
                        handleReload={() => { }}
                        handleClose={() => { }}
                    />
                </div> :

                <div style={{ padding: "10px 0 0 0" }}>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <div>
                                <Form.Label style={{ verticalAlign: "sub", marginBottom: 0, marginTop: 10 }}>
                                    Inserte hoja de procesamiento (Procesamiento - LOGISTICA INTEGRAL) para que sea transformada en tabla de carga
                                </Form.Label>
                                <Button style={{ float: "right", margin: 5 }}
                                    variant={textareaValue.length < 30 ? "outline-primary" : "primary"}
                                    disabled={textareaValue.length < 30}
                                    onClick={(e: any) => handleTransformTextAreaData()}>
                                    {translate("domain.adaptSheet")}
                                </Button>
                            </div>
                            <Form.Control as="textarea"
                                rows={6}
                                style={{ fontSize: "11px", marginTop: 20 }}
                                value={textareaValue}
                                onChange={handleTextareaChange}
                            />
                        </Form.Group>
                    </Form>
                    {error !== "" && <div>
                        <p style={{ color: "red" }}>{error}</p>
                    </div>
                    }
                    {wrongEnvironmetData !== "" && <div>
                        <label style={{ color: "orange", fontWeight: "bold" }}>{translate("common.warning").toUpperCase()}!</label>
                        <p style={{ color: "orange" }}>{translate("common.theNextSampleCodesAreNotInTheCorrectEnvironment")}:&nbsp;<strong>{wrongEnvironmetData}</strong></p>
                    </div>}
                    {
                        thereIsTransformedData && <div>
                            <BulkLoadTableCsv csvData={transformedData} />
                            <Button style={{ float: "right", margin: 5 }}
                                // variant={textareaValue.length < 30 ? "outline-primary" : "primary"}
                                // disabled={textareaValue.length < 30}
                                onClick={(e: any) => handleUploadTransformedData()}>
                                {translate("common.uploadBatch")}
                            </Button>
                        </div>
                    }
                </div>
            }
        </>
    )
}