import React from 'react';
import { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import QueryContext from '../../../Contexts/Query/QueryContext';


interface FiltersBadgesAppliedI {
    filtersBadgesData: {
        value: any,
        name?: string,
        label?: string,
        reference?: any
    }[]
}

export default function FiltersBadgesApplied(props: FiltersBadgesAppliedI) {
    const { state_request_dictParser } = useContext(QueryContext);
    let badgesArray: any = props.filtersBadgesData
    return badgesArray
        .filter((x: any) => x.label)
        .map((x: any, i: number) => {
            let srdp: any = state_request_dictParser.filter((xx: any) => xx.sField === x.name)
            if (srdp.length > 0) srdp = srdp[0].type
            else srdp = ""
            let notBelong: boolean = false
            if (badgesArray.find((y: any) => (y.reference && y.reference === x.name))) notBelong = true
            let label = `${x.label}`
            if (typeof x.value === "boolean") label += `: ${x.label ? "on" : "off"}`
            else
                if (!isNaN(x.value) && x.name !== "studies" && x.name !== "code") {
                    if (srdp === "slider")
                        label += ` ≥ ${x.value}`;
                    else
                        label += ` ${notBelong ? "≥" : "≤"} ${x.value}`;
                } else
                    label += ` ${notBelong ? "∉" : ""}: ${x.value}`
            if (label.length > 65) label = label.substring(0, 60).concat("...")
            return (
                <Badge variant={(isNaN(x.value) && notBelong) ? "light" : "secondary"}
                    key={i}
                    style={{
                        marginRight: "5px", fontSize: "10px",
                        backgroundColor: (isNaN(x.value) && notBelong) ? "#DDD" : "auto",
                        color: (isNaN(x.value) && notBelong) ? "#444" : "auto",
                        verticalAlign: "middle",
                        height: 18,
                        paddingTop: 5
                    }}>
                        {label}
                    
                </Badge >
            )
        })
}