import React from 'react';
import { faColumns, faBookmark, faComment, faSave, faFileExport, faSortAmountDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState, useEffect } from 'react';
import { Button, Row, Col, Dropdown, Badge, ButtonGroup, Table } from "react-bootstrap";
import { Tooltip } from "../../../../Common/Tooltip/Tooltip";
import { LangContext } from "../../../../../Contexts/Lang";
import ReactSelect from '../../../../Common/Form/ReactSelect';
import FiltersBadgesApplied from "../../FiltersBadgesApplied";
import ReportServices from "../../../../../Services/ReportService";
import ProcessReportedVariant from "../../../../ReportedVariants/ProcessReportedVariants/ProcessReportedVariant";
import DropdownToolkit from "../../../../Common/DropdownToolkit";
import { variantsOrderByOptions } from "../../../../../utils/BioFunctionalData";
import { ModalCommentGroupT, getConcatedComments, getStateComments, ModalCommentGroup } from "../../../../Common/ModalCommentGroup/ModalCommentGroup";
import { ModalWarning } from "../../../../Common/ModalWarning";
import VariantServices from "../../../../../Services/VariantServices";
import QueryContext, { TableTitleI } from "../../../../../Contexts/Query/QueryContext";
import ThemeContext from "../../../../../Contexts/Theme/ThemeContext";
import VariantsTableHeaders from "../../VariantsTable/VariantsTableHeaders";
import { VariantIdentificationTableRowI } from "../../../../Common/ModalCommentGroup/VariantIdentificationTableRow";
import { getSampleId_NameTagAffSex } from 'src/utils/BioHelpers';
import BadgesWarning from '../../BadgeUnknownGenesNames';
import BadgePreviousGenesNames from '../../BadgePreviousGenesNames';

interface QueryBodyTableToolkitI {
    history: any,
    optionsColumnSelect: any,
    handleColumnSelect: any,
    columnsSelecteds: any,
    paginatorComp: any,
    handleReloadDataTable: any,
    variantSelecteds: string[],
    variantsToSaveGroup: VariantIdentificationTableRowI[],
    handleGetQueryVariant(pageNumber?: number, pageSize?: number, then?: any): void,
    isMitochondrial: boolean,
    tableTitles: TableTitleI[],
    handleVariantSelecteds?: any,
    headersTableRef?: React.MutableRefObject<any>,
    handleScrollHeadersTable?(scroll: any): void,
    queryVariantsLoaded?: boolean,
    setScrollYPosition?(position: number | null): void,
}

export default function QueryBodyTableToolkit(props: QueryBodyTableToolkitI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const { queryVariants, queryCombos, badgesFilters, badgesWarning, badgesInfo, dispatch: { SetOrderBySS } } = useContext(QueryContext);
    const [showDropdownCol, setshowDropdownCol] = useState(false);
    const [columnsFilterAplied, setcolumnsFilterAplied] = useState(false);
    const isMultiple = queryCombos.data?.study?.type.toLowerCase().trim() === "multiple";
    const filtersBadgesData = badgesFilters
    useEffect(() => {

    }, [badgesFilters, badgesWarning, badgesInfo])


    const [showReportVariant, setshowReportVariant] = useState<{
        show: boolean, data?: {
            studyId: string,
            variants: string,
            samplenames: string
        }
    }>({ show: false, data: undefined })
    const handleCloseReportVariant = () => { setshowReportVariant({ show: false, data: undefined }) }
    const handleShowReportVariant = (studyId: string, variants: string, samplenames: string) => {
        setshowReportVariant({
            show: true,
            data: {
                studyId,
                variants,
                samplenames
            }
        })
    }

    const VariantS = new VariantServices();
    const [removeCommentModal, setremoveCommentModal] = useState<{ show: boolean, variants: string[] }>({ show: false, variants: [] });
    const handleCloseRemoveCommentModal = () => { setremoveCommentModal({ show: false, variants: [] }) }
    const handleShowRemoveCommentModal = (variants: string[]) => { setremoveCommentModal({ show: true, variants: variants }) }
    const handleUncomentVariant = () => {
        VariantS.uncomment_variants(queryCombos.data?.study.id, props.variantSelecteds)
            .then((res: any) => {
                if (res.status === 200) {
                    handleCloseRemoveCommentModal();
                    props.handleReloadDataTable();
                    props.handleGetQueryVariant();
                }
            }).catch((e: any) => {
                console.log("e")
                console.log(e)
            })
    }


    const initFormikModal = { show: false, type: "comment" as ModalCommentGroupT, data: undefined, editData: undefined }
    const [commentGroupModal, setcommentGroupModal] = useState<{ show: boolean, type: ModalCommentGroupT, data: any, editData: any }>(initFormikModal);
    const handleCloseCommentGroupModal = () => { setcommentGroupModal(initFormikModal) }
    const handleShowCommentGroupModal = (type: ModalCommentGroupT, data: any, editData?: any) => {
        setcommentGroupModal({ show: true, type: type, data: data, editData: editData })
    }

    let orderByOpt = variantsOrderByOptions(translate)
    const initOrderByOptions = () => {
        if (props.isMitochondrial) {
            orderByOpt = orderByOpt.filter(
                (x: any) => x.value !== "infoColumnData.INTERVAR_VEREDICT" &&
                    x.value !== "infoColumnData.GNOMAD_GENOME_ALL" &&
                    x.value !== "infoColumnData.GNOMAD_EXOME_ALL")
        }
        let localOrderBy = localStorage.getItem(`query_orderBy`);
        if (localOrderBy) localOrderBy = JSON.parse(localOrderBy)
        let initOrderBy: any = orderByOpt;
        if (!localOrderBy || !Array.isArray(localOrderBy)) {
            SetOrderBySS([!props.isMitochondrial ? orderByOpt[0].value : ""])
        } else {
            initOrderBy = (localOrderBy ?? []).map((x: any) => orderByOpt.find((y: any) => y.value === x))
        }
        return initOrderBy
    }
    const [orderBy, setorderBy] = useState(initOrderByOptions())
    const handleOrderBy = (value: any) => {
        if (value && value.length > 3) {
            const newOrder = [...value.filter((x: any, i: number) => i !== 0)]
            setorderBy(newOrder)
            SetOrderBySS(newOrder.map((x: any) => x.value))
        }
        else {
            setorderBy(value)
            SetOrderBySS(value.map((x: any) => x.value))
        }
    }
    useEffect(() => {
        setorderBy(initOrderByOptions())
        let ssOrderBy: any = localStorage.getItem(`query_orderBy`)
        if (ssOrderBy && Array.isArray(JSON.parse(ssOrderBy))) {
            ssOrderBy = JSON.parse(ssOrderBy)
            const filtered = ssOrderBy.filter((x: any) => orderByOpt.map((xx: any) => x.value).indexOf(x) > -1)
            if (filtered.length !== 0) {
                setorderBy(filtered)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryVariants, queryCombos, props.isMitochondrial])

    function getExportDropdownItems() {
        const ReportS = new ReportServices();
        let actualQuery = props.history.location?.pathname.split("/")
        actualQuery = actualQuery[actualQuery.length - 1]
        const orderByVal = orderBy.length > 0 ? orderBy[0]?.value : undefined;
        let joinedSamples = (queryCombos.data?.study?.samples ?? [])
            .map((x: any) =>
                `sample_${x.id}=${getSampleId_NameTagAffSex(x.sample.name, x.tag, x.afectado, x.sample.sexo)}`
            ).join("&")

        let exportDropdownItems: any = [
            {
                label: `${translate("common.allVariantsInResult")} (xls)`,
                href: ReportS.get_downloadResultXls_link((isMultiple ? joinedSamples : "") + actualQuery, orderByVal)
            },
            {
                label: `${translate("common.selectedVariants")} (xls)`,
                href: ReportS.get_downloadSelectedXls_link(`${actualQuery}${isMultiple ? ("&" + joinedSamples) : ""}`, props.variantSelecteds.join(), orderByVal),
                disabled: props.variantSelecteds.length === 0
            },
            {
                label: `${translate("casesQuery.allGenesInResult")} (csv)`,
                href: ReportS.get_downloadGenesToCsv_link((isMultiple ? joinedSamples : "") + actualQuery, orderByVal)
            }
        ]
        if (!isMultiple)
            exportDropdownItems = [
                ...exportDropdownItems,
                {
                    label: `${translate("common.allVariantsInResult")} (vcf)`,
                    href: ReportS.get_downloadResultVariantsToVcf_link(actualQuery, orderByVal)
                },
                {
                    label: `${translate("common.selectedVariants")} (vcf)`,
                    href: ReportS.get_downloadSelectedVariantsToVcf_link(queryCombos.data?.study.id, props.variantSelecteds.join()),
                    disabled: props.variantSelecteds.length === 0
                }
            ]
        return exportDropdownItems;
    }
    const exportDropdownItems = getExportDropdownItems()
    const getOrderByWidth = () => {
        let width = "220px";
        try {

            width = `${100 +
                orderBy.length * 39 +
                (orderBy.length === 0 ? 0 :
                    orderByOpt
                        .filter((x: any) => orderBy.find((y: any) => (x?.label ?? "    ") === y.label))
                        .map((x: any) => (x?.label ?? "     ").length * 6)
                        .reduce((accum: number, curr: number) => accum + curr)
                )
                }px`
        } catch (e: any) { }
        return width
    }
    const orderByWidth = getOrderByWidth()
    return (<>
        {removeCommentModal.show &&
            <ModalWarning labelTitle={translate('common.confirm')}
                label={translate('casesQuery.doYouWantToRemoveCommentsOnTheSelectedVariants')}
                labelButton={translate('common.delete')}
                showState={removeCommentModal.show}
                handleClose={handleCloseRemoveCommentModal}
                onClick={handleUncomentVariant}
            />}
        {/* {commentGroupModal.show && */}
        <ModalCommentGroup show={commentGroupModal.show}
            handleClose={handleCloseCommentGroupModal}
            type={commentGroupModal.type}
            data={commentGroupModal.data}
            editData={commentGroupModal.editData}
            studyId={queryCombos.data?.study.id}
            handleReload={() => {
                if (props.setScrollYPosition) props.setScrollYPosition(window.pageYOffset);
                if (props.handleGetQueryVariant) props.handleGetQueryVariant()

            }}
        />
        {/* } */}
        {showReportVariant?.data &&
            <ProcessReportedVariant history={props.history}
                addVariant={{
                    studyId: showReportVariant.data.studyId,
                    variants: showReportVariant.data.variants,
                    samplenames: showReportVariant.data.samplenames
                }}
                show={showReportVariant.show}
                handleClose={handleCloseReportVariant}
                handleReload={props.handleReloadDataTable}
            />}
        <div style={{ "backgroundColor": theme.backgroundSecondary, padding: "10px 7px 7px", borderBottom: "solid 1px lightgray" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ alignSelf: "center" }}>
                    <Badge variant={"secondary"}
                        key={"totalVariantsCount"}
                        style={{ margin: "0px 5px 0 0", fontSize: "13px", borderRadius: "50px" }}>
                        {translate("common.variants")} {queryVariants.data?.totalVariantsCount ?? 0}
                    </Badge >
                    <Badge variant={"secondary"}
                        key={"totalGenesCount"}
                        style={{ margin: "0 5px 0 0", fontSize: "13px", borderRadius: "50px" }}>
                        genes {queryVariants.data?.totalGenesCount ?? 0}
                    </Badge >
                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "15px", marginRight: "5px", color: "dimgray" }} />
                    <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                        {translate("casesQuery.filtersApplied")} :
                    </span>
                    {
                        filtersBadgesData && filtersBadgesData.length > 0 && <FiltersBadgesApplied filtersBadgesData={filtersBadgesData} />
                    }
                    &nbsp;
                    {
                        badgesWarning && badgesWarning.length > 0 && <BadgesWarning badgesData={badgesWarning} />
                    }
                    &nbsp;
                    {
                        badgesInfo && badgesInfo.length > 0 && <BadgePreviousGenesNames badgesData={badgesInfo} />
                    }
                </div>
                <div style={{ margin: "0", right: 0 }}>
                    {props.paginatorComp}
                </div>
            </div>
            <hr style={{ margin: "5px 0" }} />

            <Row style={{ margin: "0px", padding: "0px", backgroundColor: theme.backgroundSecondary }}>
                <Col style={{ padding: 0, marginTop: "2px" }}>
                    <ButtonGroup style={{ backgroundColor: theme.backgroundPrimary, height: "32px" }}>
                        <DropdownToolkit icon={faComment}
                            tooltip={translate("casesQuery.commentVariants")}
                            items={[
                                {
                                    label: translate("common.comment"),
                                    onClick: () => {
                                        let commenIds: any = (Object.keys(queryVariants.data?.study?.comments) ?? [])
                                            .filter((x: any) => props.variantSelecteds.filter((xx: any) => xx.indexOf(x) > -1).length > 0)
                                        handleShowCommentGroupModal("comment",
                                            {
                                                variantsId: props.variantSelecteds,
                                                variants: props.variantsToSaveGroup
                                            },
                                            {
                                                comment: queryVariants.data?.study?.comments && commenIds.length > 0 ?
                                                    getConcatedComments(queryVariants.data?.study?.comments, commenIds) ?? undefined : undefined,
                                                state: queryVariants.data?.study?.comments && commenIds.length > 0 ?
                                                    getStateComments(queryVariants.data?.study?.comments, commenIds) ?? undefined : undefined
                                            }
                                        )
                                    }
                                },
                                {
                                    label: translate("casesQuery.removeComment"), onClick: () => {
                                        handleShowRemoveCommentModal(props.variantSelecteds)
                                    }
                                }
                            ]}
                            disabled={props.variantSelecteds.length === 0}
                        />
                        <DropdownToolkit icon={faSave}
                            tooltip={translate("casesQuery.saveResult")}
                            items={[
                                {
                                    label: translate("casesQuery.createGroup"), onClick: () => {
                                        handleShowCommentGroupModal("create_group", {
                                            variants: props.variantsToSaveGroup
                                        })

                                    }
                                },
                                {
                                    label: translate("casesQuery.addToGroup"), onClick: () => {
                                        handleShowCommentGroupModal("add_to_group", {
                                            variants: props.variantsToSaveGroup,
                                            groups: queryVariants.data?.groups ?? []
                                        })
                                    },
                                    disabled: queryCombos.data?.study?.comments && Object.keys(queryCombos.data?.study?.comments).length > 0 ? false : true
                                }
                            ]}
                            disabled={props.variantSelecteds.length === 0}
                        />
                        <DropdownToolkit icon={faFileExport}
                            tooltip={translate("common.export")}
                            items={exportDropdownItems} />
                        <DropdownToolkit icon={faBookmark}
                            tooltip={translate("casesQuery.reportVariant")}
                            items={[{
                                label: translate("casesQuery.reportVariant"),
                                onClick: () => {
                                    handleShowReportVariant(`${queryCombos.data?.study.id}`, props.variantSelecteds.join(","), queryCombos.data?.study.samplesNames.join(", "))
                                }
                            }]}
                            disabled={props.variantSelecteds.length === 0}
                        />

                    </ButtonGroup>
                </Col>
                <div style={{ display: "flex", marginTop: "2px" }}>
                    <ReactSelect options={orderByOpt}
                        onChange={(e: any) => handleOrderBy(e)}
                        value={orderBy}
                        isMulti={true}
                        width={orderByWidth}
                    />
                </div>
                <div style={{ display: "flex", backgroundColor: theme.backgroundPrimary, marginTop: "2px" }}>
                    <Tooltip label={translate("common.sortBy")}>
                        <Button variant="outline-secondary"
                            style={{ height: "32px", padding: "0 8px", borderColor: "lightgray", marginLeft: "-1px" }}
                            onClick={() => { props.handleGetQueryVariant(1) }}>
                            <FontAwesomeIcon icon={faSortAmountDown} style={{ fontSize: "17px" }} />
                        </Button>
                    </Tooltip>
                    <Tooltip label={translate("casesQuery.selectColumnsToDisplay") + (columnsFilterAplied ? (" - " + translate("common.filterApplied").toUpperCase()) : "")}>
                        <Dropdown alignRight={true} show={showDropdownCol}>
                            <Dropdown.Toggle variant={columnsFilterAplied ? "warning" : "outline-primary"} id="dropdown-basic"
                                onClick={() => setshowDropdownCol(!showDropdownCol)}
                                style={{ padding: "6px 3px", borderColor: "lightgray" }}>
                                <FontAwesomeIcon icon={faColumns}
                                    style={{ margin: "0 2px 0 2px", fontSize: "15px" }}
                                    data-tip="Add case"
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ padding: 0 }}>
                                <div key={0} style={{ borderRadius: "0" }}>
                                    <ReactSelect options={props.optionsColumnSelect}
                                        onChange={(e: any) => {
                                            // console.log(e)
                                            if (e.filter((x: any) => x.value === "variant").length === 0)
                                                e.push(props.optionsColumnSelect.filter((x: any) => x.value === "variant")[0])
                                            if (e.length !== props.optionsColumnSelect.length)
                                                setcolumnsFilterAplied(true)
                                            else
                                                setcolumnsFilterAplied(false)
                                            props.handleColumnSelect(e)
                                        }}
                                        value={props.columnsSelecteds}
                                        isMulti={true}
                                        width={`350px`}
                                    />
                                </div>
                            </Dropdown.Menu >
                        </Dropdown>
                    </Tooltip>
                </div>
            </Row>
            {props.queryVariantsLoaded &&
                <Row id="queryBodyToolkitRow"
                    style={{ margin: "5px -7px -7px -7px", padding: "0px", backgroundColor: theme.backgroundSecondary, overflowX: "auto" }}
                    ref={props.headersTableRef}
                    onScrollCapture={(e: any) => { if (props.handleScrollHeadersTable) props.handleScrollHeadersTable(e); }}>
                    <Table style={{ margin: 0 }} id={"queryBodyToolkitRow_QueryVariantsHeaders"}>
                        <VariantsTableHeaders queryVariants={queryVariants}
                            variantSelecteds={props.variantSelecteds}
                            tableTitles={props.tableTitles}
                            isMitochondrial={props.isMitochondrial}
                            handleGetQueryVariant={props.handleGetQueryVariant}
                            handleVariantSelecteds={props.handleVariantSelecteds}
                        />
                    </Table>
                </Row>
            }
        </div>
    </>
    )
}
