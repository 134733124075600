import { RollesT } from "../Contexts/interfaces";
import LibraryPrepKits from '../utils/LibraryPrepKit.json';
import OncoTreeDict from '../utils/OncoTreeDict.json';

//-------------------------------------Data to employ on components as key value pair---------------------------------
interface LabelValueI { label: string | JSX.Element, value: string | null }

function getLibraryPrepKitLabelVal(): LabelValueI[] {
	const librariesValLabel = Object.entries(LibraryPrepKits).map((x: string[]): LabelValueI => {
		return { value: x[0].toUpperCase(), label: x[1] }
	})
	return librariesValLabel;
}

class OncotTreeDict {
	public static getOncoTreeDictLabelVal(key?: string, label?: string): LabelValueI[] {
		const oncotreeValLabel = Object.entries(OncoTreeDict).map((x: string[]): LabelValueI => {
			return { value: x[0].toUpperCase(), label: x[1] }
		})
		return oncotreeValLabel;
	}
	public static getOncoTreeDictValue(label: string): string {
		const value = OncotTreeDict.getOncoTreeDictLabelVal().filter((x: LabelValueI) => {
			return (x.label === label.trim().toUpperCase())
		});
		if (value.length > 0 && value[0].value)
			return value[0].value;
		return ""
	}
	public static getOncoTreeDictLabel(value: string): string {
		const label = OncotTreeDict.getOncoTreeDictLabelVal().filter((x: LabelValueI) => {
			return (x.value === value.trim().toUpperCase())
		});
		if (label.length > 0 && label[0].label)
			return `${label[0].label}`;
		return ""
	}
}

function getValueByKeyInLibraryPrepKit(key: string): string | null {
	const isThere: LabelValueI[] = getLibraryPrepKitLabelVal().filter((x: LabelValueI) => {
		return x.value === key.trim().toUpperCase();
	})
	return isThere.length > 0 ? isThere[0]?.value ?? null : null;
}

// const getKeyLibraryPrepKit = (): string | null => {
//     const librariesValLabel = Object.entries(LibraryPrepKits).map((x: string[]): LabelValueI => {
//         return { value: x[0].toUpperCase(), label: x[1] }
//     })
//     return librariesValLabel;
// }

const consequenceEffectValues: LabelValueI[] = [
	{ label: "Frame shift", value: "frameshift_variant" },
	{ label: "Stop gained", value: "stop_gained" },
	{ label: "Stop lost", value: "stop_lost" },
	{ label: "Start lost", value: "start_lost" },
	{ label: "Splice Acceptor", value: "splice_acceptor_variant" },
	{ label: "Splice Donor", value: "splice_donor_variant" },
	{ label: "Missense", value: "missense_variant" },
	{ label: "Intron", value: "intron_variant" },
	{ label: "Inframe Insertion", value: "inframe_insertion" },
	{ label: "Structural interaction", value: "structural_interaction_variant" },
	{ label: "Protein protein contact", value: "protein_protein_contact" },
	{ label: "Disruptive Inframe Insertion", value: "disruptive_inframe_insertion" },
	{ label: "Inframe Deletion", value: "inframe_deletion" },
	{ label: "Disruptive Inframe Deletion", value: "disruptive_inframe_deletion" },
	{ label: "Synonymus", value: "synonymous_variant" },
]

const consequenceImpactValues: LabelValueI[] = [
	{ label: "High", value: "HIGH" },
	{ label: "Moderate", value: "MODERATE" },
	{ label: "Modifier", value: "MODIFIER" },
	{ label: "Low", value: "LOW" }
];

const evidenceClinvarValues: LabelValueI[] = [
	{ label: "0 - Uncertain significance", value: "0" },
	{ label: "1 - not provided", value: "1" },
	{ label: "2 - Benign", value: "2" },
	{ label: "3 - Likely benign", value: "3" },
	{ label: "4 - Likely pathogenic", value: "4" },
	{ label: "5 - Pathogenic", value: "5" },
	{ label: "6 - drug response", value: "6" },
	{ label: "7 - histocompatibility", value: "7" },
	{ label: "8 - Confers sensitivity", value: "8" },
	{ label: "9 - Risk factor", value: "9" },
	{ label: "10 - Association", value: "10" },
	{ label: "11 - Protective", value: "11" },
	{ label: "12 - Affects", value: "12" },
	{ label: "13 - Conflicting interpretations of pathogenicity", value: "13" },
	{ label: "255 - other", value: "255" },
]

const mitochondrialScoreInterpretationValues: LabelValueI[] = [
	{ label: "Likely benign", value: "likely_benign" },
	{ label: "Possibly benign", value: "possibly_benign" },
	{ label: "Possibly pathogenic", value: "possibly_pathogenic" },
	{ label: "Likely pathogenic", value: "likely_pathogenic" }
]

const mitochondrialDiseaseStatusValues = (translate: Function): LabelValueI[] => [
	{ label: translate("casesQuery.diseaseStatusOptions.confirmed"), value: "Cfrm" },
	{ label: translate("casesQuery.diseaseStatusOptions.reported"), value: "Reported" },
	{ label: translate("casesQuery.diseaseStatusOptions.pointMutationPolymorphism"), value: "P.M.-possibly-synergistic" },
	{ label: translate("casesQuery.diseaseStatusOptions.unclear"), value: "Unclear" },
	{ label: translate("casesQuery.diseaseStatusOptions.possiblySynergistic"), value: "Possibly-synergistic" }
]

const mutationTasterValues: LabelValueI[] = [
	{ label: "Disease causing automatic", value: "A" },
	{ label: "Disease causing", value: "D" },
	{ label: "Polymorphism", value: "N" },
	{ label: "Polymorphism automatic", value: "P" },
];

const polyphenValues: LabelValueI[] = [
	{ label: "Probably Damaging", value: "D" },
	{ label: "Possibly Damaging", value: "P" },
	{ label: "Benign", value: "B" }
];

const siftValues: LabelValueI[] = [
	{ label: 'Tolerated', value: "T" },
	{ label: 'Damaging', value: "D" }
];

const variantAcmgValues: LabelValueI[] = [
	{ label: "Benign", value: "Benign" },
	{ label: "Likely Benign", value: "Likely_benign" },
	{ label: "Uncertain Significance", value: "Uncertain_significance" },
	{ label: "Likely Pathogenic", value: "Likely_pathogenic" },
	{ label: "Pathogenic", value: "Pathogenic" }
]

type CommentStateT = "LIKELY_RELEVANT" | "LIKELY_IRRELEVANT" | "RELEVANT" | "IRRELEVANT"
const commentStatesColors: any = {
	"LIKELY_RELEVANT": "#FFB111",
	"LIKELY_IRRELEVANT": "#C19660",
	"RELEVANT": "#E10000",
	"IRRELEVANT": "gray"
}

const variantRelevanceValues = (translate: any): LabelValueI[] => [
	{ label: translate('common.relevanceOptions.RELEVANT'), value: "RELEVANT" },
	{ label: translate('common.relevanceOptions.LIKELY_RELEVANT'), value: "LIKELY_RELEVANT" },
	{ label: translate('common.relevanceOptions.LIKELY_IRRELEVANT'), value: "LIKELY_IRRELEVANT" },
	{ label: translate('common.relevanceOptions.IRRELEVANT'), value: "IRRELEVANT" }
];

const variantTypesValues: LabelValueI[] = [
	{ label: "Snp", value: "SNP" },
	{ label: "Mnp", value: "MNP" },
	{ label: "Ins", value: "INS" },
	{ label: "Del", value: "DEL" },
	{ label: "Mixed", value: "MIXED" },
];

type VariantsOrderByValueT = "infoColumnData.INTERVAR_VEREDICT" |
	"infoColumnData.EFFECT" |
	"infoColumnData.IMPACT" |
	"infoColumnData.GNOMAD_EXOME_ALL" |
	"infoColumnData.GNOMAD_GENOME_ALL" |
	"infoColumnData.GENE_NAME" |
	"position"

const variantsOrderByOptions = (translate: Function): { label: string, value: VariantsOrderByValueT }[] => [
	{ label: translate("common.classification"), value: "infoColumnData.INTERVAR_VEREDICT" },
	{ label: translate("common.effect"), value: "infoColumnData.EFFECT" },
	{ label: translate("common.impact"), value: "infoColumnData.IMPACT" },
	{ label: "Gnomad Exome", value: "infoColumnData.GNOMAD_EXOME_ALL" },
	{ label: "Gnomad Genome", value: "infoColumnData.GNOMAD_GENOME_ALL" },
	{ label: translate("common.gene"), value: "infoColumnData.GENE_NAME" },
	{ label: translate("common.position"), value: "position" }
]


const rollesOptions = (translate: Function): { label: string, value: RollesT }[] => [
	{ label: translate("user.rollesOptions.guest"), value: "ROLE_GUEST" },
	{ label: translate("user.rollesOptions.reporter"), value: "ROLE_REPORTER" },
	{ label: translate("user.rollesOptions.operator"), value: "ROLE_OPERATOR" },
	{ label: translate("user.rollesOptions.admin"), value: "ROLE_ADMIN" },
]

const evidenciaAcmg = [
	"PVS1", "PS1", "PS2", "PS3", "PS4", "PM1", "PM2", "PM3", "PM4", "PM5",
	"PM6", "PP1", "PP2", "PP3", "PP4", "PP5", "BA1", "BS1", "BS2", "BS3",
	"BS4", "BP1", "BP2", "BP3", "BP4", "BP5", "BP6", "BP7"
]

const evidenciaAcmgWeights: LabelValueI[] = [
	{ value: "VERYSTRONG", label: "VS" },
	{ value: "STRONG", label: "STR" },
	{ value: "SUPPORTING", label: "SUP" },
	{ value: "MODERATE", label: "MOD" },
	{ value: "STAND-ALONE", label: "SA" }
]

const studyTypeOptions = (translate: Function): LabelValueI[] => [
	{ label: translate('home.filter.studyType.all'), value: null },
	{ label: translate('home.filter.studyType.singles'), value: "simple" },
	{ label: translate('home.filter.studyType.familiares'), value: "familiar" },
	{ label: translate('home.filter.studyType.multiples'), value: "multiple" }
]
const studyStateOptions = (translate: Function): LabelValueI[] => [
	{ label: translate('home.studyState.all'), value: "ALL" },
	{ label: translate('home.studyState.active'), value: "ACTIVE" },
	{ label: translate('home.studyState.analizing'), value: "ANALIZING" },
	{ label: translate('home.studyState.reported'), value: "REPORTED" },
	{ label: translate('home.studyState.archived'), value: "ARCHIVED" },
	{ label: translate('home.studyState.incomplete'), value: "incomplete" },
	{ label: translate('home.studyState.processing'), value: "processing" }
];
const studyStateOptions2 = (translate: Function): LabelValueI[] => [
	{ label: translate('home.studyState.active'), value: "ACTIVE" },
	{ label: translate('home.studyState.analizing'), value: "ANALIZING" },
	{ label: translate('home.studyState.reported'), value: "REPORTED" },
	{ label: translate('home.studyState.archived'), value: "ARCHIVED" },
	{ label: translate('home.studyState.processing'), value: "PROCESSING" },
];

const genomeRefernceOptions: LabelValueI[] = [
	{ value: "rCRS", label: "rCRS" },
	{ value: "hg19", label: "UCSC_HG19" },
	{ value: "GRCh37", label: "NCBI_GRCH37" },
	{ value: "hg38", label: "UCSC_HG38" },
	{ value: "GRCh38", label: "NCBI_GRCH38" }
]

const reportedVariantsDesicionOptions = (translate: Function): LabelValueI[] => [
	{ label: translate("variant.decisionOptions.desicion"), value: null },
	{ label: translate("variant.decisionOptions.discarded"), value: "Discarded" },
	{ label: translate("variant.decisionOptions.hold"), value: "Hold" },
	{ label: translate("variant.decisionOptions.reported"), value: "Reported" },
]

type ZygosityT = "hem" | "hom" | "het" | "homref" | "notcov";

const cancerHotspotsOrganTypes: string[] = [
	"uterus", "cnsbrain", "esophagusstomach", "breast", "bowel",
	"lung", "prostate", "bladder", "unk", "cervix", "headandneck",
	"thyroid", "ovaryfallopiantube", "liver", "biliarytract",
	"lymph", "pancreas", "skin", "kidney", "softtissue", "penis",
	"testis", "blood", "bone", "adrenalgland", "ampullaofvater",
	"vulvavagina", "thymus", "pleura", "eye", "peritoneum",
]

const civicGeneId: { [name: string]: number } = {
	"AKT1": 2, "CCND1": 8, "CCND2": 9, "CCND3": 10, "CCNE1": 11, "CDK4": 13, "CEBPA": 15, "PRKACA": 17, "DNMT3A": 18, "GATA2": 25,
	"IDH2": 27, "MEF2D": 32, "MGMT": 34, "NPM1": 35, "PML": 39, "RUNX1": 43, "SF3B1": 44, "U2AF1": 48, "WT1": 49, "NOTCH1": 50, "DDR2": 51,
	"FLI1": 53, "FOXP1": 56, "REL": 57, "IGF2": 60, "AURKA": 61, "PBRM1": 62, "KMT2D": 64, "BTK": 65, "APC": 66, "AR": 67, "ASXL1": 68, "ERG": 71,
	"FOXL2": 72, "IKZF1": 73, "NCOA3": 74, "PAX8": 75, "PGR": 76, "SMAD4": 77, "SMARCA4": 78, "TTF1": 80, "ACTL6A": 150, "ACVR1": 154, "PARP1": 199,
	"AEBP1": 221, "AKT2": 254, "ALCAM": 259, "BIRC3": 353, "BIRC5": 355, "AREG": 389, "RHOA": 399, "ASCL1": 436, "ASNS": 446, "ASS1": 450, "ATR": 524,
	"ATRX": 525, "AXL": 533, "B2M": 537, "TNFRSF17": 570, "CEACAM1": 588, "PRDM1": 593, "BLK": 594, "KLF5": 634, "CASP8": 761, "CBFA2T3": 774, "CBFB": 776,
	"CBL": 778, "CBLB": 779, "CD19": 826, "MS4A1": 827, "CD44": 855, "CD79B": 868, "CDK2": 906, "CDK5": 908, "CDK9": 912, "CDKN1A": 913, "CDKN1B": 914,
	"CDX2": 927, "MAP3K8": 1142, "CREBBP": 1193, "CRKL": 1204, "CSF3R": 1239, "CTAG1B": 1278, "CCN2": 1283, "CTLA4": 1285, "CTNNB1": 1290, "CYP2D6": 1342, "DCC": 1396,
	"GADD45A": 1411, "DDIT3": 1413, "DEFA1": 1428, "NQO1": 1463, "DNMT1": 1510, "DPYD": 1526, "DRD5": 1536, "DUSP6": 1567, "EGF": 1634, "EIF1AX": 1646, "EIF4EBP1": 1655,
	"EP300": 1704, "EPAS1": 1705, "EPHB2": 1719, "EPHB4": 1721, "EPOR": 1728, "ERBB3": 1733, "ERBB4": 1734, "ERCC1": 1735, "ERCC2": 1736, "EREG": 1737, "ERCC5": 1741,
	"ESR2": 1753, "ETS2": 1763, "ETV1": 1764, "ETV4": 1767, "ETV5": 1768, "MECOM": 1771, "FANCA": 1810, "FANCC": 1811, "PTK2B": 1818, "FCGR2A": 1842, "FCGR2B": 1843,
	"FCGR3A": 1844, "FGF2": 1872, "FGF3": 1873, "FGF13": 1883, "FGFR4": 1887, "FOXF1": 1911, "FOXC2": 1920, "FOXO1": 1925, "FLT3LG": 1937, "FLT4": 1938, "FN1": 1947,
	"FNTB": 1950, "FOS": 1955, "GAS6": 2186, "GATA1": 2188, "GLI1": 2279, "ARHGAP35": 2438, "GSTP1": 2473, "MSH6": 2478, "H3-3A": 2537, "H3-3B": 2538, "HDAC2": 2578,
	"HGF": 2591, "NRG1": 2593, "HIF1A": 2596, "HLA-C": 2608, "HLA-DRA": 2622, "HMOX1": 2657, "HOXC10": 2715, "HOXD8": 2723, "HRAS": 2747, "HSPA5": 2778, "HSPB1": 2784,
	"TNC": 2822, "IGF1R": 2899, "IL2RB": 2962, "IL6": 2970, "CXCL10": 3017, "JAK1": 3090, "JAK3": 3091, "JUN": 3094, "KDR": 3153, "KRT18": 3215, "LAG3": 3236,
	"STMN1": 3255, "LEPR": 3274, "TACSTD2": 3362, "EPCAM": 3364, "SMAD2": 3375, "SMAD3": 3376, "MDM4": 3466, "MEN1": 3485, "MITF": 3527, "MKI67": 3529, "MLH1": 3532,
	"KMT2A": 3537, "MMP2": 3549, "MMP9": 3553, "MN1": 3564, "MPL": 3577, "MRE11": 3586, "MSH2": 3628, "MSH3": 3629, "MTAP": 3659, "MTHFR": 3672, "MYB": 3730,
	"MYCL": 3738, "MYCN": 3741, "NBN": 3801, "NEDD9": 3852, "NF2": 3870, "NFE2L2": 3878, "NOTCH3": 3936, "NT5E": 3976, "NUP98": 3995, "PRKN": 4103, "PAX5": 4111,
	"PCNA": 4137, "ABCB1": 4244, "PIK3CB": 4285, "PIM1": 4286, "PIK3R1": 4289, "PIK3R2": 4290, "PLCG2": 4327, "PLK1": 4337, "PMS2": 4371, "POLD1": 4384, "POLE": 4386,
	"POU5F1": 4419, "PPP2R1A": 4463, "PRKAA2": 4503, "PRKAR1A": 4511, "PRKCB": 4517, "MAPK1": 4532, "MAP2K7": 4546, "PSMB8": 4616, "PSMD4": 4630, "PTGS2": 4659, "PTPN6": 4682,
	"PTPN11": 4685, "PTPN12": 4686, "PTPRB": 4690, "PTPRD": 4692, "PTPRF": 4695, "RAC1": 4753, "RAD23B": 4760, "RASA1": 4791, "RASGRF1": 4793, "RB1": 4795, "RHEB": 4868,
	"RRM1": 5051, "RRM2": 5052, "ATXN7": 5112, "SCN8A": 5128, "CX3CL1": 5167, "SDHA": 5176, "SDHB": 5177, "SRSF2": 5210, "SGK1": 5225, "SMARCA2": 5354, "SMO": 5365,
	"SOS1": 5409, "SOX10": 5418, "SSTR5": 5499, "SSX2": 5501, "SSX4": 5503, "STAT1": 5514, "STAT3": 5516, "STAT5B": 5519, "STAT6": 5520, "STK11": 5534, "SYK": 5572,
	"ZEB1": 5649, "TCF19": 5654, "TEK": 5717, "TF": 5723, "NR2F2": 5731, "TFE3": 5734, "TFF3": 5737, "TGFA": 5742, "TGFBR3": 5752, "THBS2": 5761, "TIMP1": 5777,
	"TOP1": 5845, "TOP2A": 5848, "TP53BP1": 5852, "TPT1": 5870, "HSP90B1": 5876, "TSPYL1": 5939, "TYMS": 5971, "TYR": 5972, "UGT1A": 6023, "KDM6A": 6054, "VEGFA": 6071,
	"VEGFC": 6073, "WEE1": 6104, "WNT11": 6119, "XRCC1": 6144, "CXCR4": 6329, "DEK": 6358, "TFEB": 6380, "AIMP2": 6394, "HMGA2": 6455, "LZTR1": 6523, "ZRSR2": 6534,
	"KDM5C": 6538, "ARID1A": 6559, "H3C2": 6614, "SPOP": 6652, "LGR5": 6772, "IRS2": 6862, "ABCC3": 6906, "CFLAR": 7009, "PROM1": 7014, "ALDH1A2": 7024, "SPHK1": 7041,
	"FUBP1": 7044, "CCNE2": 7222, "AURKB": 7282, "PTTG1": 7297, "KLF4": 7356, "ABCG2": 7451, "SH3PXD2A": 7623, "HDAC9": 7704, "CUL7": 7780, "FGF19": 7909, "AKT3": 7936,
	"HDAC6": 7948, "BCL2L11": 7953, "SH2B3": 7954, "RAD50": 8032, "SPRY2": 8160, "TUBB3": 8262, "YAP1": 8292, "MERTK": 8331, "HOXB13": 8351, "NCOA2": 8368, "SLCO1B1": 8450,
	"CTCF": 8508, "DLL3": 8524, "STAG2": 8553, "HSPH1": 8614, "TLK2": 8787, "PTPRT": 8878, "PTP4A3": 8908, "RRAS2": 9054, "ZHX2": 9121, "DKK1": 9171, "DICER1": 9533,
	"SIRT1": 9538, "CBLC": 9705, "PPP1R15A": 9722, "WWTR1": 9952, "SETBP1": 10024, "PDCD4": 10573, "MAGEH1": 11261, "DROSHA": 11314, "TBK1": 11322, "TLX3": 11466, "CTAG2": 11492,
	"FOXP3": 11587, "CRBN": 11761, "RSF1": 12121, "LRP1B": 12146, "ERRFI1": 12271, "ROBO4": 12367, "UGT1A1": 12422, "NUDT15": 12881, "FBXW7": 12903, "DDX43": 13005, "CHD7": 13085,
	"WDR12": 13185, "PBK": 13276, "POLE4": 13465, "CYSLTR2": 13648, "CIP2A": 13948, "KMT2C": 14089, "BACH2": 14160, "PAPPA2": 14195, "PBLD": 14284, "CRLF2": 14303, "BIRC7": 14842,
	"GALNT14": 14923, "PREX2": 15344, "PDCD1LG2": 15403, "NECTIN4": 15676, "HAVCR2": 16394, "ABCC10": 16656, "IL33": 16802, "SLFN11": 16872, "NAPRT": 17016, "H3C14": 17939, "VPS37A": 18353, "PXDNL": 18364,
	"DGKH": 19449, "ZNF384": 19738, "ARID2": 19838, "FLCN": 19959, "RICTOR": 20480, "RABL3": 21380, "ATXN1L": 22231, "MIR21": 24929, "MIR218-1": 24938, "ECSCR": 26599, "KLLN": 32083,
	"DUX4": 34321, "PRNCR1": 41522, "COX2": 56400, "CIC": 9326, "BRD4": 9588, "POT1": 9935, "CSF1R": 33, "AXIN2": 6572, "RAD54L": 6676, "CD274": 11335, "DDX41": 11932,
	"SUFU": 12074, "ALK": 1, "BCOR": 12555, "FANCL": 12754, "RBM15": 14483, "ZFTA": 14629, "PALB2": 15013, "BRIP1": 15955, "CDK12": 12112, "ARAF": 3, "MBD4": 7084,
	"KIF23": 7502, "SNCAIP": 7608, "ABL1": 4, "BRAF": 5, "BRCA1": 6, "BRCA2": 7, "CDK6": 12, "CDKN2A": 14, "EGFR": 19, "ERBB2": 20, "ESR1": 21,
	"FGFR2": 22, "FGFR3": 23, "EZH2": 63, "FLT3": 24, "IDH1": 26, "JAK2": 28, "KIT": 29, "KRAS": 30, "MAP2K1": 31, "NRAS": 36, "PIK3CA": 37,
	"PDGFRA": 38, "PTEN": 41, "VHL": 58, "BCL2": 59, "RET": 42, "TP53": 45, "TSC1": 46, "TSC2": 47, "MET": 52, "EWSR1": 54, "TET2": 55,
	"AGR2": 8407, "ATM": 69, "BAP1": 70, "TERT": 79, "ABL2": 101, "ACTA1": 128, "BARD1": 549, "BAX": 550, "BCL6": 566, "CALR": 732, "CDH1": 888,
	"CDKN2B": 916, "CHEK1": 981, "CSF1": 1234, "SLC29A1": 1701, "ETV6": 1769, "PDGFB": 4173, "FGFR1": 1885, "MTOR": 2073, "GATA3": 2189, "B4GALT1": 2239, "GNA11": 2308,
	"GNAQ": 2317, "GNAS": 2319, "HBB": 2556, "IL7R": 2975, "LYN": 3359, "TBXT": 5581, "TYK2": 5970, "STAG3": 8552, "MDM2": 3465, "MYC": 3737, "MYD88": 3742,
	"MYOD1": 3777, "NF1": 3867, "NTRK1": 3983, "NTRK2": 3984, "NTRK3": 3985, "PDGFRB": 4176, "PPP2R2A": 4465, "MAP2K2": 4542, "PRPS1": 4566, "PTCH1": 4645, "RAD51C": 4762,
	"RAD51B": 4763, "RAD51D": 4765, "RAF1": 4767, "RELA": 4831, "RIT1": 4875, "ROS1": 4941, "RPS6": 5013, "SMARCB1": 5356, "SSX1": 5500, "CHEK2": 8950, "NT5C2": 9189
}

const getVariantQCFilters = (filterType: "germline" | "somatic"): LabelValueI[] => {
	const germlineFiltes = [
		{ label: "Pass", value: "PASS" },
		{ label: "Off target", value: "Off_target" },
		{ label: "HF LowQual", value: "HF_LowQual" },
		{ label: "HF LowQualindels", value: "HF_LowQualindels" }
	]
	if (filterType === "germline")
		return germlineFiltes;
	else
		return [
			...germlineFiltes,
			{ label: "base_qual", value: "base_qual" },
			{ label: "clustered_events", value: "clustered_events" },
			{ label: "germline", value: "germline" },
			{ label: "haplotype", value: "haplotype" },
			{ label: "multiallelic", value: "multiallelic" },
			{ label: "orientation", value: "orientation" },
			{ label: "panel_of_normals", value: "panel_of_normals" },
			{ label: "slippage", value: "slippage" },
			{ label: "strand_bias", value: "strand_bias" },
			{ label: "weak_evidence", value: "weak_evidence" },
		]
}

export type {
	LabelValueI,
	VariantsOrderByValueT,
	RollesT,
	CommentStateT,
	ZygosityT
}

const getVariantFiltersLabels = (translate: Function): { [name: string]: string } => {
	const variantFiltersLabels: { [name: string]: string } = {
		"pass": "PASS",
		"fail": "FAIL",
		"lowqual": translate("casesQuery.lowQuality"),
		"off_target": "Off target",
		"panel_of_normals": "PON",
		"germline": "Germ",
		// -- this previous are the relevant, the nextones are going to be order in ascending mode 
		"base_qual": "base qual",
		"clustered_events": "clustered events",
		"duplicate": "duplicate",
		"fragment": "fragment",
		"haplotype": "haplotype",
		"hf_lowqual": "lowqual",
		"hf_lowqualindels": "lowqual",
		"low_allele_frac": "low allele frac",
		"map_qual": "map qual",
		"multiallelic": "multiallelic",
		"n_ratio": "n ratio",
		"normal_artifact": "normal artifact",
		"orientation": "orientation",
		"position": "position",
		"possible_numt": "possible numt",
		"slippage": "slippage",
		"strand_bias": "strand bias",
		"strict_strand": "strict strand",
		"weak_evidence": "weak evidence"
	}
	return variantFiltersLabels;
}


export {
	OncotTreeDict,

	cancerHotspotsOrganTypes,
	civicGeneId,
	commentStatesColors,
	consequenceEffectValues,
	consequenceImpactValues,
	evidenceClinvarValues,
	evidenciaAcmg,
	evidenciaAcmgWeights,
	genomeRefernceOptions,
	getLibraryPrepKitLabelVal,
	getValueByKeyInLibraryPrepKit,
	getVariantFiltersLabels,
	getVariantQCFilters,
	mitochondrialDiseaseStatusValues,
	mitochondrialScoreInterpretationValues,
	mutationTasterValues,
	polyphenValues,
	reportedVariantsDesicionOptions,
	rollesOptions,
	siftValues,
	studyStateOptions,
	studyStateOptions2,
	studyTypeOptions,
	variantAcmgValues,
	variantRelevanceValues,
	variantsOrderByOptions,
	variantTypesValues
}
