import React, { useContext, useEffect, useState } from 'react';
import { Table } from "react-bootstrap";
import { LangContext } from 'src/Contexts/Lang';
import CircleLabel from '../../Common/CircleLabel';

interface StudyData {
    studyName: string;
    code: string;
    batch: string;
    studyDescription: string;
    professional: string;
    institution: string;
    genomeReference: string;
    user: string;
    domainId: string;
    libraryPrepKit: string;
    customLibrary: string;
    type: string;
    hasBam: string;
    // hasBam: boolean;
    hasCnv: string;
    // hasCnv: boolean;
    subtype: string;
    fileSamples: string;
}

interface BulkLoadTableCsvProps {
    csvData: string;
}

export default function BulkLoadTableCsv(props: BulkLoadTableCsvProps) {
    const { dispatch: { translate } } = useContext(LangContext);
    const [tableData, setTableData] = useState<StudyData[]>([]);

    useEffect(() => {
        const parseCSV = (csv: string): StudyData[] => {
            const lines = csv.trim().split('\n');
            const headers = lines[0].replaceAll("\r", "").split(',');
            return lines.slice(1).map(line => {
                const values = line.split(',');
                const entry: Record<string, string | boolean> = {};
                headers.forEach((header: string, index: number) => {
                    if (header === 'hasBam' || header === 'hasCnv') {
                        entry[header] = values[index].toLowerCase() === 'true';
                    } else {
                        entry[header] = values[index];
                    }
                });

                return entry as unknown as StudyData;
            });
        };

        setTableData(parseCSV(props.csvData));
    }, [props.csvData]);

    return (
        <div style={{ fontSize: 11 }}>
            <div style={{ fontWeight: "bold", fontSize: 15, margin: 5, color: "gray" }}>{translate("common.samples")}&nbsp;
                <CircleLabel label={String(tableData.length)} size={2} backColor={"darkgray"} style={{ verticalAlign: "middle", padding: "2px 9px" }} />
            </div>
            <div style={{ overflow: "auto" }}>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>{translate(`home.processCase.name`)}</th>
                            <th>{translate(`common.code`)}</th>
                            <th>{translate(`common.batch`)}</th>
                            <th>{translate(`home.processCase.description`)}</th>
                            <th>{translate(`common.professional`)}</th>
                            <th>{translate(`common.institution`)}</th>
                            <th>{translate(`common.referenceGenome`)}</th>
                            <th>{translate(`user.user`)}</th>
                            <th>{translate(`domain.domainId`)}</th>
                            <th>{translate(`common.library`)}</th>
                            <th>{translate(`home.processCase.customLibrary`)}</th>
                            <th>{translate(`home.processCase.studyType`)}</th>
                            <th>BAM</th>
                            <th>CNV</th>
                            <th>{translate(`home.processCase.studyType`)}</th>
                            <th>{translate(`common.vcfGzFile`)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                <td>{row.studyName}</td>
                                <td>{row.code}</td>
                                <td>{row.batch}</td>
                                <td>{row.studyDescription}</td>
                                <td>{row.professional}</td>
                                <td>{row.institution}</td>
                                <td>{row.genomeReference}</td>
                                <td>{row.user}</td>
                                <td>{row.domainId}</td>
                                <td>{row.libraryPrepKit}</td>
                                <td>{row.customLibrary}</td>
                                <td>{row.type}</td>
                                <td>{row.hasBam ? 'Yes' : 'No'}</td>
                                <td>{row.hasCnv ? 'Yes' : 'No'}</td>
                                <td>{row.subtype}</td>
                                <td>{row.fileSamples}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};
