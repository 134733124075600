import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Badge, Spinner, Col, Row, Form, Dropdown } from 'react-bootstrap';
import { LangContext } from '../../../Contexts/Lang';
import "../../Common/table.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCalendarDay, faEdit, faTrash, faFolder, faCog, faFolderPlus, faFolderOpen, faSearchPlus, faChartPie, faSearch } from '@fortawesome/free-solid-svg-icons';
import StudyService from '../../../Services/StudyServices';
import { Tooltip } from '../../Common/Tooltip/Tooltip';
import BadgeTypeSubtype from '../../Common/Badges/BadgeSampleTypeSubtype';
import CopyToClipboard from '../../Common/CopyToClipboard';
import ThemeContext from '../../../Contexts/Theme/ThemeContext';
import UserContext from 'src/Contexts/User/UserContext';
import { getValueByKeyInLibraryPrepKit } from 'src/utils/BioFunctionalData';
import { CasesTableT } from './CasesTable';
import { StudyI } from 'src/Contexts/interfaces';

interface CasesTableRowI {
    history: any,
    actualRow: any,
    casesSelecteds: any[],
    handleCasesSelecteds: any,
    handleReload(): void,
    handleShowProcessCase(id?: number, domainId?: string): any,
    handleShowStatistics?(study?: StudyI, domain?: number): void,
    handleShowCnvs?(study?: StudyI, domain?: number): void,
    CTType?: {
        type: CasesTableT,
        data: any
    }
}

export default function CasesTableRow(props: CasesTableRowI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const { user } = useContext(UserContext);
    const isAdmin = (user.data?.authorities ?? []).find((x) => (x?.authority ?? "").toLowerCase().indexOf("admin") > -1)
    const isSystem = (user.data?.authorities ?? []).find((x) => (x?.authority.toUpperCase().trim() === "ROLE_SYSTEM"))
    const isOperator = (user.data?.authorities ?? []).find((x) => (x?.authority ?? "").toLowerCase().indexOf("operator") > -1)

    const isDomainDetail = (props?.CTType?.type ?? "") === "domainDetail";
    const state = props.actualRow.state?.trim()?.toLowerCase();
    const isprocessing = state === "processing" ? true : false;
    const isincomplete = state === "incomplete" ? true : false;
    const SS = new StudyService();

    const domainId = props.CTType?.data.domainId ?? props.actualRow.domainId;
    const launchGetStudyAndShowModal = (): void => {
        SS.read_case(`${props.actualRow.id}`, domainId)
            .then((res: any) => {
                if (res.status === 200) {
                    if (res.data.study && props.handleShowStatistics) {
                        const a: StudyI = res.data.study as StudyI;
                        props.handleShowStatistics(a, domainId);
                    }
                }
            })
            .catch((e: any) => {
                // setstudyReq({ loading: false, loaded: false, data: null, error: e })
            })
    }
    const launchGetStudyAndShowCnvs = (): void => {
        SS.read_case(`${props.actualRow.id}`, domainId)
            .then((res: any) => {
                if (res.status === 200) {
                    if (res.data.study && props.handleShowCnvs) {
                        const a: StudyI = res.data.study as StudyI;
                        props.handleShowCnvs(a, domainId);
                    }
                }
            })
            .catch((e: any) => {
                // setstudyReq({ loading: false, loaded: false, data: null, error: e })
            })
    }

    const getFirstColumn = () => {
        const getReference = () => {
            let libraryPrepKit: any = false;
            if (props.actualRow.libraryPrepKit &&
                props.actualRow.libraryPrepKit.trim() !== "") {
                libraryPrepKit = getValueByKeyInLibraryPrepKit(props.actualRow.libraryPrepKit.toUpperCase());
            }
            let result = `${props.actualRow.genomeReference}`
            if (libraryPrepKit) result = `${result} - ${libraryPrepKit}`
            return result;
        }
        const getDescription = () => {
            if (props.actualRow.description && props.actualRow.description.trim() !== "")
                return ` - ${props.actualRow.description.slice(0, 75)}${props.actualRow.description.length > 75 ? '...' : ''}`
            return ``
        }
        return (
            <>
                <Row style={rowStyles}>
                    <span style={{ color: (!isprocessing ? "darkslategray" : "darkgray"), marginBottom: 0 }}>
                        {props.actualRow.name}
                        <CopyToClipboard valueToCopy={props.actualRow.name} fontSize={"9px"} />
                    </span>
                    {props.actualRow.code && props.actualRow.code !== "" && <>
                        <span style={{ color: "gray", marginBottom: 0, fontSize: "12px", }}>
                            - {props.actualRow.code}
                            <CopyToClipboard valueToCopy={props.actualRow.code} fontSize={"9px"} />
                        </span>
                    </>
                    }
                </Row>
                <Row style={rowStyles}>
                    <Col style={{ padding: 0, maxWidth: "fit-content" }}>
                        <BadgeTypeSubtype type={props.actualRow.type}
                            subtype={props.actualRow.subtype.toLowerCase()}
                        />
                        <Badge variant="secondary" style={{ fontWeight: "inherit", fontSize: "10px", backgroundColor: "darkgray" }}>
                            {getReference()}
                        </Badge>{' '}
                        <span style={{ color: "gray", marginBottom: 0, fontSize: "13px", wordBreak: "break-word" }}>
                            {getDescription()}
                        </span>
                    </Col>
                </Row>
            </>
        )
    }
    const getProfessionalColumn = () => {
        return (<>
            <Row style={rowStyles}>
                <Tooltip label={translate('common.professional')}>
                    <p style={{
                        color: (!isprocessing ? "darkslategray" : "darkgray"), marginBottom: 0, fontSize: "13px"
                    }}>{props.actualRow.professional}</p>
                </Tooltip>
            </Row>
        </>
        )
    }
    const getDomainColumn = () => {
        return (<>
            <Row style={rowStyles}>
                <Tooltip label={translate('common.domain')}>
                    <p style={{
                        color: (!isprocessing ? "darkslategray" : "darkgray"), marginBottom: 0, fontSize: "11px"
                    }}>{props.actualRow.domainName}</p>
                </Tooltip>
            </Row>
        </>
        )
    }

    const getStateColumn = () => {
        const styleP = { color: (!isprocessing ? "gray" : "darkgray"), marginBottom: 0, fontSize: "13px" }
        const styleIcon = { border: "none", marginRight: "5px", color: "darkgray" }
        const state = props.actualRow.state?.trim()?.toLowerCase()
        const states: any = {
            active: <>
                <FontAwesomeIcon icon={faCheckCircle} style={styleIcon} />
                <p style={styleP}>
                    {translate('home.studyState.active')}
                </p>
            </>,
            archived: <>
                <FontAwesomeIcon icon={faFolder} style={styleIcon} />
                <p style={styleP}>
                    {translate('home.studyState.archived')}
                </p>
            </>,
            processing: <>
                <Spinner animation="border" variant="secondary" size="sm" style={{ marginRight: "5px", color: "darkgray !important" }} />
                <p style={styleP}>
                    {translate('home.studyState.processing')}
                </p>
            </>,
            reported: <>
                <FontAwesomeIcon icon={faCheckCircle} style={styleIcon} />
                <p style={styleP}>
                    {translate('home.studyState.reported')}
                </p>
            </>,
            default: <p style={styleP}>{props.actualRow.state}</p>
        }
        return <Row style={{ margin: 0, alignItems: "center", placeContent: "center" }}>
            {states[state] ?? states["default"]}
        </Row>
    }

    const getBamCnvEnabledColum = () => {
        return (<div style={{ placeContent: "center" }}>
            {props.actualRow.hasBam ?
                <Badge variant="secondary" style={{ fontSize: 10, backgroundColor: "lightgray" }}>
                    BAM
                </Badge> :
                <></>}
            {(props.actualRow.hasCnv) ? <>
                {props.actualRow.hasBam && <br />}
                <Badge variant="secondary" style={{ fontSize: 10, backgroundColor: "lightgray" }}>
                    CNV
                </Badge>
            </> : <></>}
        </div>
        )
    }

    const dateSplited = props.actualRow.simpleDateFormatCreated.split(" ")
    const getDateColumn = () => {
        return (
            <Tooltip label={translate("common.creationDate")}>
                <div>
                    <Row style={{ margin: 0, padding: 0, placeContent: "center" }}>
                        <FontAwesomeIcon icon={faCalendarDay} style={{ border: "none", marginRight: "5px", color: "darkgray" }} />
                        <p style={{ color: (!isprocessing ? "gray" : "darkgray"), marginBottom: 0, fontSize: "13px" }}>
                            {dateSplited && dateSplited.length > 0 ? dateSplited[0] : "-"}
                        </p>
                    </Row>
                    <Row style={{ margin: 0, padding: 0, placeContent: "center" }}>
                        <p style={{ color: (!isprocessing ? "gray" : "darkgray"), marginBottom: 0, fontSize: "13px" }}>
                            {dateSplited && dateSplited.length > 1 ? dateSplited[1] : "-"}
                        </p>
                    </Row>
                </div>
            </Tooltip>
        )
    }

    const getDropdown = () => {
        const handleActive = () => {
            SS.active([props.actualRow.id]).then(() => {
                props.handleReload();
            })
        }
        const handleArchive = () => {
            SS.archive([props.actualRow.id]).then(() => {
                props.handleReload();
            })
        }
        const handleEnableDisableBam = () => {
            SS.update_case_bam(props.actualRow.id, props?.actualRow?.hasBam === null ? false : (props.actualRow.hasBam === true ? false : true))
                .then((res: any) => {
                    if (res.status === 200) {
                        if (props.handleReload) props.handleReload()
                    }
                }).catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
        }
        const handleEnableDisableCnv = () => {
            console.log(!props.actualRow.hasCnv)
            SS.update_case_cnv(props.actualRow.id, props?.actualRow?.hasCnv === null ? false : (props.actualRow.hasCnv === true ? false : true))
                .then((res: any) => {
                    if (res.status === 200) {
                        if (props.handleReload) props.handleReload()
                    }
                }).catch((e: any) => {
                    console.log("e")
                    console.log(e)
                })
        }
        const handleDelete = () => {
            SS.delete([props.actualRow.id], isDomainDetail ? domainId : props.actualRow.domainId ?? undefined).then(() => {
                props.handleReload();
            })
        }

        return (
            <Dropdown alignRight={true} >
                <div style={{ display: "flex", placeContent: "center" }}>
                    {!isprocessing ?
                        <>
                            {!isDomainDetail &&
                                <Tooltip label={translate('home.advancedQueries')}>
                                    <Link to={`/cases/query/caseId/${props?.actualRow?.id}/`} style={{ textDecoration: "none", color: "#222" }}>
                                        <Button variant="outline-light" style={{ border: "none", borderRadius: "50px 0 0 50px", borderRight: "solid 1px #EEE" }}>
                                            <FontAwesomeIcon icon={faSearchPlus} style={{ border: "none", color: "gray", fontSize: "15px" }} />
                                        </Button>
                                    </Link>
                                </Tooltip>
                            }
                            <Tooltip label={translate('home.table.caseActions')}>
                                <Dropdown.Toggle variant="outline-light" id="dropdown-basic"
                                    style={{
                                        padding: "6px ​20px",
                                        boxShadow: "none",
                                        border: "none",
                                        borderRadius: (isprocessing || isDomainDetail ? "50px" : "0 50px 50px 0"),
                                        fontSize: "15px",
                                        marginRight: "5px"
                                    }}>
                                    <FontAwesomeIcon icon={faCog}
                                        style={{ border: "none", color: "gray" }}
                                        data-tip="Add case"
                                    />
                                </Dropdown.Toggle>
                            </Tooltip>
                        </> :
                        <Button variant="outline-light"
                            style={{ border: "none", borderRadius: "50px" }}
                            onClick={handleDelete}>
                            <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray", fontSize: "15px" }} />
                        </Button>
                    }
                </div>
                <Dropdown.Menu style={{ minWidth: "170px", borderRadius: "0", overflow: "visible" }} >
                    {!isprocessing && (isAdmin || isOperator || isSystem) &&
                        <Dropdown.Item style={{ borderRadius: "0" }} variant="secondary"
                            onClick={() => {
                                props.handleShowProcessCase(props.actualRow.id, domainId)
                            }}
                        >
                            <Row>
                                <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                    <FontAwesomeIcon icon={faEdit} style={{ border: "none", color: "gray" }} />
                                </Col>
                                <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                    {translate('home.table.edit')}
                                </Col>
                            </Row>
                        </Dropdown.Item>}
                    {(isDomainDetail || isAdmin || isSystem) &&
                        <Dropdown.Item style={{ borderRadius: "0" }} variant="secondary"
                            onClick={() => { handleEnableDisableBam() }}>
                            <Row>
                                <Col sm={3} style={{ padding: "2px 0 0 0", fontSize: "11px", fontWeight: "bold", fontFamily: "monospace", color: "gray" }}>
                                    BAM
                                </Col>
                                <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                    {
                                        props.actualRow.hasBam ?
                                            translate('common.disable') :
                                            translate('common.enable')
                                    }
                                </Col>
                            </Row>
                        </Dropdown.Item>}
                    {(isDomainDetail || isAdmin || isSystem) &&
                        <Dropdown.Item style={{ borderRadius: "0" }} variant="secondary"
                            onClick={() => { handleEnableDisableCnv() }}>
                            <Row>
                                <Col sm={3} style={{ padding: "2px 0 0 0", fontSize: "11px", fontWeight: "bold", fontFamily: "monospace", color: "gray" }}>
                                    CNV
                                </Col>
                                <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                    {
                                        props.actualRow.hasCnv ?
                                            translate('common.disable') :
                                            translate('common.enable')
                                    }
                                </Col>
                            </Row>
                        </Dropdown.Item>}
                    {((isDomainDetail || isAdmin || isSystem) && props.actualRow.hasCnv) &&
                        <Dropdown.Item style={{ borderRadius: "0" }} variant="secondary"
                            onClick={() => { launchGetStudyAndShowCnvs() }}>
                            <Row>
                                <Col sm={3} style={{ padding: "2px 0 0 0", fontSize: "11px", fontWeight: "bold", fontFamily: "monospace", color: "gray" }}>
                                    <FontAwesomeIcon icon={faSearch} style={{}} />
                                    <span style={{ fontSize: 8 }}>
                                        CNV
                                    </span>
                                </Col>
                                <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                    Visualizar CNV
                                </Col>
                            </Row>
                        </Dropdown.Item>}
                    {isDomainDetail &&
                        <Dropdown.Item style={{ borderRadius: "0" }} variant="secondary"
                            onClick={() => { handleDelete() }}>
                            <Row>
                                <Col sm={3} style={{ padding: "0 0 0 5px" }}>
                                    <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray" }} />
                                </Col>
                                <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                    {translate('home.delete')}
                                </Col>
                            </Row>
                        </Dropdown.Item>}
                    {!isprocessing &&
                        <Dropdown.Item style={{ minWidth: "100px", borderRadius: "0" }} variant="secondary"
                            onClick={() => { state === "archived" ? handleActive() : handleArchive() }}>
                            <Row>
                                <Col sm={3} style={{ padding: "0 0 0 4px" }}>
                                    {state === "archived" ?
                                        <FontAwesomeIcon icon={faFolderOpen} style={{ border: "none", color: "gray" }} /> :
                                        <FontAwesomeIcon icon={faFolderPlus} style={{ border: "none", color: "gray" }} />
                                    }
                                </Col>
                                <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                    {state === "archived" ?
                                        translate('common.active') :
                                        translate('common.archive')
                                    }
                                </Col>
                            </Row>
                        </Dropdown.Item>}
                    {!isprocessing &&
                        <Dropdown.Item style={{ minWidth: "100px", borderRadius: "0" }}
                            variant="secondary"
                            onClick={() => launchGetStudyAndShowModal()}
                        >
                            <Row>
                                <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                    <FontAwesomeIcon icon={faChartPie} style={{ border: "none", color: "gray" }} />
                                </Col>
                                <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                    {translate('casesDetail.statistics')}
                                </Col>
                            </Row>
                        </Dropdown.Item>
                    }
                    {!isprocessing && (isAdmin || isSystem) &&
                        <Dropdown.Divider />
                    }
                    {
                        <Dropdown.Item style={{ minWidth: "100px", borderRadius: "0" }} variant="secondary" onClick={handleDelete}>
                            <Row>
                                <Col sm={3} style={{ padding: "0 0 0 3px" }}>
                                    <FontAwesomeIcon icon={faTrash} style={{ border: "none", color: "gray" }} />
                                </Col>
                                <Col sm={9} style={{ padding: "0 12px 0 0" }}>
                                    {translate('home.delete')}
                                </Col>
                            </Row>
                        </Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown >)
    }
    const columnStyles = { border: "none", verticalAlign: "middle", cursor: "pointer", opacity: isincomplete ? 0.6 : 1 }
    const rowStyles = { margin: 0 }

    const handleRowClick = () => {
        if (isDomainDetail || isSystem)
            props.handleShowProcessCase(props.actualRow.id, domainId)
        else {
            if (!isincomplete && !isprocessing)
                props.history.push(`/cases/caseId/${props.actualRow.id}/`);
        }
    }

    return (
        <tr style={{
            borderBottom: "1px solid lightgray",
            backgroundColor: isprocessing || isincomplete ? "#efefef" : "auto",
        }}
            className={isprocessing ? "processingTr" : "tr"}>
            <td style={{ border: "none", width: "5px", verticalAlign: "middle", textAlign: "center" }}>
                <Form.Check type="checkbox"
                    checked={props?.casesSelecteds?.find((y: any) => `${y}` === `${props.actualRow.id}`) ? true : false}
                    onChange={() => { props.handleCasesSelecteds(`${props.actualRow.id}`) }}
                />

            </td>
            <td style={{ ...columnStyles, wordWrap: "break-word" }} onClick={handleRowClick}>
                {getFirstColumn()}
            </td>
            {
                isSystem && <td style={columnStyles} onClick={handleRowClick}>
                    {getDomainColumn()}
                </td>
            }
            {
                !isDomainDetail && <td style={columnStyles} onClick={handleRowClick}>
                    {getProfessionalColumn()}
                </td>
            }
            <td style={columnStyles}
                onClick={handleRowClick}>
                <Tooltip label={translate("common.batch")}>
                    <Badge pill variant="light" style={{ fontSize: "10px", color: theme.grayHeader, fontWeight: "lighter", }}>
                        <>
                            {props.actualRow.batch && props.actualRow.batch !== "null" && props.actualRow.batch}
                        </>
                    </Badge>
                </Tooltip>
            </td>
            <td style={{ ...columnStyles, minWidth: 40, }} onClick={handleRowClick}>
                {getBamCnvEnabledColum()}
            </td>
            <td style={{ ...columnStyles, minWidth: 80, }} onClick={handleRowClick}>
                {getStateColumn()}
            </td>
            <td style={{ ...columnStyles, minWidth: 100, }} onClick={handleRowClick}>
                {getDateColumn()}
            </td>
            <td style={{ border: "none", verticalAlign: "middle", width: "50px" }}>
                {getDropdown()}
            </td>
        </tr >
    )
}

