import { useContext } from "react";
import { Badge } from 'react-bootstrap';
import { LangContext } from "../../../Contexts/Lang";
import React from "react";
import { Tooltip } from "src/Components/Common/Tooltip/Tooltip";


interface SampleStatsI {
	name: string,
	PF_Q30_BASES: string,
	MEDIAN_TARGET_COVERAGE: string,
	MEAN_TARGET_COVERAGE: string,
	PF_Q20_BASES: string

}
interface FilterPanelHeaderI {
	genomeReference?: string,
	samples?: SampleStatsI[]
}
export default function FilterPanelHeader(props: FilterPanelHeaderI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const getSamplesComponent = () => {
		return (
			<div>
				{(props.samples ?? []).map((sample: SampleStatsI, index: number) => {
					return (
						<span key={index} >
							<Badge variant="info" pill
								style={{ fontWeight: "bold", margin: 0 }}>
								{sample.name}
							</Badge>&nbsp;
							<span key={index} style={{ fontSize: "12px" }}>
								<Tooltip label={translate("coverage.meanTargetCoverage")}>
									<span><b style={{ color: "dimgray" }}>Mean</b>:&nbsp;{sample.MEAN_TARGET_COVERAGE} &nbsp;</span>
								</Tooltip>| &nbsp;
								<Tooltip label={translate("coverage.medianTargetCoverage")}>
									<span><b style={{ color: "dimgray" }}>Median</b>:&nbsp;{sample.MEDIAN_TARGET_COVERAGE} &nbsp;</span>
								</Tooltip>| &nbsp;
								<Tooltip label={translate("coverage.q30%	")}>
									<span><b style={{ color: "dimgray" }}>Q20</b>:&nbsp;{sample.PF_Q20_BASES} &nbsp;</span>
								</Tooltip>| &nbsp;
								<Tooltip label={translate("coverage.q20%	")}>
									<span><b style={{ color: "dimgray" }}>Q30</b>:&nbsp;{sample.PF_Q30_BASES} </span>
								</Tooltip>
							</span>
						</span>
					)
				})}
			</div>
		)
	}
	return (
		<div style={{ margin: 0, display: "flex" }}>
			<div style={{ padding: "0 7px 0 0", minWidth: "fit-content" }}>
				{/* <Tooltip placement='right-start'
					label={"CNVs detectados por DECoN. Se muestran sólo los CNVs con Bayes Factor mayor a 8 y su correspondiente análisis suplementario realizado con la herramienta ONCOCNV."}>
					<FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: "17px", color: "lightgray", verticalAlign: "middle" }} />
				</Tooltip>
				&nbsp;&nbsp; */}
				<Badge variant="info" pill style={{ fontWeight: "bold", margin: 0 }} >
					{props.genomeReference ?? ""}
				</Badge>
				{getSamplesComponent()}
			</div>
			{/* <div style={{ padding: 0, width: "100%", borderLeft: "solid 1px #EEE", paddingLeft: 12 }}>
				<CnvMetrics genesList={props.genesList}
					correlation={props.correlation}
					nComp={props.nComp} />
			</div> */}
		</div >
	)
}

