import React from 'react';
import { Accordion, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import FormInput from "../../Common/Form/FormInput";
import { useEffect, useState, useContext, useRef } from "react";
import StudyService from '../../../Services/StudyServices';
import { LangContext } from '../../../Contexts/Lang';
import './styles.css';
import { formInputType, StudyBulk, studyType } from '../../Common/Interfaces';
import { Formik } from "formik";
import * as yup from "yup";
import AsociateSamples from './AsociateSamples';
import EditCases from './EditCase';
import { studyStateOptions2, genomeRefernceOptions, getLibraryPrepKitLabelVal } from "../../../utils/BioFunctionalData";
import ThemeContext from "../../../Contexts/Theme/ThemeContext";
import UserContext from "../../../Contexts/User/UserContext";
import { getDateFormated } from 'src/utils/helpers';
import WarningCaseLoadFilesNotFound, { FilesNotFoundByDomainT } from 'src/Components/Common/WarningCaseLoadFilesNotFound';
import { AxiosResponse } from 'axios';
type CasesTableT = "default" | "domainDetail"

interface ProcessCaseI {
	show: boolean,
	handleClose: any,
	caseId?: number,
	studyTypeOptions: any[],
	handleReload?: any,
	domainId?: string
	domainName?: string
	CTType?: CasesTableT
}
export default function ProcessCase(props: ProcessCaseI) {
	const { theme } = useContext(ThemeContext);
	const { dispatch: { translate } } = useContext(LangContext);
	const { user } = useContext(UserContext);
	const isDomainDetail = (props?.CTType ?? "") === "domainDetail";
	const isAdmin = (user.data?.authorities ?? []).find((x) => (x?.authority ?? "").toLowerCase().indexOf("admin") > -1)
	const StudyS = new StudyService();
	const [initialForm, setInitialForm] = useState<any | undefined>()

	const [samplesSelecteds, setsamplesSelecteds] = useState<any[]>([])
	const [samplesEdit, setsamplesEdit] = useState<any | undefined>()
	const [studyType, setstudyType] = useState<studyType>("simple")
	const [samplesError, setsamplesError] = useState<boolean>(false)

	const initWarningModal = { "show": false, filesNotFound: {} }
	const [warningModal, setwarningModal] = useState<{ show: boolean, filesNotFound: FilesNotFoundByDomainT }>(initWarningModal)

	const [samplesFamiliarMultipleError, setsamplesFamiliarMultipleError] = useState<boolean>(false)

	let formRef: any = useRef();


	const secuences = ["array", "mt-dna", "wes", "wgs", "panel", "somatic"]
	const secuencesValLabel = secuences.map((x: any) => {
		return {
			value: x.toUpperCase(),
			label: translate(`home.table.subTypes.${x}`)
		}
	})

	const librariesValLabel = getLibraryPrepKitLabelVal()

	useEffect(() => {
		let isMounted = true;
		if (warningModal.show) {
			setwarningModal(initWarningModal)
		}
		if (props.show && props.caseId) {
			StudyS.read_case(props.caseId.toString(), props.domainId)//props.caseId
				.then((res: any) => {
					if (isMounted && res.status === 200) {
						const study = res.data.study;
						let newCase = study
						newCase.code = study.code ?? "";
						newCase.batch = study.batch ?? "";
						newCase.description = study.description ?? "";
						newCase.state = study.state ?? "";
						newCase.professional = study.professional ?? "";
						newCase.institution = study.institution ?? "";
						newCase.sequencing = study.subtype ?? "";
						newCase.libraryPrepKit = study.libraryPrepKit; //.toLowerCase();
						newCase.customLibraryPrepKit = study.customLibraryPrepKit ?? "";
						newCase.genomaRef = study.genomeReference ?? "";
						newCase.studyType = study.type ?? "";
						setsamplesSelecteds([])
						setsamplesEdit(study.samples)
						setstudyType(study.studyType)
						formRef?.current?.setValues(newCase)
					}
				})
				.catch((e: any) => {
					console.log("Error!!!!!!!!!!!")
					console.log(e)
				})
		} else {
			formRef?.current?.setValues(initializeValues())
			setsamplesEdit([])
		}
		setInitialForm(initializeValues())
		setsamplesSelecteds([])
		return () => { isMounted = false };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])
	useEffect(() => { }, [warningModal])
	function initializeValues() {
		let initialValues = {
			name: "",
			code: "",
			batch: "",
			description: "",
			state: "ACTIVE",
			professional: "",
			institution: props.domainName ?? user.data?.domainName ?? "",
			sequencing: "WES",
			libraryPrepKit: undefined,
			customLibraryPrepKit: "",
			genomaRef: "hg38",
			studyType: "simple",
		}
		if (isDomainDetail) return { ...initialValues, hasBam: false, hasCnv: false }
		return initialValues
	}
	function createSchema() {
		const name = yup.string()
			.min(3, translate(`error.toShort`))
			.max(50, translate(`error.toLong`))
			.required(translate(`error.required`));
		const code = yup.string()
		const batch = yup.string()
		const description = yup.string();
		const state = yup.string();
		const hasBam = yup.boolean();
		const hasCNV = yup.boolean();
		const professional = yup.string();
		const institution = yup.string();
		const sequencing = yup.string()
			.notOneOf(["..."], translate(`error.required`))
			.required(translate(`error.required`))
		const libraryPrepKit = yup.string()
			.required(translate(`error.required`))
		const customLibraryPrepKit = yup.string()
		const genomaRef = yup.string()
			.notOneOf(["..."], translate(`error.required`))
			.required(translate(`error.required`))
		const studyType = yup.string()
		let yupObjectShape: any = {
			name,
			code,
			batch,
			description,
			state,
			professional,
			institution,
			sequencing,
			libraryPrepKit,
			customLibraryPrepKit,
			genomaRef,
			studyType
		}
		if (isDomainDetail) yupObjectShape = { ...yupObjectShape, hasBam, hasCNV }
		return yup.object().shape(yupObjectShape)
	}
	function createForm(values: any, handleChange: any, errors: any) {
		function getSelectOptions(array: any[], addCeroValue: boolean = false) {
			if (addCeroValue)
				array = [{ value: undefined, label: "..." }, ...array]
			return array?.map((x: any, i: number) =>
				<option key={i} value={x.value}>{x.label}</option>
			)

		}
		const genomeRefernceOpt = getSelectOptions(genomeRefernceOptions, true)
		const studyStateOptions = getSelectOptions(studyStateOptions2(translate), true)
		const secuencesOptions = getSelectOptions(secuencesValLabel, true)
		const librariesOptions = getSelectOptions(librariesValLabel, true)
		const studyTypeOptions = getSelectOptions(props.studyTypeOptions.filter((x: any, i: number) => i !== 0))
		function commonFormInput(type: formInputType, key: string, name: string, label: string, placeholder?: string, required: boolean = false) {
			return <FormInput type={type}
				labelBold={true}
				labelColor={"gray"}
				// borderRadius={true}
				label={label}
				key={key}
				formGrouplStyle={{ "marginBottom": "15px" }}       // onKeyDown={(e: any) => handleEnter(e)}
				placeHolder={placeholder}
				name={name}
				// disabled={props.mode === "read"}
				required={required}
				requiredFeedback={errors[name]}
				feedbackValidator={!!errors[name]}
				value={values[name]}
				onChange={handleChange} />
		}
		function commonFormSelect(key: string, name: string, options: any, label: string, required: boolean = false) {
			return <Form.Group key={key} style={{ marginBottom: "10px" }} >
				<Form.Label style={{ borderRadius: "0", color: "gray" }}>
					{required && <span style={{ color: theme.error, float: "right" }}> *</span>}
					<strong>{label}</strong>
				</Form.Label>
				<Form.Control as="select" aria-label="Default select example" style={{ borderRadius: 0, fontSize: "12px" }}
					name={name}
					value={values[name]}
					disabled={(props.caseId && name === "studyType") ? true : false}
					onChange={(e: any) => {
						if (name === "studyType") {
							setstudyType(e.target?.value)
							if (e.target?.value === "simple")
								setsamplesSelecteds([samplesSelecteds[0]])
							else if (e.target?.value === "familiar") {
								setsamplesSelecteds([...samplesSelecteds.filter((x: any, i: number) => i < 5)])
							}
						}
						return handleChange(e)
					}}
					isInvalid={!!errors[name]}>
					{options}
				</Form.Control>
				<Form.Control.Feedback type="invalid"> {errors[name]} </Form.Control.Feedback>
			</Form.Group>
		}
		const nameInput = commonFormInput("text", "nameKey", "name", translate(`home.processCase.name`), `${translate(`home.processCase.name`)}...`, true);
		const codeInput = commonFormInput("text", "codeKey", "code", translate(`common.code`), `${translate(`common.code`)}...`);
		const batchInput = commonFormInput("text", "batchKey", "batch", translate(`common.batch`), `${translate(`common.batch`)}...`);
		const descriptionInput = commonFormInput("textarea", "descKey", "description", translate(`home.processCase.description`), `${translate(`home.processCase.description`)}...`)
		const stateSelect = commonFormSelect("stateKey", "state", studyStateOptions, translate(`home.studyState.state`), true)
		const professionalInput = commonFormInput("text", "professionalKey", "professional", translate(`common.professional`), `${translate(`common.professional`)}...`)
		const institution = commonFormInput("text", "institutionKey", "institution", translate(`common.institution`), `${translate(`common.institution`)}...`)
		const sequencingSelect = commonFormSelect("secuenceKey", "sequencing", secuencesOptions, translate(`home.processCase.sequencing`), true)
		const libraryPrepKit = commonFormSelect("libraryKey", "libraryPrepKit", librariesOptions, translate(`common.library`), true)
		const customLibraryPrepKit = commonFormInput("text", "customLibraryKey", "customLibraryPrepKit", translate(`home.processCase.customLibrary`), `${translate(`home.processCase.customLibrary`)}...`)
		const genomaRefSelect = commonFormSelect("genomeRefKey", "genomaRef", genomeRefernceOpt, translate(`common.referenceGenome`), true)
		const studyTypeSelect = commonFormSelect("studyTypeKey", "studyType", studyTypeOptions, translate(`home.processCase.studyType`))
		return (<>
			<Accordion defaultActiveKey="0">
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => { }}>
						{translate(`home.processCase.generalData`)}
						<FontAwesomeIcon icon={faAngleDown} style={{ float: "right", fontSize: "16px", color: "gray" }} />
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<Row>
								<Col xs={4}>{nameInput}</Col>
								<Col xs={4}>{codeInput}</Col>
								<Col xs={4}>{stateSelect}</Col>
							</Row >
							<Row>
								<Col xs={4}>
									<div>
										{batchInput}
									</div>
									{(isDomainDetail || isAdmin) &&
										<div style={{ display: "flex" }}>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<div style={{ display: "flex" }}>
												<div style={{ display: "flex", alignContent: "center" }}>
													<Form.Check type="checkbox"
														key={"hasBamKey"}
														name={"hasBam"}
														value={values["hasBam"]}
														checked={values["hasBam"]}
														onChange={() => {
															let newValues = formRef?.current?.values
															newValues["hasBam"] = !values["hasBam"]
															formRef?.current?.setValues(newValues)
															// return handleChange(true) 
														}}
													/>
													<span style={{ color: "gray", fontWeight: "bold" }}>
														BAM
													</span>
												</div>
											</div>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<div style={{ display: "flex" }}>
												<div style={{ display: "flex", alignContent: "center" }}>
													<Form.Check type="checkbox"
														key={"hasCnvKey"}
														name={"hasCnv"}
														value={values["hasCnv"]}
														checked={values["hasCnv"]}
														onChange={() => {
															let newValues = formRef?.current?.values
															newValues["hasCnv"] = !values["hasCnv"]
															formRef?.current?.setValues(newValues)
															// return handleChange(true) 
														}}
													/>
													<span style={{ color: "gray", fontWeight: "bold" }}>
														CNV
													</span>
												</div>
											</div>
										</div>
									}
								</Col>
								<Col xs={8}>{descriptionInput}</Col>
							</Row>
							<hr style={{ margin: "0 0 10px" }} />
							<Row>
								<Col xs={6}>{professionalInput}</Col>
								<Col xs={6}>{institution}</Col>
							</Row >
							<hr style={{ margin: "0 0 10px" }} />
							{
								values?.libraryPrepKit?.toLowerCase() === "custom" ?
									<Row>
										<Col xs={12} md={4}>{sequencingSelect}</Col>
										<Col xs={12} md={4}>{libraryPrepKit}</Col>
										<Col xs={12} md={4}>{customLibraryPrepKit}</Col>
									</Row > :
									<Row>
										<Col xs={12} md={6}>{sequencingSelect}</Col>
										<Col xs={12} md={6}>{libraryPrepKit}</Col>
									</Row >
							}
							<Row><Col xs={6}>{genomaRefSelect}</Col><Col xs={6}>{studyTypeSelect}</Col></Row >
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card style={{ padding: 0 }}>{
					props.caseId ?
						<EditCases
							studyType={studyType}
							samples={samplesEdit}
							setsamplesEdit={setsamplesEdit} /> :
						<AsociateSamples
							studyType={studyType}
							samplesSelecteds={samplesSelecteds}
							setsamplesSelecteds={setsamplesSelecteds}
							samplesError={samplesError}
							setsamplesError={setsamplesError}
							samplesFamiliarMultipleError={samplesFamiliarMultipleError}
							setsamplesFamiliarMultipleError={setsamplesFamiliarMultipleError}
							domainId={props.domainId}
						/>
				}
				</Card>
			</Accordion>
		</>
		)
	}
	function getTitle() {
		const iconStyles = { border: "none", marginRight: "7px", fontSize: "27px", verticalAlign: "text-bottom" }
		let title = <>
			<FontAwesomeIcon icon={faPlus} style={{ color: theme.success, ...iconStyles }} />
			<strong>{translate(`home.processCase.createCase`)}</strong>
		</>
		if (props.caseId)
			title = <>
				<FontAwesomeIcon icon={faEdit} style={{ color: theme.edit, ...iconStyles }} />
				<strong>{translate(`home.processCase.editCase`)}</strong>
			</>
		return (
			<Modal.Title style={{ margin: "0px 10px", color: theme.backgroundPrimary }} >
				{title}
			</Modal.Title>)
	}
	const title = getTitle()
	function handleSubmit(caseState: any) {
		function handleCreateCase(dataToSend: any) {
			const record: StudyBulk[] = [
				{
					studyName: dataToSend.studyName,
					code: dataToSend.code,
					batch: dataToSend.batch,
					studyDescription: dataToSend.studyDescription,
					professional: dataToSend.professional,
					institution: dataToSend.institution,
					genomeReference: dataToSend.genomeReference,
					user: `${user.data.id}`,
					domainId: `${props.domainId}`,
					libraryPrepKit: dataToSend.libraryPrepKit,
					customLibrary: dataToSend.customLibrary,
					type: dataToSend.type,
					hasBam: `${dataToSend.hasBam}`,
					hasCnv: `${dataToSend.hasCnv}`,
					subtype: dataToSend.subtype,
					fileSamples: dataToSend.fileSamples,
				}
			]
			return StudyS.create_case(dataToSend, props.domainId)
				.then((res: any) => {
					if (res.status === 200) {
						if (res.data.errors === "study.error.nameAlreadyExists") {
							formRef.current.setErrors(
								{ "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` })
						} else {
							const domainIdSamples = `${props.domainId}:${dataToSend.fileSamples.split("_acmg_final_annot.vcf.gz")[0]}`
							return StudyS.validateAuxiliaryFiles(domainIdSamples)
								.then((res: AxiosResponse<any>) => {
									if (Object.keys(res.data).length === 0) {
										props.handleClose();
										props.handleReload();
										return;
									}
									const filesNotFoundByDomain = StudyS.validateAuxiliaryFilesAdapter(res, record)
									if (filesNotFoundByDomain) {
										setTimeout(() => {
											props.handleClose();
											props.handleReload();
										}, 12000);
										setwarningModal({ show: true, filesNotFound: filesNotFoundByDomain })
									} else {
										props.handleClose();
										props.handleReload();
									}
								}).catch((e: any) => {
									console.log("e")
									console.log(e)
								})
						}
					}
				})
		}


		function handleEditCase(dataToSend: any) {
			return StudyS.update_case(dataToSend, props.domainId)
				.then((res: any) => {
					if (res.status === 200) {
						if (res.data.errors === "study.error.nameAlreadyExists") {
							formRef.current.setErrors(
								{ "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` })
						} else {
							if (props.handleReload) props.handleReload()
							props.handleClose()
						}
					}
				}).catch((e: any) => {
					if (e.errors && e.errors === "study.error.nameAlreadyExists") {
						formRef.current.setErrors(
							{ "name": `${translate(`common.name`)} ${translate(`error.alreadyDefined`)}` })
					}
					console.log("e")
					console.log(e)
				})
		}

		let dataToSend: any = {
			id: caseState.id,
			code: caseState.code,
			batch: caseState.batch,
			customLibrary: caseState.customLibraryPrepKit ?? "",
			institution: caseState.institution,
			studyDescription: caseState.description?.substr(0, 250) ?? "",
			studyState: caseState.state,
			libraryPrepKit: caseState.libraryPrepKit ? caseState.libraryPrepKit.indexOf("...") > -1 ? "" : caseState.libraryPrepKit : "",
			professional: caseState.professional,
			studyName: caseState.name,
			genomeReference: caseState.genomaRef,
			subtype: caseState.sequencing
		}
		if (isDomainDetail) dataToSend = { ...dataToSend, hasBam: caseState.hasBam, hasCnv: caseState.hasCnv }
		if (samplesEdit.length > 0) {
			dataToSend["samples"] = samplesEdit.map((x: any) => {
				let d: any = {
					id: x.id,
					birthDate: getDateFormated(x.sample.fechaNacimiento),
					sex: x.sample.sexo === "NULL" ? "" : x.sample.sexo
				}
				if (studyType === "familiar") {
					d["affected"] = x.afectado;
					d["relation"] = x.tag;
				}
				return d
			});
			return handleEditCase(dataToSend)
		}
		else if (samplesEdit.length === 0 && samplesSelecteds.length === 0) {
			setsamplesError(true)
			return
		} else {
			const st = formRef?.current?.values?.studyType
			dataToSend["fileSamples"] = samplesSelecteds;
			dataToSend["type"] = st;
			if (st === "simple") {
				dataToSend.fileSamples = dataToSend.fileSamples[0]
				return handleCreateCase(dataToSend)
			}
			if (samplesSelecteds.length < 2)
				setsamplesFamiliarMultipleError(true)
			else
				return handleCreateCase(dataToSend)
		}
	}
	function getWarningFilesNotFound() {
		setTimeout(() => {
			if (props.handleReload) props.handleReload()
			props.handleClose()
		}, 12000);

		return (
			<WarningCaseLoadFilesNotFound
				filesNotFoundByDomain={warningModal.filesNotFound}
				handleReload={props.handleReload}
				handleClose={props.handleClose}
			/>
		)
	}
	return (
		<Modal id="ProcessCase"
			show={props.show}
			onHide={props.handleClose}
			dialogClassName={warningModal.show ? undefined : "my-modal"}
			size={warningModal.show ? "lg" : "xl"}
		>
			<Formik initialValues={initialForm}
				validationSchema={createSchema()}
				onSubmit={handleSubmit}
				enableReinitialize={true}
				handleSubmit={console.log}
				innerRef={formRef}
				validateOnChange={false}
				validateOnBlur={true}>
				{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
					<>
						<Form noValidate onSubmit={handleSubmit}>
							<Modal.Header closeButton style={{ padding: "5px 10px", backgroundColor: theme.grayHeader }}>
								{title}
							</Modal.Header>
							<Modal.Body style={{ padding: "0" }}>
								{warningModal.show ?
									getWarningFilesNotFound()
									:
									createForm(values, handleChange, errors)
								}
							</Modal.Body>
							<Modal.Footer>
								<Button variant="secondary" onClick={props.handleClose}>
									{translate(`common.close`)}
								</Button>
								{warningModal.show ? <></> :
									<Button variant="primary" type="submit">
										{translate(`common.save`)}
									</Button>
								}
							</Modal.Footer>
						</Form>
					</>
				)}
			</Formik>
		</Modal>
	)
}
