import React from 'react';
import { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import "./Tooltip.css"

type placement = 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'
interface TooltipI {
	children: any,
	label?: string | JSX.Element ,
	placement?: placement,
	style?: any,
	autoClose?: number
	key?: number
}
function MyTooltip(props: TooltipI) {
	const label = props.label ?? ""
	const placement = props.placement ?? "top"
	const style = props.style ?? {}
	const [show, setshow] = useState(false)
	let isMounted = true
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		isMounted = true;
		return () => {
			isMounted = false
		}
	}, [])
	const handleAutoClose = (e: any) => {
		if (e && isMounted) {
			setshow(true)
			setTimeout(() => {
				if (isMounted)
					setshow(false)
				// }, props.autoClose??5000);
			}, props.autoClose ?? 5000000);
		} else
			if (isMounted) setshow(false)
	}
	return (
		<OverlayTrigger
			key={props.key ?? 0}
			show={show}
			placement={placement}
			// show={true}
			onToggle={(e: any) => handleAutoClose(e)}
			overlay={
				<Tooltip style={style}
					className="in"
					id="tooltip-top"
				>
					{label}
				</Tooltip>
			}
		>
			{props.children}
		</OverlayTrigger >
	)

}

export { MyTooltip as Tooltip }
export type { placement }
