import React, { useContext, useEffect, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { StudyI } from 'src/Contexts/interfaces';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { LangContext } from 'src/Contexts/Lang';
import Paginator from 'src/Components/Common/Paginator/Paginator';
import CNVsContext from 'src/Contexts/CNVs/CNVsContext';
import Loader from 'src/Components/Common/Loader/Loader';
import CnvTable from './CnvTable';
import { Spinner } from 'react-bootstrap';
import FilterPanelHeader from './FilterPanelHeader';

interface ModalCNVsI {
    history: any,
    handleCloseCnvs: () => void
    showCnvs: {
        open: boolean,
        study: StudyI | undefined,
        domainId: number | null
    }
}
export default function ModalCNVs(props: ModalCNVsI) {
    const { theme } = useContext(ThemeContext);
    const { dispatch: { translate } } = useContext(LangContext);
    const { cnvs, filters, sortBy, filterAndSortCnvDataTable, dispatch: { getCNVs, cleanCNVs } } = useContext(CNVsContext);
    const [cnvsDataTable, setcnvsDataTable] = useState<any[] | null>(null);

    function handleGetCnvs(page?: number, pageSize?: number, then?: any): void {
        if (props.showCnvs.study?.id) {
            getCNVs(+props.showCnvs.study?.id, page ?? paginator.actualPage + 1, pageSize ?? paginator.itemsPerPage)
        }
    }

    const paginator = Paginator({
        reloadCall: () => { },
        onChangePage: (i: any) => { },
        onItemsPerPageChange: (i: any) => { }
    })
    useEffect(() => {
        let isMounted = true;
        if (isMounted && !cnvs.loaded && !cnvs.loading && !cnvs.refresh) {
            handleGetCnvs(1, 25);
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted && !cnvs.loading && cnvs.loaded) {
            let arrayCnvs = filterAndSortCnvDataTable(cnvs.data?.cnv?.results, filters, sortBy, cnvs.data?.diseaseGenes);
            const cnvsPaginated = (arrayCnvs).filter((x: any, i: number) =>
                i >= paginator.actualPage * paginator.itemsPerPage &&
                i < (paginator.actualPage + 1) * paginator.itemsPerPage
            )
            paginator.pageSize_set(arrayCnvs.length)
            setcnvsDataTable(cnvsPaginated)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, cnvs, paginator.actualPage, paginator.itemsPerPage, sortBy])
    let correlation, nComp;
    if ((cnvs.data?.cnv?.results ?? undefined) && (cnvs.data?.cnv?.results ?? []).length > 0) {
        const firstValue = cnvs.data?.cnv?.results[0];
        correlation = firstValue.correlation;
        if (correlation !== null && !isNaN(+(correlation))) correlation = (+correlation).toFixed(3);
        nComp = firstValue.nComp;
    }
    const filterPanelHeader = !cnvs.loaded ?
        <div>
            <Spinner animation='grow' role='status' style={{ height: "15px", width: "15px", color: "theme.grayHeader" }} />
        </div> :
        <div>
            <FilterPanelHeader genomeReference={cnvs.data?.study?.genomeReference}
                samples={cnvs.data?.study?.samples}
                genesList={cnvs.data?.genes_list ?? null}
                correlation={correlation}
                nComp={nComp} />
        </div>

    const handleClose = () => {
        props.handleCloseCnvs()
        cleanCNVs();
    }

    return (
        <Modal show={props.showCnvs.open}
            onHide={handleClose}
            size={"xl"}>
            <Modal.Header closeButton
                style={{
                    color: "lightgray",
                    backgroundColor: theme.grayHeader,
                    display: "flow",
                    fontSize: 20,
                    fontWeight: "bold"
                }}
            >
                {/* <FontAwesomeIcon icon={faChartPie} style={{ fontSize: "20px", marginRight: "10px", }} /> */}
                CNVs&nbsp;-&nbsp;{props.showCnvs.study?.name}
            </Modal.Header>
            {/* <Card style={{ borderRadius: "0px", marginTop: headerHeight ?? "10px" }}> */}
            <Card style={{ borderRadius: "0px", margin: "10px" }}>
                {/* < Card style={{ borderRadius: "0px", marginTop: 5 }}> */}
                <Card.Body //ref={tableCardTableBodyRef}
                    // onScrollCapture={(e: any) => { if (handleScrollTableBody) handleScrollTableBody(e); }}
                    style={{ padding: "0px", overflow: "auto hidden" }}>
                    <div style={{ padding: 15, backgroundColor: "#eee", borderBottom: "solid 1px lightgray" }}>
                        {filterPanelHeader}
                    </div>
                    {(cnvs.loaded && cnvsDataTable !== null && (cnvsDataTable ?? []).length === 0) ?
                        <div>
                            <p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
                                {translate('common.noResultsFound')}
                            </p>
                        </div> :
                        (!cnvs.loaded && !cnvs.loading) ? <></> :
                            (!cnvs.loaded && cnvs.loading) || cnvsDataTable === null ?
                                <Loader onContainer={true} type={'DNA'} /> :
                                <>
                                    <CnvTable history={props.history}
                                        study={cnvs.data?.study}
                                        hugoGenes={cnvs.data?.hugoGenes}
                                        loading={cnvs.loading}
                                        dataTable={cnvsDataTable ?? []}
                                        handleSortByFilter={() => { }}
                                        handleReload={() => { }}
                                        studyId={`${props.showCnvs.study?.id}`} />
                                </>
                    }
                </Card.Body>
                {
                    (cnvsDataTable ?? []).length > 0 ?
                        <Card.Footer>
                            {paginator.component}
                        </Card.Footer>
                        : <></>
                }
            </Card >
            {/* <Statistics history={props.history}
                actualCase={props.showCnvs.study}
                domainId={props.showCnvs.domainId ?? undefined}
                noTitle={true}/> */}
        </Modal >)
}